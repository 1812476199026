/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { options, RANGE_24_HOURS, RANGE_CUSTOM } from './CalculateRange';
import CustomRange from './CustomRange';

/**
 * Render option button.
 *
 * @param {boolean} isActive
 * @param {string} label
 * @param {function} onClick
 *
 * @returns {object}
 */
const OptionButton = ({ isActive, label, onClick }) => {
    const className = isActive
        ? 'btn-primary text-white'
        : 'btn-secondary text-secondary';

    return (
        <button
            type="button"
            className={`btn ${className}`}
            onClick={onClick}
        >
            { label }
        </button>
    );
};

/**
 * Render date range selector.
 *
 * @param {function} onClick
 * @param {object} currentRange
 * @param {string} className
 *
 * @returns {object}
 */
const DateRangeSelect = ({ onClick, currentRange, className }) => {
    return (
        <div className={`btn-group text-secondary ${className}`}>
            {
                Object.keys(options).map((option) => {
                    if (option === RANGE_CUSTOM) {
                        return undefined;
                    }

                    return (
                        <OptionButton
                            key={option}
                            isActive={option === currentRange.option}
                            label={options[option].label}
                            onClick={() => onClick({ option })}
                        />
                    );
                })
            }

            <CustomRange
                isActive={RANGE_CUSTOM === currentRange.option}
                currentValues={currentRange.range}
                onClick={(range) => {
                    onClick({
                        option: RANGE_CUSTOM,
                        range,
                    });
                }}
            />
        </div>
    );
};

DateRangeSelect.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    currentRange: PropTypes.object,
};

DateRangeSelect.defaultProps = {
    className: '',
    currentRange: { option: RANGE_24_HOURS },
};

export default DateRangeSelect;
