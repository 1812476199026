/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '../../Table';
import Details from './Details';
import Create from './Create';
import { AddButton } from '../../Ui/Buttons';
import FeatureDetect from '../../../core/FeatureDetect';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

/**
 * Show Remotes overview.
 *
 * @param {string} id
 * @param {string} tag
 * @param {string} operationId
 * @param {object} location
 * @param {string} tables
 * @param {object} schema
 *
 * @return {string}
 */
const Overview = ({
    id,
    tag,
    operationId,
    tables,
    schema,
}) => {
    const table = tables.find(t => t.id === id);
    const buttons = [];
    const FeatureDetection = new FeatureDetect(schema);

    if (FeatureDetection.hasOperationId('createRemote')) {
        buttons.push(
            <AddButton
                key={`${table.id}-add`}
                to="/remotes/create"
            />,
        );
    }

    return (
        <Table
            title={`${tag}`}
            tag={tag}
            operationId={operationId}
            table={table}
            viewAction={(row) => { return `/remotes/${row.identifier}`; }}
            supportsCrud
            buttons={buttons}
        />
    );
};

export default withRouter(connect(mapStateToProps, null)(Overview));
export { Details, Create };
