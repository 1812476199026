/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    forEach, isEmpty, has, isObject,
} from 'lodash';
import { withRouter } from 'react-router-dom';
import RegisterWatchers from '../../../core/Watchers';
import Utils from '../../../core/Utils';
import {
    EMPTY_SCHEMA,
    getEntitySchema,
    getEnumResources,
    normalize,
    PARAMETERS_PROPERTY,
} from '../../../core/JsonSchema';
import HttpClient from '../../../core/HttpClient';
import Redirect from '../../../core/Redirect';

const mapStateToProps = state => ({
    api: state.api,
    uiSchema: state.schema.uiSchema,
    schema: state.schema.schema,
});

const mapDispatchToProps = dispatch => ({
    onFailedApiCall: message => (
        dispatch({
            type: 'REQUEST_FAILED',
            message,
        })
    ),
});

class EntityContainer extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        let { data } = props;

        if (isEmpty(data)) {
            // Show a loading message when the data will
            // be fetched from the API using parameters.
            data = isEmpty(this.props.params)
                ? {}
                : { name: 'Loading...' };
        }

        this.state = {
            data,
            schema: EMPTY_SCHEMA,
            watchers: [],
        };

        // Setup default mutators.
        this.mutators = [];

        // Add additional mutators passed in props.
        if (this.props.mutators) {
            this.mutators = this.mutators.concat(this.props.mutators);
        }
    }

    /**
     * Get schema and register watchers when component mounts.
     *
     * @return {void}
     */
    componentDidMount() {
        this.initSchema();
    }

    /**
     * Execute watchers and mutators for changed form data.
     *
     * @param {object} formData
     *
     * @return {void}
     */
    onChange(formData) {
        const data = formData;

        this.setState({
            data: formData,
        });

        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    /**
     * Create or update data, depending on the child component.
     *
     * @param {object} data
     * @param {string} tag
     * @param {string} operationId
     *
     * @return {void}
     */
    onSubmit(data, tag, operationId) {
        // TODO: Fix me. Find a better place and correct implementation.
        const values = data;

        if (has(data, PARAMETERS_PROPERTY) && !isObject(data[PARAMETERS_PROPERTY])) {
            values.parameters = JSON.parse(data.parameters);
        }

        HttpClient.request(
            operationId,
            this.props.params,
            values,
        ).then(() => {
            if (typeof this.props.onSuccess === 'function') {
                this.props.onSuccess();
            }

            Redirect(this.props.history, this.props.basePath);
        }).catch((error) => {
            const message = error.message
                ? error.message
                : 'Unexpected error while saving.';

            this.props.onFailedApiCall(message);
        });
    }

    /**
     * Normalize a schema and update the state.
     *
     * @param {object} schema
     * @param {object|boolean} data
     *
     * @return {void}
     */
    setSchema(schema, data = false) {
        const normalized = normalize(schema);
        const state = data
            ? { schema: normalized, data }
            : { schema: normalized };

        this.setState(state);
    }

    /**
     * Register watchers. Then trigger the execution
     * of all watchers to init the schema and data.
     *
     * @return {void}
     */
    registerWatchers() {
        RegisterWatchers(this.props.entity, this.props.uiSchema, (watchers) => {
            this.setState(
                {
                    watchers,
                },
                () => this.executeWatchers(this.state.data, false),
            );
        });
    }

    /**
     * Execute registered watchers.
     *
     * @param {object} formData
     * @param {boolean} force
     *
     * @deprecated Will be replaced with DI.
     *
     * @return {void}
     */
    executeWatchers(formData, force = false) {
        const candidates = Object.assign({}, formData);

        forEach(this.state.watchers, (watcher, from) => {
            const container = {
                entity: this.props.entity,
                schema: Utils.clone(this.state.schema),
                data: candidates,
            };

            if (watcher.isCandidate(formData[from], this.state.data) || force) {
                watcher.handle(
                    formData[from],
                    container,
                    (result) => {
                        this.setSchema(result.schema, result.data);
                    },
                );
            }
        });
    }

    /**
     * Mutate form data.
     *
     * @param {object} formData
     *
     * @deprecated Will be replaced with DI.
     *
     * @return {void}
     */
    executeMutators(formData) {
        let candidates = formData;
        let hasCandidates = false;

        this.mutators.forEach((mutator) => {
            if (mutator.isCandidate(candidates, this.state.data)) {
                candidates = {
                    ...candidates,
                    [mutator.getTarget()]: mutator.mutate(candidates),
                };

                this.setState({
                    data: candidates,
                });

                hasCandidates = true;
            }
        });

        // Do not update the state unless it is needed
        // (i.e. when state is not set by mutators).
        if (!hasCandidates) {
            this.setState({
                data: formData,
            });
        }
    }

    /**
     * Init schema.
     *
     * @return {Promise}
     */
    initSchema() {
        const schema = getEntitySchema(this.props.entity, this.props.schema);
        const normalized = normalize(schema);

        return getEnumResources(this.props.uiSchema, this.props.entity, normalized)
            .then((entitySchema) => {
                this.setSchema(entitySchema);
            });
    }

    /**
     * Render component and its children.
     *
     * @return {object}
     */
    render() {
        const children = React.Children.map(this.props.children, child => React.cloneElement(child, {
            api: this.props.api,
            data: this.state.data,
            schema: this.state.schema,
            entity: this.props.entity,
            onChange: data => this.onChange(data),
            onSubmit: (data, tag, operationId) => this.onSubmit(data, tag, operationId),
            uiSchema: {
                ...this.props.uiSchema[this.props.entity],
                ...this.props.additionalUiSchema,
            },
        }));

        return (
            <div>
                {children}
            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(EntityContainer));
