/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { onEnterHandler } from '../../../core/Ui';

/**
 * Show a single chip.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Chip = (props) => {
    const { label, onDelete } = props;

    return (
        <span className="chip label label-info mr-1">
            {label}
            <i
                className="ion ion-md-close-circle ml-1 clickable"
                data-role="remove"
                role="button"
                onKeyUp={event => (onEnterHandler(event, () => (onDelete)))}
                onClick={() => onDelete()}
                tabIndex={0}
            />
        </span>
    );
};

export default Chip;
