/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { IonIcon } from '../Icons';
import Redirect from '../../../core/Redirect';

const onBackClick = (history, to) => {
    Redirect(history, to);
};

/**
 * Show button to go back to the previous page.
 *
 * @param {function} history
 * @param {string} to
 *
 * @return {object}
 */
const BackButton = ({ history, to }) => (
    <Button
        color="secondary"
        onClick={() => onBackClick(history, to)}
    >
        <IonIcon icon="arrow-dropleft" hasText />
        Back
    </Button>
);

BackButton.propTypes = {
    to: PropTypes.string.isRequired,
};

export default withRouter(BackButton);
