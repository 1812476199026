/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Select a transformer.
 *
 * @param {string} name
 * @param {array} options
 * @param {function} onChange
 * @param {string} className
 *
 * @return {object}
 */
const Select = ({
    name,
    options,
    onChange,
    className,
}) => {
    const handleChange = (event) => {
        if (!event.target.value) {
            return;
        }

        const picked = options.find(
            subject => subject.identifier === event.target.value,
        );

        onChange(picked.schema, picked.identifier);
    };

    return (
        <div className={`form-group ${className}`}>
            <select id={name} className="form-control" onChange={handleChange}>
                <option key="empty" />
                {
                    options.map(row => (
                        <option key={row.identifier} value={row.identifier}>
                            {row.name}
                        </option>
                    ))
                }
            </select>
        </div>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

Select.defaultProps = {
    className: '',
};

export default Select;
