/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import EnvironmentDetector, { LOGIN_ENVIRONMENT } from './EnvironmentDetector';

const ENVIRONMENT_KEY = 'MagmentEnvironment';

class EnvironmentStorage {
    /**
     * Get current environment.
     *
     * @returns {string}
     */
    static getEnvironment() {
        const environment = EnvironmentDetector.getEnvironmentFromUrl();

        if (environment) {
            return environment;
        }

        return localStorage.getItem(ENVIRONMENT_KEY) || LOGIN_ENVIRONMENT;
    }

    /**
     * Set the current environment.
     *
     * @param {string} environment
     */
    static setEnvironment(environment) {
        localStorage.setItem(
            ENVIRONMENT_KEY,
            environment,
        );
    }

    /**
     * Whether a environment has been set.
     *
     * @returns {boolean}
     */
    static hasEnvironment() {
        return !!localStorage.getItem(ENVIRONMENT_KEY);
    }

    /**
     * Remove the current environment.
     */
    static deleteEnvironment() {
        localStorage.removeItem(ENVIRONMENT_KEY);
    }

    /**
     * Get an item from the storage.
     *
     * @param {string} key
     * @param {boolean} prefixed
     *
     * @returns {string}
     */
    static getItem(key, prefixed = true) {
        return localStorage.getItem(
            prefixed ? EnvironmentStorage.getEnvironmentKey(key) : key,
        );
    }

    /**
     * Set an item in the storage.
     *
     * @param {string} key
     * @param {string} value
     * @param {boolean} prefixed
     */
    static setItem(key, value, prefixed = true) {
        localStorage.setItem(
            prefixed ? EnvironmentStorage.getEnvironmentKey(key) : key,
            value,
        );
    }

    /**
     * Remove item from local storage.
     *
     * @param {string} key
     */
    static removeItem(key) {
        localStorage.removeItem(
            EnvironmentStorage.getEnvironmentKey(key),
        );
    }

    /**
     * Completely clear the storage.
     */
    static clearAllEnvironments() {
        localStorage.clear();
    }

    /**
     * Whether the user is logged in.
     *
     * @returns {boolean}
     */
    static isAuthenticated() {
        if (!EnvironmentStorage.hasEnvironment()) {
            return false;
        }

        return !!sessionStorage.getItem(
            EnvironmentStorage.getEnvironmentKey('isAuthenticated'),
        );
    }

    /**
     * Mark the user as authenticated.
     */
    static setIsAutenticated() {
        sessionStorage.setItem(
            EnvironmentStorage.getEnvironmentKey('isAuthenticated'),
            'true',
        );
    }

    /**
     * Mark the user as unauthenticated.
     */
    static deleteIsAuthenticated() {
        sessionStorage.removeItem(
            EnvironmentStorage.getEnvironmentKey('isAuthenticated'),
        );
    }

    /**
     * Get storage key for current environment.
     *
     * @param {string} key
     *
     * @returns {string}
     */
    static getEnvironmentKey(key) {
        return `${key}[${EnvironmentStorage.getEnvironment()}]`;
    }

    /**
     * Get current API url.
     *
     * @returns {string}
     */
    static getCurrentUrl() {
        return EnvironmentStorage.getUrl(
            this.getEnvironment(),
        );
    }

    /**
     * Get API url.
     *
     * @param {string} environment
     *
     * @returns {string}
     */
    static getUrl(environment) {
        return process.env.REACT_APP_MAGEMENT_HOST.replace('{environment}', environment);
    }
}

export default EnvironmentStorage;
export {
    ENVIRONMENT_KEY,
};
