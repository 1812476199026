/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, has } from 'lodash';
import TableCell from '../Table/TableCell';
import {
    EMPTY_DEFINITION,
} from '../../core/JsonSchema';
import './Table.css';
import Loading from '../Table/Rows/Loading';
import { schemaPropType } from '../../validation';

const definition = (schema, name) => (
    has(schema.properties, name)
        ? schema.properties[name]
        : EMPTY_DEFINITION
);

const mapStateToProps = state => ({
    api: state.api,
});

/**
 * Show entity data.
 *
 * @param {object} data
 * @param {object} schema
 * @param {object} api
 * @param {array} renderers
 *
 * @return {object}
 */
const Table = ({
    data, schema, api, renderers,
}) => {
    const tableRows = map(data, (value, name) => (
        <tr key={name}>
            <th>{name.replace(/_/g, ' ')}</th>
            <TableCell
                name={name}
                key={name}
                item={value}
                definition={definition(schema, name)}
                renderers={renderers}
                context={data}
            />
        </tr>
    ));

    return (
        <table className="table table-bordered">
            <tbody>
                {
                    api.isRequesting
                        ? <Loading />
                        : tableRows
                }
            </tbody>
        </table>
    );
};

Table.propTypes = {
    schema: schemaPropType.isRequired,
    data: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
    ])).isRequired,
    renderers: PropTypes.arrayOf(PropTypes.func),
};

Table.defaultProps = {
    renderers: [],
};

export default connect(
    mapStateToProps,
    null,
)(Table);
