/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import ErrorMessage from './ErrorMessage';
import { readFlashMessage } from '../../../reducers/notification/actions';

class FlashMessage extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            flashMessage: {
                ...this.props.flashMessage,
            },
        };

        this.handleClose = this.handleClose.bind(this);
    }

    /**
     * Mark message as read as soon as it is shown.
     */
    componentDidMount() {
        this.props.dispatch(readFlashMessage());
    }

    /**
     * Close error message.
     */
    handleClose() {
        this.setState({
            flashMessage: {
                isRead: true,
            },
        });
    }

    /**
     * Render flash message.
     *
     * @returns {object}
     */
    render() {
        return (
            <ErrorMessage
                onClose={this.handleClose}
                isOpen={!this.state.flashMessage.isRead}
                message={this.state.flashMessage.message}
            />
        );
    }
}

const mapStateToProps = state => ({
    flashMessage: state.notification.flashMessage,
});

export default connect(mapStateToProps)(FlashMessage);
