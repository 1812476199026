/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EntityContainer from '../Entity/EntityContainer';
import EntityView from '../Entity/EntityView';
import '../../Entity/Entity.css';
import StorageEntityView from './StorageEntityView';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

/**
 * Remote details.
 *
 * @param {string} tag
 * @param {object} operationIds
 * @param {string} entity
 * @param {object} params
 * @param {string} basePath
 * @param {array} renderers
 * @param {object} tables
 *
 * @return {object}
 */
const Details = ({
    tag,
    operationIds,
    entity,
    params,
    basePath,
    renderers,
    tables,
}) => {
    const entityTable = tables.find(t => t.id === 'StorageKeys');

    const tab = {
        id: 'entities',
        name: 'Entities',
        content: () => (
            <StorageEntityView
                params={params}
                basePath={basePath}
                table={entityTable}
            />
        ),
    };

    return (
        <EntityContainer
            tag={tag}
            operationIds={operationIds}
            entity={entity}
            params={params}
            basePath={basePath}
        >
            <EntityView
                tag={tag}
                operationIds={operationIds}
                entity={entity}
                params={params}
                basePath={basePath}
                renderers={renderers}
                tabs={[tab]}
            />
        </EntityContainer>
    );
};

Details.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
    renderers: PropTypes.arrayOf(PropTypes.func),
};

Details.defaultProps = {
    params: {},
    renderers: [],
};

export default connect(
    mapStateToProps,
    null,
)(Details);
