/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UndoButton from '../../../Ui/Buttons/UndoButton';

/**
 * Remove unsaved mapping change.
 *
 * @param {integer} id
 * @param {function} onRemove
 *
 * @returns {object}
 */
const UndoChanges = ({ id, dispatch, onClick }) => {
    return (
        <UndoButton onClick={() => dispatch(onClick(id))} />
    );
};

UndoChanges.propTypes = {
    id: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(UndoChanges);
