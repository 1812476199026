/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-jsonschema-form';
import ReactJSON from 'react-json-view';
import { Card, CardHeader, CardBody } from 'reactstrap';
import PageHeader from '../../Ui/PageHeader';
import TransformerTypesContainer from './Containers/TransformerTypesContainer';
import { schemaPropType } from '../../../validation';
import Fields, { uiSchema, TYPE_OBJECT } from './Form/Fields';
import ArrayTemplate from '../../Ui/Form/ArrayTemplate';

const mapStateToProps = state => ({
    identifier: state.transformer.current.identifier,
    name: state.transformer.current.name,
    schema: state.transformer.current.schema,
});

const TransformerDocumentation = (props) => {
    const { identifier, name, schema } = props;
    schema.type = TYPE_OBJECT;

    return (
        <div>
            <PageHeader title="Transformer Configurator" />
            <div className="row">
                <div className="accordion col-md-2">
                    <TransformerTypesContainer />
                </div>
                <div className="col-md-3">
                    <Card>
                        <CardHeader>{name || 'Transformer Documentation'}</CardHeader>
                        <CardBody>
                            <ReactJSON
                                src={schema}
                                name={identifier}
                                enableClipboard={false}
                                displayObjectSize={false}
                                displayDataTypes={false}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className="col-md-7">
                    <Card>
                        <CardHeader>Form</CardHeader>
                        <CardBody>
                            <Form
                                ArrayFieldTemplate={ArrayTemplate}
                                formData={{}}
                                schema={schema}
                                fields={Fields}
                                uiSchema={uiSchema}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

TransformerDocumentation.propTypes = {
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    schema: schemaPropType.isRequired,
};

export default connect(mapStateToProps)(TransformerDocumentation);
