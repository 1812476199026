/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import HttpClient from './HttpClient';
import EnvironmentStorage from './Environment/EnvironmentStorage';

/**
 * Whether the user is logged in. Note that this is
 * an insecure method. The real check is done in
 * the API. Do not rely on this result.
 *
 * @return {boolean}
 */
const insecureIsLoggedIn = () => {
    return EnvironmentStorage.isAuthenticated();
};

/**
 * Logout.
 *
 * @returns {Promise}
 */
const logout = () => {
    const baseUrl = EnvironmentStorage.getCurrentUrl();
    EnvironmentStorage.deleteIsAuthenticated();

    return fetch(`${baseUrl}/authentication/logout`, {
        credentials: 'include',
        redirect: 'manual',
    }).then(response => response);
};

/**
 * Start the session.
 *
 * @returns {Promise}
 */
const startSession = () => {
    return HttpClient.request('getCurrentUser')
        .then(() => sessionStorage.setItem('MagementIsLoggedIn', 'true'))
        .catch(() => sessionStorage.removeItem('MagementIsLoggedIn'));
};

/**
 * Whether the need to login is enabled.
 *
 * @returns {boolean}
 */
const isLoginEnabled = () => {
    return process.env.REACT_APP_REQUIRES_AUTHENTICATION === 'true';
};

/**
 * Whether the user needs to login.
 *
 * @returns {bool}
 */
const requiresLogin = () => {
    return (
        insecureIsLoggedIn() || !isLoginEnabled()
    );
};


export {
    insecureIsLoggedIn,
    startSession,
    logout,
    isLoginEnabled,
    requiresLogin,
};
