/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import FieldFactory from './FieldFactory';

const Comparator = (props) => {
    const additionalProps = {
        tag: 'Transformers',
        operationId: 'getComparators',
        types: { object: 'comparator', array: 'comparators' },
        ...props,
    };

    return FieldFactory.create(props.schema, additionalProps);
};

export default Comparator;
