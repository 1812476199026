/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const READ_FLASH_MESSAGE = 'READ_FLASH_MESSAGE';
const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';

const readFlashMessage = () => ({
    type: READ_FLASH_MESSAGE,
});

const addFlashMessage = (message, messageType) => ({
    type: ADD_FLASH_MESSAGE,
    message,
    messageType,
});

export {
    READ_FLASH_MESSAGE,
    ADD_FLASH_MESSAGE,
    readFlashMessage,
    addFlashMessage,
};
