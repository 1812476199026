/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import newIcon from './assets/new.svg';
import processingIcon from './assets/processing.svg';
import crossIcon from './assets/cross.svg';
import checkIcon from './assets/check.svg';
import totalIcon from './assets/total.svg';

const iconMap = {
    new: newIcon,
    processing: processingIcon,
    cross: crossIcon,
    total: totalIcon,
    check: checkIcon,
};

/**
 * Generic widget.
 *
 * @param {string} icon
 * @param {string}className
 * @param {node} children
 *
 * @returns {object}
 */
const Index = ({ icon, className, children }) => {
    return (
        <div className={`widget ${className}`}>
            { children }
            { icon && (
                <div className="icon p-3">
                    <div className="align-vertical">
                        <img
                            alt="Realtime stats icon"
                            src={iconMap[icon]}
                            className="widget-icon"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

Index.defaultProps = {
    icon: false,
    className: '',
    children: [],
};

export default Index;
