/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration } from '../Magement';
import Route from './PrivateRoute';

const basePath = '/:environment/routes/configurations';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Routes = () => (
    <Switch>
        <Route
            exact
            path={basePath}
            render={() => (
                <Configuration.Overview
                    id="RoutesConfigurations"
                    tag="Routes"
                    operationId="getRouteConfigurations"
                    createOperationId="createRouteConfiguration"
                />
            )}
        />
        <Route
            exact
            path={`${basePath}/create`}
            render={
                () => (
                    <Configuration.Create
                        tag="Routes"
                        operationIds={{
                            create: 'createRouteConfiguration',
                            read: 'getRouteConfiguration',
                            update: 'updateRouteConfiguration',
                            delete: 'deleteRouteConfiguration',
                        }}
                        entity="RouteConfiguration"
                        basePath={basePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${basePath}/:identifier`}
            render={
                props => (
                    <Configuration.Details
                        tag="Routes"
                        operationIds={{
                            read: 'getRouteConfiguration',
                            update: 'updateRouteConfiguration',
                            delete: 'deleteRouteConfiguration',
                        }}
                        entity="RouteConfiguration"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={basePath}
                    />
                )
            }
        />
    </Switch>
);

export default Routes;
