/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Tooltip } from 'reactstrap';
import { Copy, Check } from 'react-feather';
import Config from '../../core/Config';

class ClipBoard extends React.Component {
    constructor(props) {
        super(props);

        this.copyText = this.copyText.bind(this);
        this.toggle = this.toggle.bind(this);

        this.state = {
            isCopied: false,
            tooltipOpen: false,
        };
    }

    toggle() {
        const { tooltipOpen } = this.state;

        this.setState({
            isCopied: false,
            tooltipOpen: !tooltipOpen,
        });
    }

    copyText(subject) {
        const selectedText = document.getElementById(subject).innerHTML.replace(/<(?:.|\n)*?>/gm, '');

        navigator.clipboard.writeText(selectedText).then(() => {
            this.setState({
                isCopied: true,
            });
        });
    }

    render() {
        const { iconSize } = Config.ui;
        const { subject } = this.props;
        const message = this.state.isCopied
            ? (
                <>
                    <Check iconSize={iconSize} />
                    {' copied'}
                </>
            ) : (
                <>
                    <Copy iconSize={iconSize} />
                    {' click to copy'}
                </>
            );

        return (
            <div
                id={subject}
                onClick={() => this.copyText(subject)}
                className="d-inline-block clickable"
                role="presentation"
            >
                <span className="text-secondary">{'${'}</span>
                <span className="text-primary">
                    {subject}
                    <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={subject} toggle={this.toggle}>
                        {message}
                    </Tooltip>
                </span>
                <span className="text-secondary">{'}'}</span>
            </div>
        );
    }
}

export default ClipBoard;
