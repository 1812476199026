/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import './Notification.scss';
import { logout } from '../../core/Auth';
import EnvironmentStorage from '../../core/Environment/EnvironmentStorage';
import EnvironmentList from './EnvironmentList';
import LogoutButton from '../Ui/Buttons/LogoutButton';

const mapStateToProps = state => ({
    notifications: state.notification.messages,
    username: state.authentication.username,
    environment: state.authentication.environment,
    availableEnvironments: state.authentication.availableEnvironments,
});

const mapDispatchToProps = dispatch => ({
    clearAll: () => {
        dispatch({
            type: 'CLEAR_ALL_NOTIFICATIONS',
        });
    },
});

class CurrentUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleLogout() {
        logout()
            .then(() => {
                EnvironmentStorage.deleteIsAuthenticated();

                window.location.reload();
            });
    }

    handleToggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    render() {
        return (
            <Dropdown
                isOpen={this.state.isOpen}
                toggle={this.handleToggle}
                className="list-inline-item dropdown notification-list"
                nav
            >
                <DropdownToggle>
                    <i className="mdi mdi-account noti-icon waves-light waves-effect" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-lg">
                    <DropdownItem className="notify-item">
                        <strong>{ this.props.username }</strong>
                    </DropdownItem>

                    <EnvironmentList
                        environments={this.props.availableEnvironments}
                        environment={this.props.environment}
                    />

                    <DropdownItem className="notify-item" onClick={this.handleLogout}>
                        <LogoutButton />
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CurrentUser));
