/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import Overview from '../Magement/Configuration';
import Details from '../Magement/Configuration/Details';
import Create from '../Magement/Configuration/Create';
import Route from './PrivateRoute';

const BASE_PATH = '/:environment/users';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Users = () => (
    <Switch>
        <Route
            exact
            path={BASE_PATH}
            render={() => {
                return (
                    <Overview
                        id="Users"
                        tag="Users"
                        operationId="getUsers"
                        createOperationId="createUser"
                        identifierResolver={row => row.username}
                    />
                );
            }}
        />
        <Route
            exact
            path={`${BASE_PATH}/create`}
            render={
                () => (
                    <Create
                        tag="Users"
                        operationIds={{
                            create: 'createUser',
                        }}
                        entity="User"
                        basePath={BASE_PATH}
                    />
                )
            }
        />
        <Route
            exact
            path={`${BASE_PATH}/:identifier`}
            render={
                props => (
                    <Details
                        tag="Users"
                        operationIds={{
                            read: 'getUser',
                            delete: 'deleteUser',
                            update: 'updateUser',
                        }}
                        entity="User"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={BASE_PATH}
                    />
                )
            }
        />
    </Switch>
);

export default Users;
