/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const MenuItems = {
    dashboard: {
        id: 'dashboard',
        url: '/',
        label: 'Dashboard',
    },
    tasksQueue: {
        id: 'tasks',
        url: '/tasks/queue',
        label: 'Tasks',
        operationId: 'getTasks',
        tags: 'Tasks',
    },
    webhooks: {
        id: 'webhooks',
        url: '/webhooks',
        label: 'Web Hooks',
        operationId: 'getHooks',
        tags: 'Hooks',
    },
    routesConfigurations: {
        id: 'routes',
        url: '/routes/configurations',
        label: 'Routes',
        operationId: 'getRouteConfigurations',
        tags: 'Routes',
    },
    mapping: {
        id: 'mapping',
        url: '/mapping',
        label: 'Mapping',
        operationId: 'getMappingKeyList',
        tags: 'Mapping',
    },
    remotes: {
        id: 'remotes',
        url: '/remotes',
        label: 'Remotes',
        operationId: 'getRemotes',
        tags: 'Remotes',
    },
    subscribersConfiguration: {
        id: 'SubscriberConfiguration',
        url: '/subscribers/configurations',
        label: 'Subscribers',
        operationId: 'getSubscriberConfigurations',
        tags: 'Subscribers',
    },
    publishersConfiguration: {
        id: 'PublisherConfiguration',
        label: 'Publishers',
        url: '/publishers/configurations',
        operationId: 'getPublisherConfigurations',
        tags: 'Publishers',
    },
    transformersConfigurations: {
        id: 'TransformerConfigurations',
        url: '/transformers/configurations',
        label: 'Transformers',
        operationId: 'getTransformerConfigurations',
        tags: 'Transformers',
    },
    filesystemsConfigurations: {
        id: 'FilesystemConfigurations',
        url: '/filesystems/configurations',
        label: 'Filesystems',
        operationId: 'getFilesystemConfigurations',
        tags: 'Filesystems',
    },
    httpClientsConfigurations: {
        id: 'HttpClientConfigurations',
        url: '/http-clients/configurations',
        label: 'HTTP Clients',
        operationId: 'getHttpClientConfigurations',
        tags: 'HttpClients',
    },
    pdoClientsConfigurations: {
        id: 'PdoClientConfigurations',
        url: '/pdo-clients/configurations',
        label: 'PDO Clients',
        operationId: 'getPdoClientConfigurations',
        tags: 'PdoClients',
    },
    storagesConfigurations: {
        id: 'StorageConfigurations',
        url: '/storages/configurations',
        label: 'Storages',
        operationId: 'getStorageConfigurations',
        tags: 'Storages',
    },
    environmentVariables: {
        id: 'EnvironmentVariables',
        url: '/environment/variables',
        label: 'Environment variables',
        operationId: 'getEnvironmentVariables',
        tags: 'Environment',
    },
    flagList: {
        id: 'Flags',
        url: '/flag/list',
        label: 'Flags',
        operationId: 'listFlags',
        tags: 'Flags',
    },
    entitiesTypes: {
        id: 'EntityType',
        url: '/entities/types',
        label: 'Entities',
        operationId: 'getEntityTypes',
        tags: 'Entities',
    },
    subscribersTypes: {
        id: 'SubscriberType',
        url: '/subscribers/types',
        label: 'Subscribers',
        operationId: 'getSubscriberConfigurations',
        tags: 'Subscribers',
    },
    publishersTypes: {
        id: 'PublisherTypes',
        url: '/publishers/types',
        label: 'Publishers',
        operationId: 'getPublisherConfigurations',
        tags: 'Publishers',
    },
    transformersTypes: {
        id: 'TransformerTypes',
        url: '/transformers/types',
        label: 'Transformers',
        operationId: 'getTransformerTypes',
        tags: 'Transformers',
    },
    filesystemsTypes: {
        id: 'FilesystemTypes',
        url: '/filesystems/types',
        label: 'Filesystems',
        operationId: 'getFilesystemTypes',
        tags: 'Filesystems',
    },
    httpClientsTypes: {
        id: 'HttpClientTypes',
        url: '/http-clients/types',
        label: 'HTTP Clients',
        operationId: 'getHttpClientTypes',
        tags: 'HttpClients',
    },
    pdoClientsTypes: {
        id: 'PdoClientTypes',
        url: '/pdo-clients/types',
        label: 'PDO Clients',
        operationId: 'getPdoClientTypes',
        tags: 'PdoClients',
    },
    storagesTypes: {
        id: 'StorageTypes',
        url: '/storages/types',
        label: 'Storages',
        operationId: 'getStorageTypes',
        tags: 'Storages',
    },
    documentationTransformers: {
        id: 'TransformerDocumentation',
        url: '/documentation/transformers',
        label: 'Transformers',
    },
    userConfiguration: {
        id: 'UserConfiguration',
        url: '/users',
        label: 'Users',
        operationId: 'getUsers',
        tag: 'Users',
    },
};

export default MenuItems;
