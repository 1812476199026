/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Configuration } from '../..';
import { changeConfig } from '../../../../reducers/transformer/actions';
import TestData from './TestData';

/**
 * Transformer configuration details page.
 *
 * @param {string} tag
 * @param {array} operationIds
 * @param {object} entity
 * @param {object} params
 * @param {string} basePath
 * @param {function} dispatch
 *
 * @returns {object}
 */
const Details = ({
    tag,
    operationIds,
    entity,
    params,
    basePath,
    dispatch,
}) => {
    const tabs = [{
        id: 'TransformerTest',
        name: 'Test',
        content: () => (<TestData />),
    }];

    return (
        <>
            <Configuration.Details
                tag={tag}
                operationIds={operationIds}
                entity={entity}
                params={params}
                basePath={basePath}
                onChange={data => dispatch(changeConfig(data))}
                tabs={tabs}
            />
        </>
    );
};

Details.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.object.isRequired,
    entity: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    basePath: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(Details);
