/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import EntityTable from '../../Entity/Table';
import { schemaPropType } from '../../../validation';

/**
 * Get entity table.
 *
 * @param {string} entity
 * @param {array} data
 * @param {object} schema
 * @param {array} renderers
 *
 * @return {object}
 */
const EntityDetails = ({
    entity, data, schema, renderers,
}) => (
    <EntityTable
        entity={entity}
        data={data}
        schema={schema}
        renderers={renderers}
    />
);

EntityDetails.propTypes = {
    schema: schemaPropType.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.object,
        PropTypes.string,
    ]).isRequired,
    entity: PropTypes.string.isRequired,
};

export default EntityDetails;
