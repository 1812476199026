/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Move down button.
 *
 * @param {function} onClick
 *
 * @deprecated See DeleteButton
 *
 * @return {object}
 */
const DeleteRowButton = ({ onClick }) => (
    <Button
        color="danger"
        size="sm"
        className="mr-1"
        onClick={() => onClick()}
    >
        <IonIcon icon="trash" hasText={false} />
    </Button>
);

export default DeleteRowButton;
