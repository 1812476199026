/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input } from 'reactstrap';

/**
 * Show search bar.
 *
 * @param {string} filter
 * @param {function} onChange
 *
 * @return {object}
 */
const SearchBar = ({ filter, onChange }) => {
    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <form className="p-3">
            <InputGroup>
                <Input
                    value={filter}
                    placeholder="Search"
                    onChange={handleChange}
                />
            </InputGroup>
        </form>
    );
};

SearchBar.propTypes = {
    filter: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SearchBar;
