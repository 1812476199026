/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import EntityContainer from '../Entity/EntityContainer';
import EntityCreate from '../Entity/EntityCreate';
import '../../Entity/Entity.css';

/**
 * Create new remote.
 *
 * @param {string} tag
 * @param {object} operationIds
 * @param {string} entity
 * @param {object} params
 * @param {string} basePath
 * @param {string} title
 * @param {boolean} backButton
 * @param {object} data
 * @param {object} uiSchema
 * @param {function} onSuccess
 *
 * @return {object}
 */
const Create = ({
    tag,
    operationIds,
    entity,
    params,
    basePath,
    title,
    backButton,
    data,
    uiSchema,
    onSuccess,
}) => (
    <EntityContainer
        tag={tag}
        operationIds={operationIds}
        entity={entity}
        params={params}
        basePath={basePath}
        data={data}
        onSuccess={onSuccess}
    >
        <EntityCreate
            tag={tag}
            operationIds={operationIds}
            params={params}
            basePath={basePath}
            title={title}
            backButton={backButton}
            uiSchema={uiSchema}
        />
    </EntityContainer>
);

Create.propTypes = {
    tag: PropTypes.string.isRequired,
    title: PropTypes.string,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
    basePath: PropTypes.string.isRequired,
    backButton: PropTypes.bool,
    data: PropTypes.objectOf(PropTypes.any),
    uiSchema: PropTypes.objectOf(PropTypes.any),
    onSuccess: PropTypes.func,
};

Create.defaultProps = {
    params: {},
    backButton: true,
    data: {},
    uiSchema: {},
    title: null,
    onSuccess: () => (null),
};

export default Create;
