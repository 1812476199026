/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import map from 'lodash/map';
import filter from 'lodash/filter';

/**
 * Get formatted data structure for API.
 *
 * @param {array|object} values
 *
 * @returns {array}
 */
const formatter = (values) => {
    return map(values, (item) => {
        return {
            from: item.from,
            to: item.to,
        };
    });
};

/**
 * Filter changes by type.
 *
 * @param {string} type
 * @param {array} items
 *
 * @returns {array}
 */
const getByType = (type, items) => {
    return filter(items, (subject) => {
        return subject.type === type;
    });
};

/**
 * Filter changes by page.
 *
 * @param {string} type
 * @param {string} source
 * @param {string} destination
 * @param {object} keyMapping
 *
 * @returns {array}
 */
const getByPage = (type, source, destination, keyMapping) => {
    return filter(keyMapping, (subject) => {
        return (
            subject.type === type
            && subject.source === source
            && subject.destination === destination
        );
    });
};

export {
    formatter,
    getByType,
    getByPage,
};
