/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const RESET_RULES = 'RESET_RULES';

const resetRules = () => ({
    type: RESET_RULES,
});

export {
    RESET_RULES,
    resetRules,
};
