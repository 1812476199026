/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

class InArray {
    /**
     * Parse array field.
     *
     * @param {string} name
     * @param {object} field
     *
     * @return {object}
     */
    static parse(name, field) {
        return {
            type: 'array',
            items: {
                type: 'string',
                ...field.items,
            },
        };
    }

    /**
     * Get the ui schema.
     *
     * @returns {object}
     */
    static parseUi() {
        return { 'ui:widget': 'checkboxes' };
    }
}

export default InArray;
