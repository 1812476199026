/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    has, isEmpty, isFunction, pickBy,
} from 'lodash';
import TableHeaderColumns from './TableHeaderColumns';
import TableBody from './TableBody';

class TableData extends Component {
    /**
     * Get columns to render.
     *
     * @return {string[]}
     */
    getColumns() {
        const columns = (!this.hasColumns() && this.hasEntitySchema())
            ? TableData.getDefaultColumns(this.props.table.entitySchema)
            : Object.keys(this.props.table.columns);

        const uiSchema = this.props.uiSchema[this.props.table.entity];

        return columns.filter((column) => {
            if (!uiSchema || !uiSchema[column]) {
                return true;
            }
            return uiSchema[column].hidden !== true;
        });
    }

    /**
     * Get default columns to show from a schema.
     *
     * @return {array}
     */
    static getDefaultColumns(schema) {
        return Object.keys(
            pickBy(schema.properties, (property => property.type !== 'array')),
        );
    }

    /**
     * Whether columns have been selected.
     *
     * @return {boolean}
     */
    hasColumns() {
        return !isEmpty(this.props.table.columns);
    }

    /**
     * Whether the entity schema is loaded.
     *
     * @return {boolean}
     */
    hasEntitySchema() {
        return has(this.props.table, 'entitySchema.properties');
    }

    /**
     * Whether the table has an action column.
     *
     * @return {boolean}
     */
    hasActionColumn() {
        return (
            has(this.props, 'actionsColumn')
            && isFunction(this.props.actionsColumn)
        );
    }

    /**
     * Render table.
     *
     * @return {object}
     */
    render() {
        const hoverStyle = (this.props.viewAction) ? ' table-hover' : '';

        return (
            <table className={`table responsive table-bordered table-striped${hoverStyle}`}>
                <TableHeaderColumns
                    table={this.props.table}
                    disabledColumns={this.props.disabledColumns}
                    columns={this.getColumns()}
                    withMassActionColumn={this.props.massAction}
                    withActionsColumn={this.hasActionColumn()}
                    hasFilter={this.props.hasFilter}
                />
                <TableBody
                    table={this.props.table}
                    data={this.props.table.data}
                    columns={this.getColumns()}
                    massActionColumn={this.props.massAction}
                    actionsColumn={this.props.actionsColumn}
                    renderers={this.props.renderers}
                    viewAction={this.props.viewAction}
                />
            </table>
        );
    }
}

export default connect()(TableData);
