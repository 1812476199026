/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import MappingStatus from '../MappingStatus';

/**
 * Whether the mapping value is in a changed state.
 *
 * @param mapping
 * @param {integer} from
 *
 * @returns {boolean}
 */
const isChanged = (mapping, from) => {
    return mapping[from] !== undefined;
};

/**
 * Whether the mapping value is in a changed state.
 *
 * @param deletions
 * @param {integer} from
 *
 * @returns {boolean}
 */
const isDeleted = (deletions, from) => {
    return deletions[from] !== undefined;
};

/**
 * Get current mapping status.
 *
 * @param mapping
 * @param deletions
 * @param {object} row
 *
 * @returns {string}
 */
const getStatus = (mapping, deletions, row) => {
    const { mapped, matched } = row;

    if (isChanged(mapping, row.id)) {
        return MappingStatus.MAPPING_PENDING;
    }

    if (isDeleted(deletions, row.id)) {
        return MappingStatus.MAPPING_DELETED;
    }

    if (matched && !mapped) {
        return MappingStatus.MAPPING_MATCHED;
    }

    if (mapped) {
        return MappingStatus.MAPPING_MAPPED;
    }

    return MappingStatus.MAPPING_UNMAPPED;
};


/**
 * Get current destination.
 *
 * @param {object} mapping
 * @param {object} deletions
 * @param {object} row
 *
 * @returns {null|number}
 */
const getCurrentValue = (mapping, deletions, row) => {
    const status = getStatus(mapping, deletions, row);
    const { id, mapped, matched } = row;

    switch (status) {
        case MappingStatus.MAPPING_PENDING:
            return mapping[id].to;
        case MappingStatus.MAPPING_MATCHED:
            return matched.id;
        case MappingStatus.MAPPING_MAPPED:
            return mapped.id;
        default:
            return null;
    }
};

/**
 * Whether the current row is mapped.
 *
 * @param {object} mapping
 * @param {object} deletions
 * @param {object} row
 *
 * @returns {boolean}
 */
const isMapped = (mapping, deletions, row) => {
    return getStatus(mapping, deletions, row) === MappingStatus.MAPPING_MAPPED;
};

export {
    getCurrentValue,
    getStatus,
    isChanged,
    isMapped,
    isDeleted,
};
