/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import ChangeOrder from './ChangeOrder';
import { AddRowButton } from '../Buttons';
import { moveDown, moveUp, remove } from '../../../core/ArrayOperations';

class SortableList extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.handleMoveDown = this.handleMoveDown.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    /**
     * Get index in array by identifier.
     *
     * @param {string} identifier
     *
     * @return {number}
     */
    getIndexByIdentifier(identifier) {
        return this.props.children.findIndex(
            element => element.props.identifier === identifier,
        );
    }

    /**
     * Move an item down the list.
     *
     * @param {string} identifier
     */
    handleMoveDown(identifier) {
        const index = this.getIndexByIdentifier(identifier);

        this.props.onChange({
            items: moveDown(this.props.children, index),
        });
    }

    /**
     * Move a transformer up.
     *
     * @param {string} identifier
     */
    handleMoveUp(identifier) {
        const index = this.getIndexByIdentifier(identifier);

        this.props.onChange({
            items: moveUp(this.props.children, index),
        });
    }

    /**
     * Remove transformer.
     *
     * @param {string} identifier
     */
    handleDelete(identifier) {
        const index = this.getIndexByIdentifier(identifier);

        this.props.onChange({
            items: remove(this.props.children, index),
        });
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        // Pass the current position in the array to the child components.
        const children = React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, { index });
        });

        const { onAddItem } = this.props;

        const items = children.map((child, position) => {
            return (
                <div key={child.props.identifier}>
                    <div>{child}</div>
                    <ChangeOrder
                        identifier={child.props.identifier}
                        onMoveUp={this.handleMoveUp}
                        onMoveDown={this.handleMoveDown}
                        onDelete={this.handleDelete}
                        position={position}
                        numItems={children.length}
                    />
                </div>
            );
        });

        return (
            <div>
                {items}
                <AddRowButton onClick={onAddItem} />
            </div>
        );
    }
}

SortableList.propTypes = {
    onChange: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
};

export default SortableList;
