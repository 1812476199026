/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import ReactJSON from 'react-json-view';
import { Card, CardBody } from 'reactstrap';
import PageHeader from '../../Ui/PageHeader';
import BackButton from '../../Ui/Buttons/BackButton';
import HttpClient from '../../../core/HttpClient';
import ErrorMessage from '../../Ui/Messages/ErrorMessage';

class WebHookContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            hasError: false,
        };

        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    componentWillMount() {
        const params = {
            hook: this.props.identifier,
            version: this.props.version,
        };

        HttpClient.request('getHookFileContent', params)
            .then((response) => {
                try {
                    const data = JSON.parse(response.content);
                    this.setState({
                        data,
                    });
                } catch (err) {
                    this.setState({
                        hasError: true,
                    });
                }
            });
    }

    /**
     * Close error.
     */
    handleErrorClose() {
        this.setState({
            hasError: false,
        });
    }

    render() {
        return (
            <div>
                <PageHeader title="Content of Web Hook">
                    <BackButton to={`/webhooks/${this.props.identifier}`} />
                </PageHeader>
                <Card>
                    <CardBody>
                        <ErrorMessage
                            message="An error occurred while loading the file."
                            onClose={this.handleErrorClose}
                            isOpen={this.state.hasError}
                        />

                        <ReactJSON
                            name={false}
                            src={this.state.data}
                            enableClipboard={false}
                            displayObjectSize={false}
                            displayDataTypes={false}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default WebHookContent;
