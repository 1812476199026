/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import NumberOfTasksWidget from '../NumberOfTasksWidget';
import Widget from '../index';
import './TimeFrame.scss';

const TimeFrame = () => {
    const where = {
        status: {
            inq: ['new', 'processing', 'failed', 'finished', 'skipped'],
        },
    };
    return (
        <div className="realtime-stats">
            <Widget className="total-tasks text-white" icon="total">
                <NumberOfTasksWidget identifier="total" title="Total tasks" where={where} />
            </Widget>
            <Widget className="bg-success text-white" icon="check">
                <NumberOfTasksWidget identifier="finished" title="Finished tasks" />
            </Widget>
            <Widget className="bg-danger text-white" icon="cross">
                <NumberOfTasksWidget identifier="failed" title="Failed tasks" />
            </Widget>
        </div>
    );
};

export default TimeFrame;
