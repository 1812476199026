/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Move down button.
 *
 * @param {function} onClick
 * @param {boolean} disabled
 *
 * @return {object}
 */
const MoveDownButton = ({ onClick, disabled }) => (
    <Button
        disabled={disabled}
        outline
        size="sm"
        className="mr-1"
        onClick={() => onClick()}
    >
        <IonIcon icon="arrow-dropdown" hasText={false} />
    </Button>
);

export default MoveDownButton;
