/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';

const mapDispatchToProps = dispatch => ({
    onClick: (name, identifier, schema) => {
        dispatch({
            type: 'VIEW_TRANSFORMER',
            name,
            identifier,
            schema,
        });
    },
});

const mapStateToProps = state => ({
    identifier: state.transformer.identifier,
});

/**
 * Show list of items.
 *
 * @param {array} items
 * @param {function} render
 *
 * @return {object}
 */
const List = ({ items, render }) => (
    <ListGroup flush>
        {
            items.map((item, index) => (
                <ListGroupItem key={item.identifier}>
                    {render(item, index)}
                </ListGroupItem>
            ))
        }
    </ListGroup>
);

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    render: PropTypes.func.isRequired,
};

List.defaultProps = {
    items: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
