/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import EntityContainer from '../Entity/EntityContainer';
import EntityCreate from '../Entity/EntityCreate';
import '../../Entity/Entity.css';
import { schemaPropType } from '../../../validation';

/**
 * Create new entity.
 *
 * @param {string} tag
 * @param {object} operationIds
 * @param {string} entity
 * @param {object} params
 * @param {string} basePath
 * @param {object} additionalUiSchema
 * @param {array} mutators
 * @param {array} actions
 * @param {array} tabs
 *
 * @return {object}
 */
const Create = ({
    tag,
    operationIds,
    entity,
    params,
    basePath,
    additionalUiSchema,
    mutators,
    actions,
    tabs,
}) => (
    <EntityContainer
        tag={tag}
        operationIds={operationIds}
        entity={entity}
        params={params}
        basePath={basePath}
        additionalUiSchema={additionalUiSchema}
        mutators={mutators}
    >
        <EntityCreate
            tag={tag}
            operationIds={operationIds}
            params={params}
            basePath={basePath}
            actions={actions}
            tabs={tabs}
        />
    </EntityContainer>
);

Create.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
    basePath: PropTypes.string.isRequired,
    additionalUiSchema: schemaPropType,
    tabs: PropTypes.array,
};

Create.defaultProps = {
    params: {},
    additionalUiSchema: {},
    tabs: [],
};

export default Create;
