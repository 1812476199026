/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import {
    has, isEmpty, pickBy, isFunction,
} from 'lodash';
import { connect } from 'react-redux';
import ActionRow from './ActionRow';
import TableCell from './TableCell';
import Rows from './Rows';
import { selectRow } from '../../reducers/table/action';

/**
 * Render a row of a table.
 *
 * @param {object} table
 * @param {object}row
 * @param {integer} index
 * @param {array} columns
 * @param {array} renderers
 *
 * @return {object}
 */
const TableRow = ({
    table, row, index, columns, renderers,
}) => {
    const data = pickBy(
        row,
        (value, name) => (
            (columns.indexOf(name) !== -1) || isEmpty(columns)
        ),
    );

    return columns.map((column) => {
        const item = data[column];

        const definition = has(table, `entitySchema.properties.${column}`)
            ? table.entitySchema.properties[column]
            : { type: 'string' };

        return (
            <TableCell
                key={`${index}-${column}`}
                name={column}
                item={item}
                definition={definition}
                context={data}
                renderers={renderers}
            />
        );
    });
};

function renderMassActionColumn(table, row, dispatch) {
    return (
        <td>
            <p>
                <input
                    type="checkbox"
                    checked={table.selected.indexOf(row.identifier) > -1}
                    onClick={(event) => {
                        event.stopPropagation();

                        dispatch(selectRow(
                            table.id,
                            row.identifier,
                        ));
                    }}
                />
            </p>
        </td>
    );
}

/**
 * Render the body section of a table.
 *
 * @todo Refactor actionColumns to support array/multiple columns.
 *
 * @param {object} table
 * @param {array} data
 * @param {array} columns
 * @param {object} actionsColumn
 * @param {object} massActionColumn
 * @param {object} renderers
 * @param {function} viewAction
 * @param {function} dispatch
 *
 * @return {string}
 */
const TableBody = ({
    table, data, columns, actionsColumn, massActionColumn, renderers, viewAction, dispatch,
}) => {
    const rows = data.map((row, index) => (
        <ActionRow key={`row-${row.identifier || index}`} viewAction={isFunction(viewAction) ? viewAction(row) : null}>
            { massActionColumn ? renderMassActionColumn(table, row, dispatch) : null }
            <TableRow
                row={row}
                index={index}
                table={table}
                columns={columns}
                renderers={renderers}
            />
            { isFunction(actionsColumn) ? actionsColumn(row) : null }
        </ActionRow>
    ));

    return (
        <tbody>
            <Rows id={table.id} rows={rows} />
        </tbody>
    );
};

export default connect()(TableBody);
