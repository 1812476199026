/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import HttpClient from '../../../core/HttpClient';
import PageHeader from '../../Ui/PageHeader';
import DiForm from '.';
import Redirect from '../../../core/Redirect';

class Create extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            formData: { interface: this.props.interface },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handle change.
     *
     * @param formData
     */
    handleChange(formData) {
        this.setState({
            formData,
        });
    }

    /**
     * Handle submit.
     *
     * @param formData
     */
    handleSubmit(formData) {
        HttpClient.request('createObjectConfiguration', null, formData)
            .then(() => {
                Redirect(this.props.history, this.props.baseUrl);
            });
    }

    /**
     * Render the component.
     *
     * @returns {object}
     */
    render() {
        return (
            <>
                <PageHeader title={this.props.title} />
                <Card>
                    <CardBody>
                        <DiForm
                            interface={this.props.interface}
                            formData={this.state.formData}
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                        />
                    </CardBody>
                </Card>
            </>
        );
    }
}

Create.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    interface: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default withRouter(Create);
