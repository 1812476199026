/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import PropTypes from 'prop-types';

const schemaPropType = PropTypes.object;

const apiDataPropType = PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
]);

const apiResultType = PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
]));

const tablePropTypes = {
    id: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    numberOfItems: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    where: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    entitySchema: PropTypes.object.isRequired,
    sortColumn: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    sortDirection: PropTypes.string.isRequired,
};

const Validation = {
    table: tablePropTypes,
    schema: schemaPropType,
    apiResult: apiDataPropType,
};

export default Validation;
export {
    tablePropTypes, schemaPropType, apiDataPropType, apiResultType,
};
