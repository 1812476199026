/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { pickBy } from 'lodash';
import Config from '../../core/Config';

/**
 * Template for newly created tables.
 *
 * @type {{}}
 */
const tableTemplate = {
    id: null,
    entity: null,
    page: 1,
    limit: Config.api.numRecords,
    numberOfItems: 0,
    data: [],
    where: {},
    selected: [],
    massChecked: false,
    columns: {},
    entitySchema: { type: 'object', properties: {} },
    sortColumn: false,
    sortDirection: 'ASC',
};

const maxPage = (items, limit) => Math.ceil((items / limit));

/**
 * Mutate table by ID and return copy of all tables.
 *
 * @param tables
 * @param id
 * @param mutator
 */
const tableMutator = (tables, id, mutator) => tables.map((t) => {
    if (t.id === id) {
        return {
            ...t,
            ...mutator(t),
        };
    }

    return t;
});

const tableReducer = (state = {
    tables: [
        {
            ...tableTemplate,
            id: 'SubscriberType',
            entity: 'SubscriberType',
        },
        {
            ...tableTemplate,
            id: 'SubscriberConfiguration',
            entity: 'SubscriberConfiguration',
        },
        {
            ...tableTemplate,
            id: 'TaskQueue',
            entity: 'Task',
            where: {
                status: 'new',
            },
            sortColumn: 'identifier',
            sortDirection: 'DESC',
        },
        {
            ...tableTemplate,
            id: 'WebHook',
            entity: 'Hook',
        },
        {
            ...tableTemplate,
            id: 'WebHookQueue',
            entity: 'HookFile',
        },
        {
            ...tableTemplate,
            id: 'WebHookProcessing',
            entity: 'HookFile',
        },
        {
            ...tableTemplate,
            id: 'WebHookFinished',
            entity: 'HookFile',
        },
        {
            ...tableTemplate,
            id: 'WebHookFailed',
            entity: 'HookFile',
        },
        {
            ...tableTemplate,
            id: 'SharedWebHook',
            entity: 'Webhook',
        },
        {
            ...tableTemplate,
            id: 'SharedWebHookFiles',
            entity: 'WebhookFile',
        },
        {
            ...tableTemplate,
            id: 'WebhookProgress',
            entity: 'WebhookProgress',
        },
        {
            ...tableTemplate,
            id: 'RoutesConfigurations',
            entity: 'RouteConfiguration',
        },
        {
            ...tableTemplate,
            id: 'EntitiesTypes',
            entity: 'EntityType',
        },
        {
            ...tableTemplate,
            id: 'EntitiesConfigurations',
            entity: 'EntityConfiguration',
        },
        {
            ...tableTemplate,
            id: 'PublishersTypes',
            entity: 'PublisherType',
        },
        {
            ...tableTemplate,
            id: 'PublishersConfigurations',
            entity: 'PublisherConfiguration',
        },
        {
            ...tableTemplate,
            id: 'TransformersTypes',
            entity: 'TransformerType',
        },
        {
            ...tableTemplate,
            id: 'TransformersConfigurations',
            entity: 'TransformerConfiguration',
        },
        {
            ...tableTemplate,
            id: 'Remotes',
            entity: 'Remote',
        },
        {
            ...tableTemplate,
            id: 'HttpClientConfigurations',
            entity: 'HttpClientConfiguration',
        },
        {
            ...tableTemplate,
            id: 'HttpClientTypes',
            entity: 'ConfigurationType',
        },
        {
            ...tableTemplate,
            id: 'StorageConfigurations',
            entity: 'StorageConfiguration',
        },
        {
            ...tableTemplate,
            id: 'StorageTypes',
            entity: 'StorageType',
        },
        {
            ...tableTemplate,
            id: 'StorageKeys',
            entity: 'StorageKey',
        },
        {
            ...tableTemplate,
            id: 'FilesystemConfigurations',
            entity: 'FilesystemConfiguration',
        },
        {
            ...tableTemplate,
            id: 'FilesystemTypes',
            entity: 'FilesystemType',
        },
        {
            ...tableTemplate,
            id: 'PdoClientConfigurations',
            entity: 'PdoClientConfiguration',
        },
        {
            ...tableTemplate,
            id: 'PdoClientTypes',
            entity: 'ConfigurationType',
        },
        {
            ...tableTemplate,
            id: 'SystemVariables',
            entity: 'SystemVariable',
        },
        {
            ...tableTemplate,
            id: 'EnvironmentVariables',
            entity: 'EnvironmentVariable',
        },
        {
            ...tableTemplate,
            id: 'Flags',
            entity: 'FlagEntity',
        },
        {
            ...tableTemplate,
            id: 'DiObjectConfiguration',
            entity: 'DiObjectConfiguration',
        },
        {
            ...tableTemplate,
            id: 'Users',
            entity: 'User',
        },
    ],
}, action) => {
    switch (action.type) {
        case 'INCREMENT_PAGE':
            return {
                ...state,
                tables: tableMutator(
                    state.tables,
                    action.id,
                    table => ({
                        page: maxPage(table.numberOfItems, table.limit) >= (table.page + 1)
                            ? (table.page + 1)
                            : table.page,
                    }),
                ),
            };
        case 'DECREMENT_PAGE':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    page: (table.page <= 1 ? 1 : table.page - 1),
                })),
            };

        case 'CHANGE_PAGE':
            return {
                ...state,
                tables: tableMutator(
                    state.tables,
                    action.id,
                    () => ({ page: (action.page <= 1 ? 1 : parseInt(action.page, 10)) }),
                ),
            };
        case 'LOAD_API_DATA':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, () => ({
                    numberOfItems: action.records,
                    data: action.data,
                    selected: [],
                    massChecked: false,
                })),
            };
        case 'ON_UPDATE':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    data: table.data.reduce(
                        (carry, row) => {
                            carry.push(
                                row[action.identifierColumn] === action.row[action.identifierColumn]
                                    ? action.row
                                    : row,
                            );

                            return carry;
                        },
                        [],
                    ),
                })),
            };
        case 'ON_DELETE':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    data: table.data.reduce(
                        (carry, row) => {
                            if (row !== action.row) {
                                carry.push(row);
                            }

                            return carry;
                        },
                        [],
                    ),
                })),
            };
        case 'ON_TOGGLE':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    data: table.data.reduce(
                        (carry, row) => {
                            const mutableRow = row;
                            if (row === action.row) {
                                mutableRow.enabled = !row.enabled;
                            }

                            carry.push(mutableRow);
                            return carry;
                        },
                        [],
                    ),
                })),
            };
        case 'CHANGE_LIMIT':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, () => ({ limit: action.limit })),
            };
        case 'APPLY_FILTER':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, () => ({
                    page: 1,
                    where: action.formData,
                })),
            };
        case 'CLEAR_FILTER':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, () => ({
                    where: {},
                    page: 1,
                    sortColumn: false,
                    sortDirection: 'ASC',
                })),
            };
        case 'REMOVE_FILTER':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    where: pickBy(table.where, (value, prop) => prop !== action.field),
                })),
            };
        case 'TOGGLE_FILTER':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    columnActive: false,
                    filterActive: !table.filterActive,
                })),
            };
        case 'TOGGLE_COLUMN':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    filterActive: false,
                    columnActive: !table.columnActive,
                })),
            };
        case 'APPLY_COLUMNS': {
            const columns = pickBy(action.columns, item => item);
            return {
                ...state,
                tables: tableMutator(
                    state.tables,
                    action.id, () => ({ columns }),
                ),
            };
        }
        case 'EXPANDED_ENTITY_SCHEMA':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, () => ({
                    entitySchema: action.entitySchema,
                })),
            };
        case 'SORT_COLUMN':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    sortDirection: (table.sortDirection === 'ASC' ? 'DESC' : 'ASC'),
                    sortColumn: action.column,
                })),
            };
        case 'SELECT_ROUTE_FILTER':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    where: {
                        ...table.filter,
                        subscriber_configuration: action.subscriberConfiguration,
                        publisher_configuration: action.publisherConfiguration,
                    },
                })),
            };
        case 'CLEAR_ROUTE_FILTER':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, table => ({
                    where: {
                        ...table.filter,
                        subscriber_configuration: undefined,
                        publisher_configuration: undefined,
                    },
                })),
            };
        case 'SELECT_ROW':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, (table) => {
                    const { selected } = table;
                    const index = selected.indexOf(action.row);

                    if (index > -1) {
                        delete selected[index];
                    } else {
                        selected.push(action.row);
                    }

                    return { selected };
                }),
            };
        case 'SELECT_ROWS':
            return {
                ...state,
                tables: tableMutator(state.tables, action.id, (table) => {
                    const { massChecked } = table;

                    return {
                        selected: massChecked ? [] : action.rows,
                        massChecked: !massChecked,
                    };
                }),
            };
        default:
            return state;
    }
};

export default tableReducer;
