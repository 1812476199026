/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { has, forEach } from 'lodash';
import Range from './Range';
import DateRange from './DateRange';
import Like from './Like';
import InArray from './InArray';

/**
 * Supported field types of UI schema.
 *
 * @type {string[]}
 */
const SUPPORTED_UI_TYPES = [
    'integer',
    'string',
    'date',
    'array',
];

/**
 * Supported field parsers.
 *
 * @type {object}
 */
const parsers = {
    integer: (name, field) => Range.parse(name, field),
    string: (name, field) => Like.parse(name, field),
    date: (name, field) => DateRange.parse(name, field),
    array: (name, field) => InArray.parse(name, field),
    default: (name, field) => Like.parse(name, field),
};

/**
 * Supported ui parsers.
 *
 * @type {object}
 */
const uiParsers = {
    integer: (name, field) => Range.parseUi(name, field),
    string: (name, field) => Like.parseUi(name, field),
    date: (name, field) => DateRange.parseUi(name, field),
    array: (name, field) => InArray.parseUi(name, field),
    default: (name, field) => field,
};

class FilterParser {
    /**
     * Generate filter JSON schema.
     *
     * @param {object} schema
     * @param {array} disabledFilters
     * @param {object} entityUiSchema
     *
     * @returns {object}
     */
    static getFilterSchema(schema, disabledFilters, entityUiSchema) {
        const filter = {
            type: 'object',
            properties: {},
        };

        if (!Object.prototype.hasOwnProperty.call(schema, 'properties')) {
            return filter;
        }

        forEach(schema.properties, (property, field) => {
            if (!disabledFilters.includes(field)) {
                filter.properties[field] = FilterParser.parse(field, property);

                // Not all fields of the Alumio API are filterable natively.
                // Therefore the UI schema can mark these fields and assign a
                // new type and UI widget which can be used as a work around.
                if (FilterParser.hasFilterWidget(entityUiSchema, field)) {
                    filter.properties[field] = FilterParser.getObjectFilterProperty(filter.properties[field]);
                }
            }
        });

        return filter;
    }

    /**
     * Generate UI schema.
     *
     * @param {string} schema
     * @param {object} entityUiSchema
     *
     * @returns {object}
     */
    static getUiSchema(schema, entityUiSchema) {
        const filter = {};

        if (!has(schema, 'properties')) {
            return filter;
        }

        forEach(schema.properties, (field, name) => {
            if (FilterParser.hasUiSchema(field.type)) {
                filter[name] = FilterParser.parseUi(name, field);
            }


            if (FilterParser.hasFilterUiField(entityUiSchema, name)) {
                filter[name] = {
                    'ui:field': entityUiSchema[name].filter,
                };
            }
        });

        return filter;
    }

    /**
     * Parse a schema.
     *
     * @param {string} name
     * @param {object} field
     *
     * @returns {*}
     */
    static parse(name, field) {
        return has(parsers, field.type)
            ? parsers[field.type](name, field)
            : parsers.default(name, field);
    }

    /**
     * Parse a ui schema.
     *
     * @param {string} name
     * @param {object} field
     *
     * @returns {*}
     */
    static parseUi(name, field) {
        return has(uiParsers, field.type)
            ? uiParsers[field.type](name, field)
            : uiParsers.default(name, field);
    }

    /**
     * Whether the field type is supported.
     *
     * @param {string} type
     *
     * @return {boolean}
     */
    static hasUiSchema(type) {
        return SUPPORTED_UI_TYPES.indexOf(type) !== -1;
    }

    /**
     * Whether the UI schema contains a filter definition of the provided field.
     *
     * @param {object} uiSchema
     * @param {string} field
     *
     * @returns {bool}
     */
    static hasFilterWidget(uiSchema, field) {
        return (uiSchema[field] && uiSchema[field].filter);
    }

    /**
     * Whether the UI schema contains a ui:field for the provided field.
     *
     * @param {object} uiSchema
     * @param {string} name
     *
     * @returns {bool}
     */
    static hasFilterUiField(uiSchema, name) {
        return (
            uiSchema[name]
            && uiSchema[name].filter
        );
    }

    /**
     * Get object filter.
     *
     * @param {object} property
     *
     * @return {object}
     */
    static getObjectFilterProperty(property) {
        return {
            type: 'object',
            magement: {
                original: { ...property },
            },
        };
    }
}

export default FilterParser;
