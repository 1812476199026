/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const apiReducer = (
    state = {
        isRequesting: false,
        isError: false,
        message: null,
    },
    action,
) => {
    switch (action.type) {
        case 'REQUEST_START':
            return {
                ...state,
                isRequesting: true,
                isError: false,
            };
        case 'REQUEST_FINISHED':
            return {
                ...state,
                isRequesting: false,
                isError: false,
            };
        case 'REQUEST_FAILED':
            return {
                ...state,
                isRequesting: false,
                isError: true,
                message: action.message,
            };
        case 'CLOSE_ERROR_MESSAGE':
            return {
                ...state,
                isError: false,
            };
        default:
            return state;
    }
};

export default apiReducer;
