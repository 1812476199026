/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import Equals from './Equals';

class Like extends Equals {
    /**
     * Get a like field.
     *
     * @param {string} name
     * @param  {object} field
     *
     * @returns {string}
     */
    static parse(name, field) {
        return field.enum
            ? super.parse(name, field)
            : {
                type: 'object',
                properties: {
                    like: {
                        type: 'string',
                        title: field.title,
                    },
                },
            };
    }

    /**
     * Parse UI schema.
     *
     * @returns {object}
     */
    static parseUi(name, field) {
        return field.enum ? {} : { 'ui:field': 'like' };
    }
}

export default Like;
