/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';

class ObjectFilter extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Format form data and notify parent.
     *
     * @param {string} formData
     */
    handleChange(formData) {
        // Workaround to support object filters in the Magement API.
        // Format the data structure into an "in array" filter.
        const data = {
            inq: {
                identifier: formData,
            },
        };

        this.props.onChange(data);
    }

    render() {
        const formData = this.props.formData.inq
            ? this.props.formData.inq.identifier
            : '';

        return (
            <SchemaField
                schema={this.props.schema.magement.original}
                registry={this.props.registry}
                onChange={this.handleChange}
                formData={formData}
            />
        );
    }
}

export default ObjectFilter;
