/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import MagementField from './MagementField';

/**
 * Render field based on type.
 *
 * @param {object} props
 *
 * @returns {object}
 */
const FieldFactory = (props) => {
    if (props.id === 'root') {
        return props.children;
    }

    const childElements = props.schema.type
        ? props.children
        : <MagementField {...props.children[0].props} />;

    return (
        <div className={`form-group row ${props.classNames}`}>
            { props.label !== null && props.label !== undefined
                && (
                    <label
                        className={`col-sm-2 col-form-label ${props.id}`}
                        htmlFor={props.id}
                    >
                        {props.label.replace(/_/g, ' ')}
                    </label>
                )
            }
            <div className="col-sm-10">
                {childElements}
                {props.errors}
                {props.help}
            </div>
        </div>
    );
};

export default FieldFactory;
