/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

class Interval extends React.Component {
    /**
     * Register interval.
     *
     * @return {void}
     */
    componentDidMount() {
        this.interval = setInterval(
            () => {
                this.props.callback();
            },
            (this.props.time * 1000),
        );
    }

    /**
     * Remove interval.
     *
     * @return {void}
     */
    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    /**
     * Render component.
     *
     * @return {array|null}
     */
    render() {
        return this.props.children || null;
    }
}

Interval.propTypes = {
    time: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired,
};

export default Interval;
