/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import EntityContainer from '../Entity/EntityContainer';
import EntityView from '../Entity/EntityView';
import '../../Entity/Entity.css';

/**
 * Remote details.
 *
 * @param {string} tag
 * @param {object} operationIds
 * @param {string} entity
 * @param {object} params
 * @param {string} basePath
 *
 * @return {object}
 */
const Details = ({
    tag,
    operationIds,
    entity,
    params,
    basePath,
}) => (
    <EntityContainer
        tag={tag}
        operationIds={operationIds}
        entity={entity}
        params={params}
        basePath={basePath}
    >
        <EntityView
            tag={tag}
            operationIds={operationIds}
            entity={entity}
            params={params}
            basePath={basePath}
        />
    </EntityContainer>
);

Details.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
};

Details.defaultProps = {
    params: {},
};

export default Details;
