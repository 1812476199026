/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import render from './Render';

const KEY_MAPPING = {
    ENTER: 13,
};

/**
 * Clickable components must have a enter action to aid
 * users with a screen reader. This handler calls the
 * provider action when a user presses Enter.
 *
 * @param event
 * @param action
 */
const onEnterHandler = (event, action) => {
    if (event.keyCode === KEY_MAPPING.ENTER) {
        action();
    }
};

export default render;
export { KEY_MAPPING, onEnterHandler };
