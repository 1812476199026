/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { pickBy, isEmpty } from 'lodash';

class RangeFilter extends React.Component {
    /**
     * Constructor.
     *
      * @param {object} props
     */
    constructor(props) {
        super(props);

        const initial = this.sanitize(this.props.formData);
        this.state = { ...initial };

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle form changes.
     *
     * @param {object} event
     */
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.props.onChange(this.sanitize(this.state));
        });
    }

    /**
     * Sanitize filter output.
     *
     * @param {object} filter
     *
     * @return {?object}
     */
    sanitize(filter) {
        const result = pickBy(filter, (subject) => {
            return subject !== undefined && subject !== '';
        });

        return !isEmpty(result)
            ? result
            : undefined;
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        return (
            <div className="d-flex">
                <div className="flex-grow-1 mr-2">
                    <div className="input-group">
                        <input
                            id={this.props.name}
                            onChange={this.handleChange}
                            className="form-control"
                            type={this.props.HTML5Type}
                            name="gte"
                            value={this.state.gte}
                        />
                    </div>
                </div>
                <div className="flex-grow-1 ml-1">
                    <div className="input-group">
                        <input
                            onChange={this.handleChange}
                            className="form-control"
                            type={this.props.HTML5Type}
                            name="lte"
                            value={this.state.lte}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

RangeFilter.propTypes = {
    formData: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    HTML5Type: PropTypes.string,
};

RangeFilter.defaultProps = {
    HTML5Type: 'text',
};

export default RangeFilter;
