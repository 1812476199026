/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '../../Table';
import Details from './Details';
import ActionColumn from '../../Table/ActionColumn';
import Retry from './Retry';
import FeatureDetect from '../../../core/FeatureDetect';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

const mapDispatchToProps = dispatch => ({
    onUpdatedTasks: (id, rows) => {
        rows.forEach((row) => {
            dispatch({
                type: 'ON_UPDATE',
                identifierColumn: 'identifier',
                row,
                id,
            });
        });
    },
});

/**
 * Get actions column.
 *
 * @param {object} table
 * @param {function} onUpdatedTasks
 *
 * @returns {function}
 */
const getActionsColumn = (table, onUpdatedTasks) => {
    return (row) => {
        return row.status !== 'new'
            ? (
                <ActionColumn>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Retry
                                inline
                                tag="Task"
                                table={Table}
                                operationId="retryTasks"
                                onSuccess={
                                    (response) => {
                                        onUpdatedTasks(table.id, response.results);
                                    }
                                }
                                formData={{ taskIds: [row.identifier] }}
                            />
                        </li>
                    </ul>
                </ActionColumn>
            )
            : <ActionColumn />;
    };
};

/**
 * Show task queue.
 *
 * @param {string} props
 *
 * @return {object}
 */
const Task = (props) => {
    const table = props.tables.find(t => t.id === 'TaskQueue');
    const FeatureDetection = new FeatureDetect(props.schema);
    const selected = table.selected.filter((taskId) => {
        const match = table.data.find((row) => {
            return row.identifier === taskId;
        });

        return match && match.status !== 'new';
    });

    return (
        <Table
            title="Task Queue"
            id="TaskQueue"
            tag="Tasks"
            operationId="getTasks"
            table={table}
            viewAction={(row) => { return `/tasks/queue/${row.identifier}`; }}
            massAction
            buttons={(selected.length > 0 && FeatureDetection.hasOperationId('retryTasks'))
                ? [
                    <Retry
                        inline
                        tag="Task"
                        table={Table}
                        operationId="retryTasks"
                        onSuccess={
                            (response) => {
                                props.onUpdatedTasks(table.id, response.results);
                            }
                        }
                        formData={{ taskIds: selected }}
                    />,
                ]
                : []
            }
            actionsColumn={
                FeatureDetection.hasOperationId('retryTasks')
                    ? getActionsColumn(table, props.onUpdatedTasks)
                    : undefined
            }
        />
    );
};

Task.propTypes = {
    tables: PropTypes.arrayOf(PropTypes.object).isRequired,
    schema: PropTypes.object.isRequired,
    onUpdatedTasks: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Task);

export { Details };
