/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import Form from 'react-jsonschema-form';
import { Button } from 'reactstrap';
import Bootstrap4Template from '../../../Ui/Form/Bootstrap4Template';
import HttpClient from '../../../../core/HttpClient';
import JsonEditField from '../../../Ui/Form/JsonEditField';
import { addError, addTestEntity, closeError } from '../../../../reducers/transformer/actions';
import ErrorMessage from '../../../Ui/Messages/ErrorMessage';
import IonIcon from '../../../Ui/Icons/IonIcon';

class TestData extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            response: undefined,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * Handle form submit.
     *
     * @param {object} formData
     */
    onSubmit({ formData }) {
        this.props.dispatch(
            addTestEntity(
                this.props.configuration.identifier,
                formData,
            ),
        );

        try {
            HttpClient.request(
                'executeVirtualTransformer',
                {
                    type: this.props.configuration.type,
                },
                {
                    entities: JSON.parse(formData.textarea),
                    configuration: this.props.configuration.parameters,
                },
            ).then((response) => {
                this.setState({ response });
            }).catch((e) => {
                this.props.dispatch(addError(e.message));
            });
        } catch (e) {
            this.props.dispatch(addError(e.message));
        }
    }

    /**
     * Render component.
     *
     * @returns {object}
     */
    render() {
        return (
            <>
                <ErrorMessage
                    onClose={() => this.props.dispatch(closeError())}
                    isOpen={this.props.error.hasError}
                    message={this.props.error.errorMessage}
                />

                <Form
                    FieldTemplate={Bootstrap4Template}
                    onSubmit={this.onSubmit}
                    schema={{
                        type: 'object',
                        properties: {
                            textarea: {
                                type: 'string',
                                title: 'Test Data',
                            },
                        },
                    }}
                    uiSchema={{
                        textarea: {
                            'ui:widget': 'json',
                        },
                    }}
                    widgets={{ json: JsonEditField }}
                >
                    {
                        this.state.response
                        && (
                            <fieldset>
                                <div className="form-group row form-group field field-string">
                                    <span className="col-sm-2 col-form-label">Test Results</span>
                                    <div className="col-sm-10">
                                        <ReactJson
                                            name={false}
                                            src={this.state.response}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                            enableClipboard={false}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                        )
                    }

                    <Button color="pink" disabled={this.props.api.isRequesting}>
                        <IonIcon icon="beaker" />
                        Test
                    </Button>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    configuration: state.transformer.testData,
    error: state.transformer.error,
    entities: state.transformer.entities,
    api: state.api,
});


TestData.propTypes = {
    configuration: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    null,
)(TestData);
