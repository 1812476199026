/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from '../../Table/index';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

/**
 * Show web hooks.
 *
 * @param {object} props
 *
 * @return {object}
 */
const SharedWebHooks = (props) => {
    const table = props.tables.find(t => t.id === 'SharedWebHook');

    return (
        <Table
            title="Web Hooks"
            id="SharedWebHook"
            tag="Hooks"
            operationId="getHooks"
            table={table}
            viewAction={(row) => { return `/webhooks/${row.identifier}`; }}
        />
    );
};

export default withRouter(connect(
    mapStateToProps,
    null,
)(SharedWebHooks));
