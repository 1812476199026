/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Form from 'react-jsonschema-form';
import './MappingFilter.scss';

const MappingFilterType = ({
    data, items, onChange, filter, onFilter,
}) => {
    const values = items.map((item) => {
        return item.value;
    });

    const labels = items.map((item) => {
        return item.label;
    });

    const schema = {
        type: 'array',
        items: {
            type: 'string',
            enum: values,
            enumNames: labels,
        },
        uniqueItems: true,
    };

    const uiSchema = {
        'ui:widget': 'checkboxes',
        'ui:options': {
            inline: true,
        },
    };

    return (
        <div className="form-row align-items-center">
            <div className="col-auto">
                <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Filter by source"
                    value={filter}
                    onChange={event => onFilter(event.target.value)}
                />
            </div>
            <div className="col-auto">
                <div className="form-check">
                    <Form
                        id="mapping-filter-form"
                        formData={data}
                        onChange={form => onChange(form.formData)}
                        schema={schema}
                        uiSchema={uiSchema}
                    >
                        { ' ' }
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default MappingFilterType;
