/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import SelectContainer from './SelectContainer';
import PageHeader from '../../Ui/PageHeader';
import Legend from './Legend';
import Help from './Help';
import KeyMappings from './KeyMapping/KeyMappings';
import {
    selectMappingType,
    selectMappingSource,
    selectMappingDestination,
} from '../../../reducers/mapping/actions';
import Reload from './Actions/Reload';
import Swap from './KeyMapping/Actions/Swap';

const mapStateToProps = state => ({
    mapping: state.mapping,
    api: state.api,
});

class Mapping extends React.Component {
    constructor(props) {
        super(props);

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSourceChange = this.handleSourceChange.bind(this);
        this.handleDestinationChange = this.handleDestinationChange.bind(this);
    }

    /**
     * Handle type change.
     *
     * @param {object} type
     */
    handleTypeChange(type) {
        this.props.dispatch(selectMappingType(type));
    }

    /**
     * Handle source change.
     *
     * @param {object} remote
     */
    handleSourceChange(remote) {
        this.props.dispatch(selectMappingSource(remote));
    }

    /**
     * Handle destination change.
     *
     * @param {object} remote
     */
    handleDestinationChange(remote) {
        this.props.dispatch(selectMappingDestination(remote));
    }

    /**
     * Render components.
     *
     * @returns {*}
     */
    render() {
        const {
            type, source, destination, data, suggestions,
        } = this.props.mapping;

        const mapping = data.length > 0
            ? (
                <KeyMappings
                    type={type}
                    source={source}
                    destination={destination}
                    data={data}
                    suggestions={suggestions}
                />
            )
            : <p>Select options from the menu above to begin.</p>;

        return (
            <div>
                <PageHeader title="Mapping">
                    <Reload disabled={this.props.api.isRequesting} />
                    <Swap isSubmitting={this.props.api.isRequesting} />
                    <Help>
                        <Legend />
                    </Help>
                </PageHeader>
                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-4">
                                <SelectContainer
                                    label="Type"
                                    tag="Mapping"
                                    operationId="getMappingTypes"
                                    onSelect={this.handleTypeChange}
                                    selectedOption={type}
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectContainer
                                    label="Source"
                                    tag="Remotes"
                                    operationId="getRemotes"
                                    onSelect={this.handleSourceChange}
                                    selectedOption={source}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="flex">
                                    <div className="flex-grow-1">
                                        <SelectContainer
                                            label="Destination"
                                            tag="Remotes"
                                            operationId="getRemotes"
                                            onSelect={this.handleDestinationChange}
                                            selectedOption={destination}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-12">
                                { mapping }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Mapping);
