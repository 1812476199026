/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SaveButton from '../../../../Ui/Buttons/SaveButton';
import {
    applyMappingValueChanges,
} from '../../../../../reducers/mapping/actions';

const mapStateToProps = state => ({
    mapping: state.mapping,
});

/**
 * Show the amount of pending changes.
 *
 * @param {object} keyMapping
 * @param {array} deletions
 * @param {function} dispatch
 * @param {bool} isSubmitting
 * @returns {object}
 */
const PendingChanges = ({
    keyMapping,
    deletions,
    dispatch,
    isSubmitting,
}) => {
    const amount = (Object.keys(keyMapping).length + Object.keys(deletions).length);

    return amount > 0 && (
        <SaveButton
            className="mr-2"
            onClick={() => dispatch(applyMappingValueChanges())}
            isSubmitting={isSubmitting}
        />
    );
};

PendingChanges.propTypes = {
    keyMapping: PropTypes.objectOf(PropTypes.any),
    deletions: PropTypes.objectOf(PropTypes.any),
    isSubmitting: PropTypes.bool,
};

PendingChanges.defaultProps = {
    keyMapping: {},
    deletions: {},
    isSubmitting: false,
};

export default connect(mapStateToProps)(PendingChanges);
