/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import IonIcon from './IonIcon';

const CalendarIcon = ({ hasText, margin }) => (
    <IonIcon icon="calendar" hasText={hasText} margin={margin} />
);

export default CalendarIcon;
