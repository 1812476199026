/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

class FileDropReadOut extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            highlight: false,
        };

        this.fileInputRef = React.createRef();

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    /**
     * Add readout of file to the fileReadOut prop
     *
     * @param {object} event
     *
     * @return {void}
     */
    onFilesAdded(event) {
        // target is for use of upload button, dataTransfer is for onDrop
        const files = event.target.files || event.dataTransfer.files;

        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }

        if (files[0]) {
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                this.props.fileReadOut(reader.result);
            });

            reader.readAsText(files[0]);
        }
    }

    /**
     * On drag over
     *
     * @param {object} event
     *
     */
    onDragOver(event) {
        event.preventDefault();
        this.startHighlight();
    }

    /**
     * On drag leave
     *
     */
    onDragLeave() {
        this.stopHighlight();
    }

    /**
     * On file drop
     *
     * @param {object} event
     *
     */
    onDrop(event) {
        event.preventDefault();
        this.onFilesAdded(event);
        this.stopHighlight();
    }

    /**
     * When open dialog by click on drop area
     *
     */
    openFileDialog() {
        this.fileInputRef.current.click();
    }

    /**
     * Add new file to the array of file when there are multiple
     *
     * @param {array} list
     *
     * @return {array}
     */
    fileListToArray(list) {
        const array = [];
        /* eslint-disable no-plusplus */
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }

        return array;
    }

    /**
     * Start highlighting the drop area
     *
     * @return {void}
     */
    startHighlight() {
        this.setState({
            highlight: true,
        });

        this.props.highLight(true);
    }

    /**
     * Stop highlighting the drop area
     *
     * @return {void}
     */
    stopHighlight() {
        this.setState({
            highlight: false,
        });

        this.props.highLight(false);
    }

    /**
     * Render component and its children.
     * TODO: use fileTypes as an array for the possibility to allow multiple file types
     *
     * @return {object}
     */
    render() {
        const { fileType, hideUploadButton } = this.props;
        const buttonClass = (hideUploadButton) ? ' d-none' : '';

        return (
            <div
                className={`dropzone ${fileType}-dropzone ${this.state.highlight ? 'highlight' : ''}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                role="presentation"
                onClick={this.openFileDialog}
                data-hint={`Drop the ${fileType} file here`}
            >
                <input
                    ref={this.fileInputRef}
                    className={`FileInput${buttonClass}`}
                    type="file"
                    accept={`.${fileType}`}
                    onChange={this.onFilesAdded}
                />
            </div>
        );
    }
}

FileDropReadOut.propTypes = {
    fileType: PropTypes.string,
    fileReadOut: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]),
    highLight: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
    hideUploadButton: PropTypes.bool,
};

FileDropReadOut.defaultProps = {
    fileType: '',
    fileReadOut: '',
    highLight: false,
    hideUploadButton: false,
};

export default FileDropReadOut;
