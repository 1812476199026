/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import Column from './Column';
import PageHeader from '../Ui/PageHeader';
import Pagination from './Pagination';
import Limit from './Limit';
import Validation from '../../validation';
import RefreshButton from '../Ui/Buttons/RefreshButton';
import AdvancedFilter from './AdvancedFilter';

/**
 * Render the header of a table.
 *
 * @param {string} title
 * @param {array} disabledFilters
 * @param {string} table
 * @param {string} buttons
 * @param {boolean} supportsCrud
 *
 * @return {object}
 */
const TableHeader = ({
    title,
    sticky,
    disabledFilters,
    table,
    buttons,
    showPagination,
    showFilters,
    showColumnSelector,
    showRefresh,
    loadApiData,
    uiSchema,
}) => {
    const children = [];
    if (Array.isArray(buttons)) {
        buttons.map(button => children.push(button));
    }

    if (showRefresh) {
        children.push(
            <RefreshButton key={`${table.id}-refresh-btn`} onClick={() => (loadApiData())} />,
        );
    }

    if (showPagination) {
        children.push(
            <Pagination key={`${table.id}-pagination`} table={table} />,
            <Limit key={`${table.id}-limit`} table={table} />,
        );
    }

    if (showFilters) {
        children.push(
            <AdvancedFilter
                key={`${table.id}-advanced-filter`}
                table={table}
                disabledFilters={disabledFilters}
                uiSchema={uiSchema}
            />,
        );
    }

    if (showColumnSelector) {
        children.push(<Column key={`${table.id}-column`} table={table} />);
    }

    return (
        <PageHeader title={title} sticky={sticky}>
            {children}
        </PageHeader>
    );
};

TableHeader.propTypes = {
    title: PropTypes.string.isRequired,
    sticky: PropTypes.bool,
    disabledFilters: PropTypes.arrayOf(PropTypes.string),
    table: PropTypes.shape(Validation.table).isRequired,
    buttons: PropTypes.arrayOf(PropTypes.object),
    showPagination: PropTypes.bool,
    showFilters: PropTypes.bool,
    showColumnSelector: PropTypes.bool,
    showRefresh: PropTypes.bool,
    loadApiData: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
    sticky: true,
    disabledFilters: [],
    buttons: [],
    showPagination: true,
    showFilters: true,
    showColumnSelector: true,
    showRefresh: true,
};

export default TableHeader;
