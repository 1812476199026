/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { viewTransformer } from '../../../reducers/transformer/actions';
import InlineTextButton from '../../Ui/Buttons/InlineTextButton';
import { schemaPropType } from '../../../validation';

/**
 * Show transformer list item.
 *
 * @param {string} identifier
 * @param {number} index
 * @param {string} name
 * @param {object} schema
 * @param {function} dispatch
 *
 * @return {object}
 */
const TransformerType = ({
    identifier, index, name, schema, dispatch,
}) => {
    const handleClick = () => {
        dispatch(viewTransformer(identifier, name, schema));
    };

    return (
        <InlineTextButton
            label={name}
            index={index}
            onClick={handleClick}
        />
    );
};

TransformerType.propTypes = {
    identifier: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    schema: schemaPropType,
    dispatch: PropTypes.func.isRequired,
};

TransformerType.defaultProps = {
    schema: {},
};

export default connect()(TransformerType);
