/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { has, isObject } from 'lodash';
import { Card, CardBody } from 'reactstrap';
import Tabs from '../../Ui/Tabs';
import HttpClient from '../../../core/HttpClient';
import PageHeader from '../../Ui/PageHeader';
import Delete from './Delete';
import BackButton from '../../Ui/Buttons/BackButton';
import EntityDetails from './EntityDetails';
import EntityForm from './EntityForm';
import { ErrorMessage } from '../../Ui/Messages';
import store from '../../../store';
import { schemaPropType } from '../../../validation';
import FeatureDetect from '../../../core/FeatureDetect';

/**
 * @deprecated Will be replaced with Di Configuration.
 */
class EntityView extends React.Component {
    /**
     * Init data.
     *
     * @return {void}
     */
    componentDidMount() {
        this.loadEntityData();
    }

    /**
     * Load entity data.
     *
     * @return {Promise}
     */
    loadEntityData() {
        return HttpClient
            .request(
                this.props.operationIds.read,
                this.props.params,
            )
            .then((response) => {
                /**
                 * Support legacy and latest version of mediact/openapi-bundle.
                 * Single item API responses in latest versions are nested in
                 * an extra object:
                 *
                 *   { object: { name: foo } }
                 *
                 * The legacy version is not wrap items:
                 *
                 *   { name: foo }
                 */
                const item = response.object || response;

                // Not all entities support parameter schemas. Therefor the object must
                // be converted to a string making it editable by hand. The string is
                // converted back into an object for entities that support schemas.
                if (has(item, 'parameters') && isObject(item.parameters)) {
                    item.parameters = JSON.stringify(item.parameters);
                }

                this.props.onChange(item);
            })
            .catch(() => {
                // Error notification dispatched by HTTP client.
            });
    }

    /**
     * Render component
     *
     * @return {object}
     */
    render() {
        const FeatureDetection = new FeatureDetect(this.props.apiSchema);

        let tabs = [
            {
                id: 'overview',
                name: 'Overview',
                content: () => (
                    <EntityDetails
                        schema={this.props.schema}
                        data={this.props.data}
                        entity={this.props.entity}
                        renderers={this.props.renderers}
                    />
                ),
            },
        ];

        if (FeatureDetection.hasOperationId(this.props.operationIds.update)) {
            tabs.push({
                id: 'edit',
                name: 'Edit',
                content: () => (
                    <EntityForm
                        isSubmitting={this.props.api.isRequesting}
                        data={this.props.data}
                        schema={this.props.schema}
                        uiSchema={this.props.uiSchema}
                        onChange={form => this.props.onChange(form.formData)}
                        onSubmit={(form) => {
                            this.props.onSubmit(
                                form.formData,
                                this.props.tag,
                                this.props.operationIds.update,
                            );
                        }}
                    />
                ),
            });
        }

        tabs = tabs.concat(this.props.tabs);

        return (
            <div>
                <PageHeader title={this.props.data.name || this.props.entity}>
                    { this.props.actions }
                    {
                        FeatureDetection.hasOperationId(
                            this.props.operationIds.delete,
                        ) && (
                            <Delete
                                isRequesting={this.props.api.isRequesting}
                                tag={this.props.tag}
                                operationId={this.props.operationIds.delete}
                                params={this.props.params}
                                basePath={this.props.basePath}
                            />
                        )
                    }
                    <BackButton to={this.props.basePath} />
                </PageHeader>
                <ErrorMessage
                    message={this.props.api.message}
                    isOpen={this.props.api.isError}
                    onClose={() => store.dispatch({ type: 'CLOSE_ERROR_MESSAGE' })}
                />
                <Card>
                    <CardBody className="p-0">
                        <Tabs
                            tabs={tabs}
                            default="overview"
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

EntityView.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    params: PropTypes.objectOf(PropTypes.string).isRequired,
    basePath: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    api: PropTypes.oneOfType([
        PropTypes.object,
    ]),
    renderers: PropTypes.arrayOf(PropTypes.func),
    apiSchema: PropTypes.object.isRequired,
    uiSchema: schemaPropType,
    tabs: PropTypes.arrayOf(PropTypes.object),
};

EntityView.defaultProps = {
    api: {},
    renderers: [],
    uiSchema: {},
    tabs: [],
};

const mapStateToProps = state => ({
    apiSchema: state.schema.schema,
});

export default connect(mapStateToProps)(EntityView);
