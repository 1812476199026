/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    MoveDownButton, MoveUpButton, DeleteButton,
} from '../Buttons';

/**
 * Change the order of transformers.
 *
 * @param {string} identifier
 * @param {function} onMoveDown
 * @param {function} onMoveUp
 * @param {function} onDelete
 * @param {number} numItems
 * @param {number} position
 *
 * @return {object}
 */
const ChangeOrder = ({
    identifier,
    onMoveDown,
    onMoveUp,
    onDelete,
    numItems,
    position,
}) => {
    return (
        <div className="mr-3 mb-3">
            <MoveDownButton
                disabled={numItems === (position + 1)}
                onClick={() => onMoveDown(identifier)}
            />
            <MoveUpButton
                disabled={position === 0}
                onClick={() => onMoveUp(identifier)}
            />
            <DeleteButton
                inline
                size="sm"
                isSubmitting={numItems <= 1}
                confirm={false}
                onDelete={() => onDelete(identifier)}
            />
        </div>
    );
};

ChangeOrder.propTypes = {
    identifier: PropTypes.string.isRequired,
    onMoveDown: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ChangeOrder;
