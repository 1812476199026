/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Flag } from '../Magement';
import Route from './PrivateRoute';

const basePath = '/:environment/flag';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Flags = () => (
    <Switch>
        <Route
            exact
            path={`${basePath}/list`}
            render={() => (
                <Flag.Overview
                    id="Flags"
                    tag="Flags"
                    operationId="listFlags"
                />
            )}
        />
        <Route
            exact
            path={`${basePath}/:flag`}
            render={
                () => (
                    <Flag.Toggle
                        tag="Flags"
                        entity="FlagEntity"
                        basePath={basePath}
                        operationIds={{
                            enable: 'enableFlag',
                            disable: 'disableFlag',
                        }}
                    />
                )
            }
        />
    </Switch>
);

export default Flags;
