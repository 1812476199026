/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const TYPE_PROTOTYPE = 'TYPE_PROTOTYPE';
const TYPE_CONFIGURATION = 'TYPE_CONFIGURATION';

/**
 * Get formatted select options.
 *
 * @param {array} options
 * @param {string} type
 *
 * @returns {array}
 */
const getSelectOptions = (options, type) => {
    return options.map((option) => {
        return { label: option.name, value: option.identifier, type };
    });
};

/**
 * Get options.
 *
 * @param {array} prototypes
 * @param {array} configurations
 *
 * @returns {array}
 */
const getOptions = (prototypes, configurations) => {
    const options = [];

    const prototypeOptions = getSelectOptions(prototypes, TYPE_PROTOTYPE);
    if (prototypeOptions.length > 0) {
        options.push({
            label: 'Prototypes',
            options: prototypeOptions,
        });
    }

    const configurationOptions = getSelectOptions(configurations, TYPE_CONFIGURATION);
    if (configurationOptions.length > 0) {
        options.push({
            label: 'Configurations',
            options: configurationOptions,
        });
    }

    return options;
};

/**
 * Handle option select.
 *
 * @param {object} data
 * @param {function} onSelectPrototype
 * @param {function} onSelectConfiguration
 */
const handleChange = (data, onSelectPrototype, onSelectConfiguration) => {
    if (data.type === TYPE_PROTOTYPE) {
        onSelectPrototype(data.value);
    } else {
        onSelectConfiguration(data.value);
    }
};

/**
 * Select box to select prototype or configuration.
 *
 * @param {array} prototypes
 * @param {object} value
 * @param {array} configurations
 * @param {function} onSelectPrototype
 * @param {function} onSelectConfiguration
 *
 * @returns {object}
 */
const Select = ({
    prototypes,
    value,
    configurations,
    onSelectPrototype,
    onSelectConfiguration,
}) => {
    const options = getOptions(prototypes, configurations);

    const current = value
        ? { label: value.name, value: value.identifier }
        : null;

    return (
        <ReactSelect
            value={current}
            className="mb-3"
            options={options}
            onChange={data => handleChange(data, onSelectPrototype, onSelectConfiguration)}
        />
    );
};

Select.propTypes = {
    prototypes: PropTypes.array,
    configurations: PropTypes.array,
};

Select.defaultProps = {
    prototypes: [],
    configurations: [],
};

export default Select;
