/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Table from '../../Table/index';

/**
 * Show queued web hooks.
 *
 * @param {object} table
 * @param {string} identifier
 *
 * @return {object}
 */
const TableQueued = ({ table, identifier }) => (
    <Table
        id="WebHookQueue"
        table={table}
        tag="Hooks"
        operationId="getQueuedHookFiles"
        showHeader={false}
        apiParameters={{ hook: identifier }}
    />
);

export default TableQueued;
