/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type } from '../Magement';
import Route from './PrivateRoute';

const configurationsBasePath = '/:environment/subscribers/configurations';
const typesBasePath = '/:environment/subscribers/types';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Subscribers = () => (
    <Switch>
        <Route
            exact
            path={typesBasePath}
            render={() => (
                <Type.Overview
                    id="SubscriberType"
                    tag="Subscribers"
                    operationId="getSubscriberTypes"
                />
            )}
        />
        <Route
            exact
            path={`${typesBasePath}/:identifier`}
            render={
                props => (
                    <Type.Details
                        configuration={{
                            id: 'SubscriberConfiguration',
                            tag: 'Subscribers',
                            operationId: 'getSubscriberConfigurations',
                        }}
                        tag="Subscribers"
                        operationIds={{
                            read: 'getSubscriberType',
                        }}
                        entity="SubscriberType"
                        params={{ identifier: props.match.params.identifier }}
                    />
                )
            }
        />
        <Route
            exact
            path={configurationsBasePath}
            render={() => (
                <Configuration.Overview
                    id="SubscriberConfiguration"
                    tag="Subscribers"
                    operationId="getSubscriberConfigurations"
                    createOperationId="createSubscriberConfiguration"
                />
            )}
        />
        <Route
            exact
            path={`${configurationsBasePath}/create`}
            render={
                () => (
                    <Configuration.Create
                        tag="Subscribers"
                        operationIds={{
                            create: 'createSubscriberConfiguration',
                            read: 'getSubscriberConfiguration',
                            update: 'updateSubscriberConfiguration',
                            delete: 'deleteSubscriberConfiguration',
                        }}
                        entity="SubscriberConfiguration"
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${configurationsBasePath}/:identifier`}
            render={
                props => (
                    <Configuration.Details
                        tag="Subscribers"
                        operationIds={{
                            read: 'getSubscriberConfiguration',
                            update: 'updateSubscriberConfiguration',
                            delete: 'deleteSubscriberConfiguration',
                        }}
                        entity="SubscriberConfiguration"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
    </Switch>
);

export default Subscribers;
