/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import AvailableTransformers from '../Configuration';
import TransformerTypeContainer from './TransformerTypeContainer';

/**
 * @return {object}
 */
const TransformerTypesContainer = () => (
    <div>
        {
            AvailableTransformers.map(transformer => (
                <TransformerTypeContainer
                    identifier={transformer.operationId}
                    key={transformer.operationId}
                    operationId={transformer.operationId}
                    tag={transformer.tag}
                    name={transformer.name}
                />
            ))
        }
    </div>
);

export default TransformerTypesContainer;
