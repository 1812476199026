/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RemoveButton from '../../../Ui/Buttons/RemoveButton';

/**
 * Remove unsaved mapping change.
 *
 * @param {integer} source
 * @param {integer} destination
 * @param {function} dispatch
 * @param {function} onClick
 *
 * @returns {object}
 */
const RemoveMapping = ({
    source,
    destination,
    dispatch,
    onClick,
}) => {
    return (
        <RemoveButton
            onClick={() => dispatch(onClick(source, destination))}
        />
    );
};

RemoveMapping.propTypes = {
    source: PropTypes.number.isRequired,
    destination: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(RemoveMapping);
