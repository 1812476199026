/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { useState } from 'react';
import {
    Button, ButtonDropdown, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import Form from 'react-jsonschema-form';
import DateWidget from '../Form/DateWidget';
import CalendarIcon from '../Icons/CalendarIcon';
import './CustomRange.scss';

const CustomRange = ({ currentValues, isActive, onClick }) => {
    const [isOpen, setIsOpen] = useState(false);

    const className = isActive
        ? 'btn-primary text-white'
        : 'btn-secondary text-secondary';

    return (
        <ButtonDropdown
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <DropdownToggle color={`btn ${className}`}>
                Custom
                <CalendarIcon hasText margin="ml-2" />
            </DropdownToggle>
            <DropdownMenu>
                <div className="p-3 custom-range-form">
                    <Form
                        formData={currentValues}
                        onSubmit={(form) => {
                            setIsOpen(false);
                            onClick(form.formData);
                        }}
                        schema={{
                            type: 'object',
                            properties: {
                                from: {
                                    type: 'string',
                                    title: 'From:',
                                },
                                to: {
                                    type: 'string',
                                    title: 'To:',
                                },
                            },
                        }}
                        uiSchema={{
                            from: {
                                'ui:widget': 'DateWidget',
                            },
                            to: {
                                'ui:widget': 'DateWidget',
                            },
                        }}
                        widgets={{
                            DateWidget,
                        }}
                    >
                        <Button color="primary">Pick</Button>
                    </Form>
                </div>
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export default CustomRange;
