/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const defaultRules = {
    '$.table.tables[*].data': [],
};

const filterReducer = (state = { rules: defaultRules }, action) => {
    switch (action.type) {
        case 'REGISTER_RULE':
            return {
                ...state,
                rules: {
                    ...state.rules,
                    [action.rule.rule]: action.rule.value,
                },
            };
        case 'RESET_RULES':
            return {
                ...state,
                rules: defaultRules,
            };
        default:
            return state;
    }
};

export default filterReducer;
