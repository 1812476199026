/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    isEmpty, isEqual, pickBy,
} from 'lodash';
import TableData from './TableData';
import TableHeader from './TableHeader';
import expandSchema from '../../core/JsonSchema';
import AppliedFilters from './AppliedFilters';
import { getDisabledFilters, getDisabledColumns } from '../../core/JsonSchema/UiSchema';
import FeatureDetect from '../../core/FeatureDetect';
import { buildApiFilter, loadApiData } from '../../reducers/table/action';

const mapStateToProps = state => ({
    schema: state.schema.schema,
    uiSchema: state.schema.uiSchema,
});

/**
 * Whether data should be fetched from the API.
 *
 * @return {bool}
 */
const shouldFetchData = (current, next) => {
    const isColumnChange = current.sortColumn !== next.sortColumn;
    const isDirectionChange = current.sortDirection !== next.sortDirection;
    const isFilterChange = !isEqual(current.where, next.where);
    const isPageChange = current.page !== next.page;
    const isLimitChange = current.limit !== next.limit;

    return (
        isFilterChange
        || isColumnChange
        || isDirectionChange
        || isPageChange
        || isLimitChange
    );
};

const mapDispatchToProps = dispatch => ({
    onEntitySchemaExpanded: (id, entitySchema) => (
        dispatch({
            type: 'EXPANDED_ENTITY_SCHEMA',
            entitySchema,
            id,
        })
    ),
    onFailedApiCall: () => (
        dispatch({
            type: 'REQUEST_FAILED',
        })
    ),
    loadApiData: (id, operationId, filter) => (
        dispatch(loadApiData(id, operationId, filter))
    ),
});

class Table extends Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        expandSchema(this.props.table.id, this.props.table.entity, this.props.schema, this.props.uiSchema)
            .then((expandedSchema) => {
                this.props.onEntitySchemaExpanded(
                    this.props.table.id,
                    expandedSchema,
                );
            });

        this.state = {
            disabled: {
                filters: getDisabledFilters(
                    this.props.table.entity,
                    this.props.uiSchema,
                ),
                columns: getDisabledColumns(
                    this.props.table.entity,
                    this.props.uiSchema,
                ),
            },
        };

        this.featureDetection = new FeatureDetect(this.props.schema);
        this.loadApiData = this.loadApiData.bind(this);
    }

    /**
     * Set table to loading state and perform an
     * API call when the component is loaded to
     * populate the table.
     */
    componentWillMount() {
        this.loadApiData();
    }

    /**
     * When filter, sorting or pagination has
     * changed perform the next API call.
     *
     * @param {object} nextProps
     */
    componentWillReceiveProps(nextProps) {
        const { table } = this.props;
        const nextTable = nextProps.table;

        if (shouldFetchData(table, nextTable)) {
            this.loadApiData(nextTable);
        }
    }

    /**
     * Get API Filter.
     *
     * @param {object} table
     *
     * @returns {{filter: string}}
     */
    getFilter(table = false) {
        const data = table || this.props.table;
        const hardcodedFilter = this.props.filter || null;

        return buildApiFilter(data, hardcodedFilter, this.props.apiParameters);
    }

    /**
     * Perform an API call to our API backend to fetch data.
     */
    loadApiData(table = false) {
        this.props.loadApiData(
            this.props.table.id,
            this.props.operationId,
            this.getFilter(table),
        );
    }

    /**
     * Render pagination.
     *
     * @returns {*}
     */
    renderTableHeader(hasFilter) {
        if (
            !Object.prototype.hasOwnProperty.call(this.props, 'showHeader')
            || this.props.showHeader !== false
        ) {
            return (
                <TableHeader
                    table={this.props.table}
                    disabledFilters={this.state.disabled.filters}
                    title={this.props.title}
                    sticky={this.props.sticky}
                    buttons={this.props.buttons || []}
                    supportsCrud={!!this.props.supportsCrud}
                    {...this.props.header}
                    loadApiData={this.loadApiData}
                    uiSchema={this.props.uiSchema}
                    showFilters={hasFilter}
                    showPagination={hasFilter}
                />
            );
        }

        return null;
    }

    /**
     * Render the Grid with a Filter.
     *
     * @return {string}
     */
    render() {
        const chips = pickBy(
            this.props.table.where,
            filter => filter !== undefined && !isEmpty(filter),
        );

        const hasFilter = this.featureDetection.hasFilter(this.props.operationId);

        return (
            <div>
                {this.renderTableHeader(hasFilter)}
                {
                    (Object.keys(chips).length > 0)
                    && <AppliedFilters table={this.props.table} chips={chips} />
                }
                <div className="table-responsive">
                    <TableData
                        table={this.props.table}
                        massAction={this.props.massAction ? true : false}
                        viewAction={this.props.viewAction}
                        disabledColumns={this.state.disabled.columns}
                        actionsColumn={this.props.actionsColumn}
                        renderers={this.props.renderers}
                        uiSchema={this.props.uiSchema}
                        hasFilter={hasFilter}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Table);
