/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Item.scss';

const Item = ({ type, name }) => {
    return (
        <div className="legend-item">
            <div className={`circle ${type}`} />
            <div className="legend-name">{ name }</div>
        </div>
    );
};

Item.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default Item;
