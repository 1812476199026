/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const MAPPING_MAPPED = 'mapped';
const MAPPING_MATCHED = 'matched';
const MAPPING_PENDING = 'pending';
const MAPPING_UNMAPPED = 'unmapped';
const MAPPING_DELETED = 'deleted';

const MappingStatus = {
    MAPPING_MAPPED,
    MAPPING_MATCHED,
    MAPPING_PENDING,
    MAPPING_UNMAPPED,
    MAPPING_DELETED,
};

const MappingNames = [
    { value: MAPPING_MAPPED, label: 'Mapped' },
    { value: MAPPING_MATCHED, label: 'Suggestion' },
    { value: MAPPING_UNMAPPED, label: 'Unmatched' },
];

export default MappingStatus;
export {
    MAPPING_MAPPED,
    MAPPING_MATCHED,
    MAPPING_PENDING,
    MAPPING_UNMAPPED,
    MAPPING_DELETED,
    MappingNames,
};
