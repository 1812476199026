/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Table from '../../Table/index';
import ViewColumn from '../../Table/ViewColumn';

/**
 * Show web hook files
 *
 * @param {string} title
 * @param {object} table
 * @param {string} identifier
 *
 * @return {object}
 */
const TableFiles = ({ title, table, identifier }) => (
    <Table
        showHeader={false}
        title={title}
        id="SharedWebHookFiles"
        table={table}
        tag="Hooks"
        operationId="getHookFiles"
        apiParameters={{ hook: identifier }}
        actionsColumn={row => (
            <ViewColumn
                key={`action-webhook-${identifier}`}
                url={`/webhooks/${identifier}/files/${row.version}`}
            />
        )}
    />
);

export default TableFiles;
