/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import ConfigurationOverview, { Details as ConfigurationDetails, Create as ConfigurationCreate } from './Configuration';
import TypeOverview, { Details as TypeDetails, Create as TypeCreate } from './Type';
import EnvironmentOverview, { Create as EnvironmentCreate, Candidates as EnvironmentCandidates } from './Environment';
import TaskOverview, { Details as TaskDetails } from './Task';
import RemoteOverview, { Details as RemoteDetails, Create as RemoteCreate } from './Remote';
import FlagOverview, { Details as FlagDetails } from './Flag';
import StorageEntityOverview, { Details as StorageEntityDetails } from './StorageEntity';
import StorageOverview from './Storage';
import WebHook from './WebHook';

const Configuration = {
    Overview: ConfigurationOverview,
    Details: ConfigurationDetails,
    Create: ConfigurationCreate,
};

const Type = {
    Overview: TypeOverview,
    Details: TypeDetails,
    Create: TypeCreate,
};

const Environment = {
    Overview: EnvironmentOverview,
    Create: EnvironmentCreate,
    Candidates: EnvironmentCandidates,
};

const Flag = {
    Overview: FlagOverview,
    Details: FlagDetails,
};

const StorageEntity = {
    Overview: StorageEntityOverview,
    Details: StorageEntityDetails,
};

const Task = {
    Overview: TaskOverview,
    Details: TaskDetails,
};

const Remote = {
    Overview: RemoteOverview,
    Details: RemoteDetails,
    Create: RemoteCreate,
};

const Magement = {
    Configuration,
    Type,
    Task,
    WebHook,
};

const Storage = {
    Overview: StorageOverview,
};

export default Magement;
export {
    Configuration, Type, Task, Remote, WebHook, Storage, Environment, Flag, StorageEntity,
};
