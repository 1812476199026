/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import CancelButton from './CancelButton';
import { IonIcon } from '../Icons';

import './Button.css';

/**
 *
 * @param {function} onDelete
 * @param {boolean} isSubmitting
 * @param {string} size
 * @param {boolean} inline
 *
 * @return {object}
 */
const InnerDeleteButton = ({
    onDelete,
    isSubmitting,
    size,
    inline,
}) => (
    <Button
        color="danger"
        size={size}
        disabled={isSubmitting}
        onClick={() => onDelete()}
    >
        <IonIcon icon="trash" hasText={!inline} />
        {!inline && 'Delete'}
    </Button>
);

InnerDeleteButton.propTypes = {
    onDelete: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    inline: PropTypes.bool,
};

InnerDeleteButton.defaultProps = {
    inline: false,
};

class DeleteButton extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    /**
     * When the delete button is clicked.
     * Open confirm modal or perform
     * the delete call instantly.
     *
     * @return {void}
     */
    onClick() {
        if (this.props.confirm) {
            this.onToggle();
        } else {
            this.onDelete();
        }
    }

    /**
     * Toggle modal state.
     *
     * @return {void}
     */
    onToggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    /**
     * Perform delete action.
     *
     * @return {void}
     */
    onDelete() {
        this.props.onDelete();

        if (this.props.confirm) {
            this.onToggle();
        }
    }

    /**
     * Show delete button.
     *
     * @return {object}
     */
    render() {
        return (
            <>
                <InnerDeleteButton
                    isSubmitting={this.props.isSubmitting}
                    onDelete={() => this.onClick()}
                    inline={this.props.inline}
                    size={this.props.size}
                />
                <Modal
                    isOpen={this.state.isOpen}
                    toggle={() => this.onToggle()}
                >
                    <ModalHeader toggle={() => this.onToggle()}>Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this item?
                    </ModalBody>
                    <ModalFooter>
                        <InnerDeleteButton
                            isSubmitting={this.props.isSubmitting}
                            onDelete={() => this.onDelete()}
                            inline={false}
                            size=""
                        />
                        <CancelButton onCancel={() => this.onToggle()} />
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

DeleteButton.propTypes = {
    confirm: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    inline: PropTypes.bool,
    size: PropTypes.string,
};

DeleteButton.defaultProps = {
    confirm: true,
    isSubmitting: false,
    inline: false,
    size: '',
};


export default DeleteButton;
