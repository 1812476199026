/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import HttpClient from '../../../core/HttpClient';
import PageHeader from '../../Ui/PageHeader';
import DiForm from '.';
import Delete from '../Entity/Delete';
import BackButton from '../../Ui/Buttons/BackButton';
import Redirect from '../../../core/Redirect';

class Update extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            formData: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Load existing data.
     */
    componentDidMount() {
        const params = {
            interface: this.props.interface,
            identifier: this.props.identifier,
        };

        HttpClient.request('getObjectConfiguration', params)
            .then((response) => {
                this.setState({
                    formData: response,
                });
            });
    }

    /**
     * Handle change.
     *
     * @param formData
     */
    handleChange(formData) {
        this.setState({
            formData,
        });
    }

    /**
     * Handle submit.
     *
     * @param formData
     */
    handleSubmit(formData) {
        HttpClient.request('updateObjectConfiguration', null, formData)
            .then(() => {
                Redirect(this.props.history, this.props.baseUrl);
            });
    }

    /**
     * Render the component.
     *
     * @returns {object}
     */
    render() {
        return (
            <>
                <PageHeader title={this.state.formData.name || 'Loading...'}>
                    <Delete
                        isRequesting={false}
                        tag="Di"
                        operationId="deleteObjectConfiguration"
                        params={{ interface: this.props.interface, identifier: this.props.identifier }}
                        basePath={this.props.baseUrl}
                    />
                    <BackButton to={this.props.baseUrl} />
                </PageHeader>
                <Card>
                    <CardBody>
                        <DiForm
                            interface={this.props.interface}
                            formData={this.state.formData}
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                        />
                    </CardBody>
                </Card>
            </>
        );
    }
}

Update.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    interface: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
};

export default withRouter(Update);
