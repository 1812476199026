/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { has } from 'lodash';

const Normalizers = {
    // Booleans are rendered as checkbox. Unchecked boxes are
    // interpreted as empty resulting in undefined as value.
    // Providing default value ensures boolean value.
    booleanDefaultValues: (property) => {
        const normalized = property;
        if (normalized.type === 'boolean' && !has(normalized, 'default')) {
            normalized.default = false;
        }

        return normalized;
    },
    parameters: (property, key) => {
        const normalized = property;

        // Set parameter type as string by default.
        // When the entity has a schema resource
        // it will be converted back to object.
        if (key === 'parameters') {
            normalized.type = 'string';
        }

        return normalized;
    },
};

export default Normalizers;
