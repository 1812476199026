/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from 'reactstrap';
import IonIcon from '../../Ui/Icons/IonIcon';

const Help = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Button color="primary" onClick={toggle}>
                <IonIcon icon="help" hasText={false} />
            </Button>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader>Help</ModalHeader>
                <ModalBody>
                    { children }
                </ModalBody>
            </Modal>
        </>
    );
};

export default Help;
