/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import IonIcon from '../Icons/IonIcon';

/**
 * Logout of the application.
 *
 * @param {function} onClick
 * @param {string} environment
 * @param {string} current
 *
 * @returns {object}
 */
const SelectEnvironment = ({ environment, current }) => {
    const icon = environment === current
        ? 'radio-button-on'
        : 'radio-button-off';

    return (
        <>
            <IonIcon icon={icon} hasText />
            {environment}
        </>
    );
};

export default SelectEnvironment;
