/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import classnames from 'classnames';
import login from '../../assets/signin-with-google.png';

/**
 * Handle the form submit.
 *
 * @param {object} event
 * @param {function} onSubmit
 */
const handleSubmit = (event, onSubmit) => {
    event.preventDefault();
    onSubmit();
};

/**
 * Show the login form.
 *
 * @param {string} value
 * @param {function} onChange
 * @param {function} onSubmit
 *
 * @returns {object}
 */
const Form = ({ value, onChange, onSubmit }) => {
    const btnClasses = classnames({
        'btn-login': true,
        disabled: !value,
    });

    return (
        <form onSubmit={event => handleSubmit(event, onSubmit)}>
            <div>
                <div className="input-group mb-4">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            {`${window.location.protocol}//${window.location.host}/`}
                        </span>
                    </div>
                    <input
                        value={value}
                        onChange={onChange}
                        className="form-control"
                        type="text"
                        id="environment"
                        required=""
                        placeholder="Enter your environment"
                    />
                </div>
            </div>
            <div className="form-group row text-center m-t-10">
                <div className="col-12">
                    <button
                        type="button"
                        className={btnClasses}
                        onClick={onSubmit}
                        disabled={!value}
                    >
                        <img src={login} alt="Signin with Google" width={270} height={65} />
                    </button>
                </div>
            </div>
        </form>
    );
};

export default Form;
