/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import ReactJSON from 'react-json-view';
import { apiDataPropType } from '../../../validation';

/**
 * Show entity data.
 *
 * @param {object} entity
 *
 * @return {object}
 */
const EntityState = ({ entity }) => {
    return (
        <ReactJSON
            name={false}
            src={entity}
            enableClipboard={false}
            displayObjectSize={false}
            displayDataTypes={false}

        />
    );
};

EntityState.propTypes = {
    entity: apiDataPropType.isRequired,
};

export default EntityState;
