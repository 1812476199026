/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import UndoChanges from '../../Actions/UndoChanges';
import AcceptSuggestion from '../../Actions/AcceptSuggestion';
import RemoveMapping from '../../Actions/RemoveMapping';
import MappingStatus from '../../MappingStatus';
import ViewValueMapping from './ViewValueMapping';
import {
    undoMapping,
    addMapping,
    removeMapping,
    selectMappingKey,
} from '../../../../../reducers/mapping/actions';

const Actions = ({
    row,
    currentStatus,
    currentValue,
    isMapped,
    isDeleted,
    isChanged,
    source,
    destination,
    type,
}) => {
    return (
        <>
            {
                (isDeleted || isChanged)
                && <UndoChanges id={row.id} onClick={undoMapping} />
            }

            {
                currentStatus === MappingStatus.MAPPING_MATCHED
                && (
                    <AcceptSuggestion
                        source={row.id}
                        destination={currentValue}
                        onClick={addMapping}
                    />
                )
            }

            {
                (isMapped && !isDeleted)
                && (
                    <RemoveMapping
                        source={row.id}
                        destination={currentValue}
                        onClick={removeMapping}
                    />
                )
            }

            {

                (isMapped && !isDeleted)
                && (
                    <ViewValueMapping
                        mappingKey={row.localValue}
                        remoteKey={row.mapped.localValue}
                        source={source}
                        destination={destination}
                        type={type}
                        onClick={selectMappingKey}
                    />
                )
            }
        </>
    );
};

export default Actions;
