/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import Status from './Status';
import TextLoadingIndicator from '../../Ui/TextLoadingIndicator';
import HealthWidget from './HealthWidget';

const Placeholder = ({ numberOfItems }) => {
    const items = [];
    for (let i = 0; i < numberOfItems; i += 1) {
        items.push(
            <HealthWidget key={`report-placeholder-${i}`}>
                <Status
                    isHealthy
                    name={<TextLoadingIndicator width={64} mode="px" />}
                />
            </HealthWidget>,
        );
    }

    return items;
};

Placeholder.propTypes = {
    numberOfItems: PropTypes.number.isRequired,
};

export default Placeholder;
