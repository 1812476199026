/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { isEmpty, pickBy } from 'lodash';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

class DateFilter extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        const initial = this.sanitize(this.props.formData);
        this.state = { ...initial };

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle form changes.
     *
     * @param name
     * @param value
     */
    handleChange(name, value) {
        const date = value !== null
            ? value.toISOString()
            : null;
        this.setState({
            [name]: date,
        }, () => this.props.onChange(this.state));
    }

    /**
     * Sanitize filter output.
     *
     * @param {object} filter
     *
     * @return {?object}
     */
    sanitize(filter) {
        const result = pickBy(filter, (subject) => {
            return subject !== undefined && subject !== '';
        });

        return !isEmpty(result)
            ? result
            : undefined;
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        const gte = this.state.gte
            ? new Date(this.state.gte)
            : null;

        const lte = this.state.lte
            ? new Date(this.state.lte)
            : null;

        return (
            <div className="d-flex">
                <div className="flex-grow-1 mr-2">
                    <div className="input-group">
                        <DatePicker
                            selected={gte}
                            onChange={data => this.handleChange('gte', data)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd-MM-yyyy, HH:mm"
                            timeCaption="time"
                        />
                    </div>
                </div>
                <div className="flex-grow-1 ml-1">
                    <div className="input-group">
                        <DatePicker
                            selected={lte}
                            onChange={data => this.handleChange('lte', data)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd-MM-yyyy, HH:mm"
                            timeCaption="time"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

DateFilter.propTypes = {
    formData: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DateFilter;
