/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import uuid from 'uuid/v4';
import moment from 'moment';
import { ADD_FLASH_MESSAGE, READ_FLASH_MESSAGE } from './actions';

const defaultState = {
    messages: [],
    flashMessage: {
        message: '',
        messageType: 'error',
        isRead: true,
    },
};

const notificationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'ADD_NOTIFICATION':
            return {
                ...state,
                messages: [
                    {
                        id: uuid(),
                        message: action.message,
                        read: false,
                        createdAt: moment(),
                    },
                    ...state.messages,
                ],
            };
        case 'DELETE_NOTIFICATION':
            return {
                ...state,
                messages: state.messages.filter(
                    subject => subject.id !== action.id,
                ),
            };
        case 'CLEAR_ALL_NOTIFICATIONS':
            return {
                ...state,
                messages: [],
            };
        case ADD_FLASH_MESSAGE:
            return {
                ...state,
                flashMessage: {
                    isRead: false,
                    messageType: action.messageType,
                    message: action.message,
                },
            };
        case READ_FLASH_MESSAGE:
            return {
                ...state,
                flashMessage: {
                    ...state.flashMessage,
                    isRead: true,
                },
            };
        default:
            return state;
    }
};

export default notificationReducer;
