/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import EnvironmentStorage from '../../core/Environment/EnvironmentStorage';
import { getFreshSchema } from '../../core/JsonSchema';
import Form from './Form';
import logo from '../../assets/alumio-vertical.svg';
import './Login.scss';
import ErrorMessage from '../Ui/Messages/ErrorMessage';
import { setEnvironment } from '../../reducers/authentication/actions';
import { receiveSchemas } from '../../reducers/schema/actions';
import { isLoginEnabled } from '../../core/Auth';
import FlashMessage from '../Ui/Messages/FlashMessage';
import { addFlashMessage } from '../../reducers/notification/actions';
import { LOGIN_ENVIRONMENT } from '../../core/Environment/EnvironmentDetector';

class Login extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            environment: undefined,
            userInteraction: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    /**
     * Add flash message when user is unauthorized to login.
     */
    componentWillMount() {
        const matches = queryString.parse(this.props.location.search);
        if (matches.error && matches.error === 'unauthorized') {
            this.props.dispatch(
                addFlashMessage('You don\'t have access to this environment.', 'error'),
            );
        }
    }

    /**
     * Get the current environment.
     *
     * @returns {string}
     */
    getEnvironment() {
        // Determine the environment to show in the login form. Always use
        // the state of the component when a user has interacted with the
        // component. Otherwise use the previous known environment.
        return (this.state.userInteraction || EnvironmentStorage.getEnvironment() === LOGIN_ENVIRONMENT)
            ? this.state.environment
            : EnvironmentStorage.getEnvironment();
    }

    /**
     * Keep track of environment.
     *
     * @param {object} event
     */
    handleChange(event) {
        this.setState({
            environment: event.target.value,
            userInteraction: true,
        });
    }

    /**
     * Close the error message.
     */
    handleErrorClose() {
        this.setState({ hasError: false });
    }

    /**
     * Redirect users to the authentication page.
     */
    handleClick() {
        const environment = this.getEnvironment();

        this.props.dispatch(setEnvironment(environment));

        localStorage.removeItem('LastModified-schema');
        localStorage.removeItem('LastModified-uiSchema');

        const baseUrl = EnvironmentStorage.getUrl(environment);

        const promises = [
            getFreshSchema(baseUrl, 'schema'),
            getFreshSchema(baseUrl, 'ui-schema'),
        ];

        Promise.all(promises)
            .then((schemas) => {
                this.props.dispatch(receiveSchemas(...schemas));
            })
            .then(() => {
                window.location = isLoginEnabled()
                    ? `${baseUrl}/authentication/connect?redirect_url=${process.env.REACT_APP_DASHBOARD_HOST}`
                    : `/${environment}`;
            })
            .catch(() => {
                this.setState({
                    hasError: true,
                });
            });
    }

    /**
     * Render login form.
     *
     * @returns {object}
     */
    render() {
        const environment = this.getEnvironment();

        return (
            <div className="wrapper-page">
                <div className="account-pages">
                    <div className="account-box">
                        <div className="account-logo-box pt-4">
                            <img
                                src={logo}
                                width="200"
                                alt="Alumio logo"
                            />
                        </div>
                        <div className="account-content">
                            <ErrorMessage
                                onClose={this.handleErrorClose}
                                isOpen={this.state.hasError}
                                message="The entered environment is invalid."
                            />

                            <FlashMessage />

                            <Form
                                value={environment}
                                onSubmit={this.handleClick}
                                onChange={this.handleChange}
                            />

                            <div className="row mt-4">
                                <div className="col-sm-12 text-center">
                                    <p className="text-muted">
                                        { 'Don\'t have an account?' }
                                        <a href="https://www.alumio.com/contact" className="text-dark ml-1">
                                            <b>Contact us</b>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    environment: state.authentication.environment,
});

export default withRouter(connect(mapStateToProps)(Login));
