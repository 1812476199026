/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import './HealthReport.scss';

/**
 * Show health widget.
 *
 * @param {array} children
 *
 * @returns {object}
 */
const HealthWidget = ({ children }) => {
    return (
        <div className="portlet mb-0">
            <div className="portlet-body">
                <div className="health-widget">
                    { children }
                </div>
            </div>
        </div>
    );
};

export default HealthWidget;
