/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { DeleteButton } from '../../Ui/Buttons';
import HttpClient from '../../../core/HttpClient';
import Redirect from '../../../core/Redirect';

class Delete extends React.Component {
    /**
     * Delete entity.
     *
     * @return {Promise}
     */
    onDelete() {
        return HttpClient.request(
            this.props.operationId,
            this.props.params,
        ).then(() => {
            Redirect(this.props.history, this.props.baseUrl);

            if (typeof this.props.onSuccess === 'function') {
                this.props.onSuccess();
            }
        }).catch((error) => {
            const msg = error.message
                ? error.message
                : 'Unexpected error while deleting.';
            this.props.onError(msg);
        });
    }

    /**
     * Render delete button.
     *
     * @return {string}
     */
    render() {
        return (
            <DeleteButton
                isSubmitting={this.props.isRequesting}
                onDelete={() => this.onDelete()}
                inline={this.props.inline}
            />
        );
    }
}

export default withRouter(Delete);
