/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import Update from '../Magement/Di/Update';
import Create from '../Magement/Di/Create';
import { Configuration } from '../Magement';
import Route from './PrivateRoute';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Di = () => (
    <Switch>
        <Route
            exact
            path="/:environment/di"
            render={() => (
                <Configuration.Overview
                    id="DiObjectConfiguration"
                    tag="Di"
                    operationId="getObjectConfigurations"
                    createOperationId="createObjectConfiguration"
                />
            )}
        />
        <Route
            exact
            path="/:environment/di/create"
            render={() => <Create interface="League\Flysystem\FilesystemInterface" baseUrl="/di" />}
        />
        <Route
            exact
            path="/:environment/di/:identifier"
            render={props => (
                <Update
                    baseUrl="/di"
                    interface="League\Flysystem\FilesystemInterface"
                    identifier={props.match.params.identifier}
                />
            )}
        />
    </Switch>
);

export default Di;
