/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

class Range {
    /**
     * Get a between filter schema.
     *
     * @param {string} name
     * @param {object} field
     *
     * @returns {string}
     */
    static parse(name, field) {
        return {
            type: 'object',
            title: field.title,
            properties: {
                gte: {
                    type: 'string',
                    title: 'From',
                },
                lte: {
                    type: 'string',
                    title: 'To',
                },
            },
        };
    }

    /**
     * Get the ui schema.

     * @returns {{}}
     */
    static parseUi() {
        return { 'ui:field': 'range' };
    }
}

export default Range;
