/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */
import EnvironmentStorage from '../Environment/EnvironmentStorage';

/**
 * Get URL with prefixed environment.
 *
 * @param {string} path
 * @param {string} environment
 *
 * @returns {string}
 */
const getUrl = (path, environment) => {
    if (path.match('/:environment')) {
        return path.replace(':environment', environment);
    }

    return `/${environment}${path}`;
};

/**
 * Redirect to new location.
 *
 * @param {object} history
 * @param {string} to
 */
const Redirect = (history, to = '/') => {
    history.push(getUrl(to, EnvironmentStorage.getEnvironment()));
};

export default Redirect;
