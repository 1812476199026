/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type } from '../Magement';
import CreateTransformer from '../Magement/Transformer/Configuration/Create';
import Details from '../Magement/Transformer/Configuration/Details';
import Route from './PrivateRoute';

const configurationsBasePath = '/:environment/transformers/configurations';
const typesBasePath = '/:environment/transformers/types';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Transformers = () => (
    <Switch>
        <Route
            exact
            path={typesBasePath}
            render={() => (
                <Type.Overview
                    id="TransformersTypes"
                    tag="Transformers"
                    operationId="getTransformerTypes"
                />
            )}
        />
        <Route
            exact
            path={configurationsBasePath}
            render={() => (
                <Configuration.Overview
                    id="TransformersConfigurations"
                    tag="Transformers"
                    operationId="getTransformerConfigurations"
                    createOperationId="createTransformerConfiguration"
                />
            )}
        />
        <Route
            exact
            path={`${typesBasePath}/:identifier`}
            render={
                props => (
                    <Type.Details
                        configuration={{
                            id: 'TransformersTypes',
                            tag: 'Transformers',
                            operationId: 'getTransformerTypes',
                        }}
                        tag="Transformers"
                        operationIds={{
                            read: 'getTransformerType',
                        }}
                        entity="TransformerType"
                        params={{ identifier: props.match.params.identifier }}
                    />
                )
            }
        />
        <Route
            exact
            path={`${configurationsBasePath}/create`}
            render={
                () => (
                    <CreateTransformer />
                )
            }
        />
        <Route
            exact
            path={`${configurationsBasePath}/:identifier`}
            render={
                props => (
                    <Details
                        tag="Transformers"
                        operationIds={{
                            read: 'getTransformerConfiguration',
                            update: 'updateTransformerConfiguration',
                            delete: 'deleteTransformerConfiguration',
                        }}
                        entity="TransformerConfiguration"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
    </Switch>
);

export default Transformers;
