/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Configuration } from '../..';
import { uiSchema } from '../Form/Fields';
import TransformerDataMutator from './TransformerDataMutator';
import TestData from './TestData';

const configurationsBasePath = '/transformers/configurations';

const tabs = [{
    id: 'TransformerTest',
    name: 'Test',
    content: () => (<TestData />),
}];

const CreateTransformer = () => {
    return (
        <div>
            <Configuration.Create
                tag="Transformers"
                operationIds={{
                    create: 'createTransformerConfiguration',
                }}
                entity="TransformerConfiguration"
                basePath={configurationsBasePath}
                additionalUiSchema={uiSchema}
                mutators={[new TransformerDataMutator('identifier', 'identifier')]}
                tabs={tabs}
            />
        </div>
    );
};

export default CreateTransformer;
