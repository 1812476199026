/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import Range from './Range';

class DateRange extends Range {
    /**
     * Get the ui schema.
     *
     * @returns {object}
     */
    static parseUi() {
        return { 'ui:field': 'date' };
    }
}

export default DateRange;
