/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Form from 'react-jsonschema-form';
import HttpClient from '../../../core/HttpClient';
import FieldFactory from './Fields/FieldFactory';
import ArrayTemplate from '../../Ui/Form/ArrayTemplate';
import { DiFields, UISchema } from './Fields';

class Di extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            prototype: { schema: {} },
            prototypes: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Get available prototypes.
     */
    componentDidMount() {
        HttpClient.request('getInterfaceList', { interface: this.props.interface })
            .then((response) => {
                this.setState({
                    prototypes: response.prototypes,
                }, () => this.setInitialSchema());
            });
    }

    /**
     * Set the initial prototype schema based on existing form data.
     */
    setInitialSchema() {
        const { type } = this.props.formData;

        this.setActivePrototype(type);
    }

    /**
     * Get prototype object by its identifier.
     *
     * @param {string} identifier
     *
     * @returns {object|boolean}
     */
    getPrototypeByIdentifier(identifier) {
        return this.state.prototypes.find((subject) => {
            return subject.identifier === identifier;
        });
    }

    /**
     * Set the active prototype.
     *
     * @param {string} identifier
     */
    setActivePrototype(identifier) {
        const prototype = this.getPrototypeByIdentifier(identifier);

        // Fall back to empty object when prototype is not
        // found. This happens when the form is empty.
        this.setState({
            prototype: prototype || { schema: {} },
        });
    }

    /**
     * Handle form changes.
     *
     * @param {object} form
     */
    handleChange(form) {
        const { type } = form.formData;

        // Watch for changes in the main prototype. Then lookup the prototype object
        // and set it in the state so it can be used to render to start of the form.
        if (type !== undefined && type !== this.state.type) {
            const prototype = this.getPrototypeByIdentifier(type);

            this.setState({
                prototype: prototype || { schema: {} },
            });
        }

        // Update the parent component with the new form data.
        this.props.onChange(form.formData);
    }

    /**
     * Submit the form.
     *
     * @param {object} form
     */
    handleSubmit(form) {
        this.props.onSubmit(form.formData);
    }

    /**
     * Render the DI form.
     *
     * @returns {object}
     */
    render() {
        const context = {
            prototype: this.state.prototype,
            prototypes: this.state.prototypes,
        };

        const { formData, schema } = this.props;

        return (
            <Form
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                formData={formData}
                schema={schema}
                fields={DiFields}
                uiSchema={UISchema}
                formContext={context}
                FieldTemplate={FieldFactory}
                ArrayFieldTemplate={ArrayTemplate}
            />
        );
    }
}

const mapStateToProps = state => ({
    schema: state.schema.schema.definitions.DiObjectConfiguration,
});

Di.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps)(Di));
