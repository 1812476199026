/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import IonIcon from '../Icons/IonIcon';

/**
 * Logout of the application.
 *
 * @returns {object}
 */
const LogoutButton = () => {
    return (
        <>
            <IonIcon icon="power" hasText />
            Logout
        </>
    );
};

export default LogoutButton;
