/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import '../Table/Pagination.css';

/**
 * Show button.
 *
 * @param element
 * @param index
 *
 * @return {object}
 */
const button = (element, index) => (
    <div key={`button-${index}`} className="btn-group">{element}</div>
);

/**
 * Show list of buttons.
 *
 * @param {array} children
 *
 * @return {object}
 */
const buttons = (children) => {
    const elements = Array.isArray(children)
        ? children.map((child, index) => button(child, index))
        : button(children);

    return (
        <div className="btn-toolbar mb-md-0">
            {elements}
        </div>
    );
};

/**
 * Show a title with optional buttons.
 *
 * @param {string} title
 * @param {array} children
 * @param {boolean} sticky
 *
 * @deprecated See Title component.
 *
 * @return {object}
 */
const PageHeader = ({ title, children, sticky }) => {
    const elements = (typeof children !== 'undefined')
        ? buttons(children)
        : undefined;

    const isSticky = (sticky) ? 'sticky-header' : 'header';

    if (!title && !children) {
        return (
            <div className="pb-4" />
        );
    }

    return (
        <div className={`d-flex ${isSticky}`}>
            { title
                && (
                    <div className="flex-grow-1 flex-shrink-1">
                        <div className="page-title-box mb-2 mt-2">
                            <h4 className="page-title mt-2">{title}</h4>
                        </div>
                    </div>
                )
            }
            { children
                && (
                    <div className="flex-grow-1 flex-shrink-0">
                        <div className="page-title-box buttons-box">{elements}</div>
                    </div>
                )
            }
        </div>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    sticky: PropTypes.bool,
};

PageHeader.defaultProps = {
    title: '',
    children: undefined,
    sticky: true,
};

export default PageHeader;
