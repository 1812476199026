/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Table from '../../Table';

const TableSystemVariables = ({ table }) => (
    <div>
        <p>
            System variables are dynamic variables managed by Alumio.

            System variables cannot be managed by users, but if need
            be they can be overridden by a user defined variable.

            When a user defines an environment variable with the
            same name as a system variable, the user variable is
            leading.
            After this variable is deleted, the system variable
            becomes active again.
        </p>
        <Table
            id="SystemVariables"
            tag="Environment"
            title="System variables"
            operationId="getSystemVariables"
            table={table}
            showHeader={false}
        />
    </div>
);

export default TableSystemVariables;
