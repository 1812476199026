/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import {
    RECEIVE_ROUTES,
    SELECT_ROUTE,
    SELECT_RANGE,
    RECEIVE_NUMBER_OF_TASKS,
    RECEIVE_FAILED_TASKS,
} from './actions';
import { RANGE_24_HOURS } from '../../components/Ui/DateRangeSelect/CalculateRange';

const defaultState = {
    routes: [],
    route: {},
    tasks: {
        period: 24,
        statistics: {
            new: 0,
            processing: 0,
            incoming: 0,
            outgoing: 0,
        },
        failed: [],
    },
    range: {
        option: RANGE_24_HOURS,
        from: undefined,
        to: undefined,
    },
};

const reportingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SELECT_ROUTE:
            return {
                ...state,
                route: action.route,
            };
        case SELECT_RANGE:
            return {
                ...state,
                range: action.range,
                tasks: {
                    ...state.tasks,
                    failed: [],
                },
            };
        case RECEIVE_ROUTES:
            return {
                ...state,
                routes: action.routes,
            };
        case RECEIVE_NUMBER_OF_TASKS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    statistics: {
                        ...state.tasks.statistics,
                        [action.identifier]: action.numberOfTasks,
                    },
                },
            };
        case RECEIVE_FAILED_TASKS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    failed: action.tasks,
                },
            };
        default:
            return state;
    }
};

export default reportingReducer;
