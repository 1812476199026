/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const LOGIN_ENVIRONMENT = 'login';

class EnvironmentDetector {
    /**
     * Get environment from URL.
     *
     * @returns {string|undefined}
     */
    static getEnvironmentFromUrl() {
        const pieces = window.location.pathname.split('/');
        const current = pieces[1];

        if (!current) {
            return undefined;
        }

        return current === LOGIN_ENVIRONMENT
            ? undefined
            : current;
    }
}

export default EnvironmentDetector;
export {
    LOGIN_ENVIRONMENT,
};
