/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render like filter.
 *
 * @param {object} formData
 * @param {function} onChange
 *
 * @returns {object}
 */
const LikeFilter = ({ formData, onChange }) => {
    return (
        <input
            className="form-control"
            value={formData.like}
            onChange={event => onChange({ like: event.target.value })}
        />
    );
};

LikeFilter.propTypes = {
    formData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default LikeFilter;
