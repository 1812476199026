/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import HttpClient from '../../../../../core/HttpClient';
import Select from './Select';
import Fields, { uiSchema, TYPE_OBJECT, TYPE_ARRAY } from './index';

class GenericTransformer extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            data: props.formData,
            options: [{
                identifier: 'loading',
                name: 'Loading',
            }],
            schema: {},
            identifier: null,
        };

        this.isActive = false;

        this.handleOptionsChange = this.handleOptionsChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    /**
     * Fetch available transformers.
     */
    componentDidMount() {
        this.isActive = true;

        HttpClient.request(this.props.operationId)
            .then((response) => {
                this.setState({
                    options: response.results,
                });
            })
            .catch(() => {
                // Silently fail.
            });
    }

    /**
     * Mark component as inactive when deleted.
     */
    componentWillUnmount() {
        this.isActive = false;
    }

    /**
     * This component can be deleted. Only mutate
     * state when the component is active.
     *
     * @param {object|function} state
     * @param {?function} callback
     */
    setState(state, callback) {
        if (this.isActive) {
            super.setState(state, callback);
        }
    }

    /**
     * Set chosen schema.
     *
     * @param {object} schema
     * @param {string} identifier
     */
    handleOptionsChange(schema, identifier) {
        this.setState(() => ({
            schema,
            identifier,
        }));
    }

    /**
     * Keep track of local form data and notify parent.
     *
     * @param {object} formData
     */
    handleFormChange(formData) {
        this.setState({
            data: formData,
        }, () => this.notifyParent(this.state.data));
    }

    /**
     * Send data to parent.
     *
     * @param {object} data
     */
    notifyParent(data) {
        const structure = this.props.type === TYPE_ARRAY
            ? {
                [this.props.types.object]: {
                    ...data,
                },
                type: this.state.identifier,
            }
            : {
                type: this.state.identifier,
                ...data,
            };

        this.props.onChange(structure, this.props.index);
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        const { options, schema } = this.state;
        const { name } = this.props;

        schema.type = TYPE_OBJECT;

        return (
            <>
                {
                    this.props.type !== TYPE_ARRAY
                    && (<label htmlFor={name}>{name}</label>)
                }
                <Select
                    name={name}
                    options={options}
                    onChange={this.handleOptionsChange}
                />
                {
                    schema.properties !== undefined
                    && (
                        <div className="pl-4">
                            <SchemaField
                                schema={schema}
                                uiSchema={uiSchema}
                                fields={Fields}
                                registry={this.props.registry}
                                onChange={this.handleFormChange}
                                formData={this.state.data}
                            />
                        </div>
                    )
                }
            </>
        );
    }
}

GenericTransformer.propTypes = {
    index: PropTypes.number,
    operationId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    registry: PropTypes.oneOfType([PropTypes.object]).isRequired,
    type: PropTypes.string,
};

GenericTransformer.defaultProps = {
    index: 0,
    type: 'object',
};

export default GenericTransformer;
