/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { getMagementVersion } from '../../core/LocalStorage';
import IonIcon from '../Ui/Icons/IonIcon';

/**
 * Scroll to top button.
 *
 * @return {object}
 */
const ScrollToTop = () => (
    <div
        className="btn btn-secondary back-to-top"
        role="presentation"
        title="Back to top"
        onClick={() => window.scrollTo(0, 0)}
    >
        <IonIcon icon="arrow-dropup" />
        <span> Back to top</span>
    </div>
);


/**
 * Show footer of page.
 *
 * @return {object}
 */
const Footer = () => (
    <footer className="footer">
        { (new Date().getFullYear()) }
        {' '}
        &copy;
        {' '}
        <a
            href="https://www.alumio.com"
            target="_blank"
            rel="noopener noreferrer"
        >
            Alumio
        </a>
        {' '}
        {`v${getMagementVersion()}`}
        <ScrollToTop />
    </footer>
);

export default Footer;
