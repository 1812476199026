/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Details from './Details';
import Create from './Create';
import AbstractOverview from '../Entity/AbstractOverview';
import FeatureDetect from '../../../core/FeatureDetect';
import { schemaPropType } from '../../../validation';

/**
 * Show Configuration overview.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Overview = (props) => {
    const { identifierResolver } = props;

    const { url } = props.match;
    const path = url.replace(`/${props.match.params.environment}`, '');

    const options = [];
    const FeatureDetection = new FeatureDetect(props.schema);

    if (FeatureDetection.hasOperationId(props.createOperationId)) {
        options.push('create');
    }

    return (
        <AbstractOverview
            {...props}
            options={options}
            viewAction={(row) => {
                // The resolver will return the identifier of the
                // provided row. Otherwise fallback to default
                // when no identifier could be found.
                const identifier = identifierResolver(row) || row.identifier;
                return `${path}/${identifier}`;
            }}
        />
    );
};

Overview.propTypes = {
    ...AbstractOverview.propTypes,
    identifierResolver: PropTypes.func,
    schema: schemaPropType.isRequired,
    operationId: PropTypes.string.isRequired,
    createOperationId: PropTypes.string,
};

Overview.defaultProps = {
    identifierResolver: () => false,
    createOperationId: '',
};

const mapStateToProps = state => ({
    schema: state.schema.schema,
});

export default withRouter(connect(mapStateToProps)(Overview));
export { Details, Create };
