/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';

const handleChange = (event, callback) => {
    callback(event.target.value);
};

/**
 * Select a prototype.
 *
 * @param {function} onChange
 * @param {object} formContext
 * @param {object} formData
 *
 * @returns {object}
 */
const PrototypeType = ({ onChange, formContext, formData }) => {
    return (
        <select
            className="form-control mb-3"
            onChange={event => handleChange(event, onChange)}
            value={formData}
        >
            <option>Select</option>
            {
                formContext.prototypes.map((prototype) => {
                    return (
                        <option
                            key={prototype.identifier}
                            value={prototype.identifier}
                        >
                            {prototype.name}
                        </option>
                    );
                })
            }
        </select>
    );
};

export default PrototypeType;
