/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import {
    Button, Modal, ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import FilterParser from '../../core/FilterParser';
import Bootstrap4Template from '../Ui/Form/Bootstrap4Template';
import DateFilter from '../Ui/Form/DateFilter';
import RangeFilter from '../Ui/Form/RangeFilter';
import { CancelButton } from '../Ui/Buttons';
import ObjectFilter from '../Ui/Form/ObjectFilter';
import LikeFilter from '../Ui/Form/LikeFilter';

const mapDispatchToProps = dispatch => ({
    onApplyFilter: (id, form) => {
        dispatch({
            type: 'APPLY_FILTER',
            formData: form.formData,
            id,
        });
        dispatch({
            type: 'TOGGLE_FILTER',
            id,
        });
    },
    onClearFilter: (id) => {
        dispatch({
            type: 'CLEAR_FILTER',
            id,
        });
    },
});

class AdvancedFilter extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);

        this.uiSchema = undefined;
        this.schema = undefined;
    }

    /**
     * Get the UI schema of the entity.
     *
     * @returns {object}
     */
    getEntityUiSchema() {
        return this.props.uiSchema[this.props.table.entity]
            ? this.props.uiSchema[this.props.table.entity]
            : {};
    }

    /**
     * Get the UI schema of the filter form.
     *
     * @returns {object}
     */
    getUiSchema() {
        return FilterParser.getUiSchema(
            this.props.table.entitySchema,
            this.getEntityUiSchema(),
        );
    }

    /**
     * Get the schema of the filter form.
     *
     * @returns {object}
     */
    getSchema() {
        return FilterParser.getFilterSchema(
            this.props.table.entitySchema,
            this.props.disabledFilters,
            this.getEntityUiSchema(),
        );
    }

    /**
     * Toggle filter modal.
     */
    handleToggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    /**
     * Apply filter.
     *
     * @param {object} form
     */
    handleSubmit(form) {
        this.props.onApplyFilter(this.props.table.id, form);
        this.handleToggle();
    }

    /**
     * Clear filters.
     */
    handleClear() {
        this.handleToggle();
        this.props.onClearFilter(this.props.table.id);
    }

    /**
     * Render filter component.
     *
     * @return {object}
     */
    render() {
        const fields = {
            range: RangeFilter,
            date: DateFilter,
            objectFilter: ObjectFilter,
            like: LikeFilter,
        };

        return (
            <div>
                <Button onClick={this.handleToggle} color="secondary" outline>
                    Filters
                </Button>
                <Modal isOpen={this.state.isOpen} size="lg">
                    <ModalHeader toggle={this.handleToggle}>
                        Filter
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            FieldTemplate={Bootstrap4Template}
                            schema={this.getSchema()}
                            uiSchema={this.getUiSchema()}
                            fields={fields}
                            onSubmit={this.handleSubmit}
                            formData={this.props.table.where}
                        >
                            <button
                                type="submit"
                                className="hidden"
                                ref={(btn) => { this.submitRef = btn; }}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            color="primary"
                            className="mr-1"
                            onClick={() => { this.submitRef.click(); }}
                        >
                            Apply
                        </Button>
                        <Button
                            type="submit"
                            color="secondary"
                            onClick={this.handleClear}
                        >
                            Clear
                        </Button>
                        <CancelButton onCancel={this.handleToggle} />
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

AdvancedFilter.propTypes = {
    disabledFilters: PropTypes.arrayOf(PropTypes.string),
};

AdvancedFilter.defaultProps = {
    disabledFilters: [],
};

export default connect(null, mapDispatchToProps)(AdvancedFilter);
