/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { localeReducer } from 'react-localize-redux';
import { combineReducers } from 'redux';
import navigationReducer from './navigation';
import tableReducer from './table';
import schemaReducer from './schema';
import apiReducer from './api';
import transformerReducer from './transformer';
import notificationReducer from './notification';
import filterReducer from './filter';
import mappingReducer from './mapping';
import authenticationReducer from './authentication';
import reportingReducer from './reporting';

const reducer = combineReducers({
    navigation: navigationReducer,
    table: tableReducer,
    schema: schemaReducer,
    locale: localeReducer,
    api: apiReducer,
    transformer: transformerReducer,
    notification: notificationReducer,
    filter: filterReducer,
    mapping: mappingReducer,
    authentication: authenticationReducer,
    reporting: reportingReducer,
});

export default reducer;
