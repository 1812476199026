/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import LogMessage from './LogMessage';
import LogMessages from './LogMessages';

/**
 * Show export messages.
 *
 * @param {array} messages
 *
 * @return {object}
 */
const ExportMessages = ({ messages }) => {
    const components = messages.map((message) => {
        const exportLog = message.export_log || message.exportLog;

        return (
            <LogMessage
                key={`export-message-${message.message_id || message.messageId}`}
                messageId={message.message_id || message.messageId}
                message={message.message}
                timestamp={exportLog.finished_at || exportLog.finishedAt}
                level={message.level}
            />
        );
    });

    return <LogMessages messages={components} />;
};

ExportMessages.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExportMessages;
