/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type } from '../Magement';
import FeatureDetect from '../../core/FeatureDetect';
import DiUpdate from '../Magement/Di/Update';
import DiCreate from '../Magement/Di/Create';
import Route from './PrivateRoute';

const typeBasePath = '/:environment/http-clients/types';
const configBasePath = '/:environment/http-clients/configurations';

/**
 * Route definitions.
 *
 * @return {object}
 */
const HttpClients = ({ schema }) => {
    const featureDetect = new FeatureDetect(schema);
    const hasDiObjectConfiguration = featureDetect.hasModelDefinition('DiObjectConfiguration');

    return (
        <Switch>
            <Route
                exact
                path={typeBasePath}
                render={() => (
                    <Type.Overview
                        id="HttpClientTypes"
                        tag="HttpClients"
                        operationId="getHttpClientTypes"
                    />
                )}
            />
            <Route
                exact
                path={`${typeBasePath}/:identifier`}
                render={
                    props => (
                        <Type.Details
                            configuration={{
                                id: 'HttpClientTypes',
                                tag: 'HttpClient',
                                operationId: 'getHttpClientConfigurations',
                            }}
                            tag="HttpClients"
                            operationIds={{
                                read: 'getHttpClientType',
                            }}
                            entity="ConfigurationType"
                            params={{ item: props.match.params.identifier }}
                        />
                    )
                }
            />
            <Route
                exact
                path={configBasePath}
                render={() => {
                    return hasDiObjectConfiguration
                        ? (
                            <Configuration.Overview
                                id="DiObjectConfiguration"
                                tag="Di"
                                operationId="getObjectConfigurations"
                                createOperationId="createObjectConfiguration"
                                title="HTTP Clients"
                                filter={{ interface: 'Psr\\Http\\Client\\ClientInterface' }}
                            />
                        )
                        : (
                            <Configuration.Overview
                                id="HttpClientConfigurations"
                                tag="HttpClients"
                                operationId="getHttpClientConfigurations"
                            />
                        );
                }}
            />

            <Route
                exact
                path={`${configBasePath}/create`}
                render={() => {
                    return hasDiObjectConfiguration
                        ? (
                            <DiCreate
                                baseUrl={configBasePath}
                                interface="Psr\Http\Client\ClientInterface"
                                title="Create a filesystem"
                            />
                        )
                        : (
                            <Configuration.Create
                                tag="HttpClientConfigurations"
                                operationIds={{
                                    read: 'getHttpClientConfiguration',
                                    create: 'createHttpClientConfiguration',
                                }}
                                entity="HttpClientConfiguration"
                                basePath={configBasePath}
                            />
                        );
                }}
            />
            <Route
                exact
                path={`${configBasePath}/:identifier`}

                render={
                    (props) => {
                        return hasDiObjectConfiguration
                            ? (
                                <DiUpdate
                                    interface="Psr\Http\Client\ClientInterface"
                                    baseUrl={configBasePath}
                                    identifier={props.match.params.identifier}
                                />
                            )
                            : (
                                <Configuration.Details
                                    tag="HttpClients"
                                    operationIds={{
                                        read: 'getHttpClientConfiguration',
                                    }}
                                    entity="HttpClientConfiguration"
                                    params={{ identifier: props.match.params.identifier }}
                                    basePath={configBasePath}
                                />
                            );
                    }
                }
            />
        </Switch>
    );
};

export default HttpClients;
