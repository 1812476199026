/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import WebHook, { Entity as WebHookEntity } from '../Magement/WebHook';
import SharedWebHooks from '../Magement/WebHook/SharedWebHooks';
import WebHookDetails from '../Magement/WebHook/WebHookDetails';
import WebHookContent from '../Magement/WebHook/WebHookContent';
import FeatureDetect from '../../core/FeatureDetect';
import Route from './PrivateRoute';

const basePath = '/:environment/webhooks';

/**
 * Route definitions.
 *
 * @return {object}
 */
const WebHooks = ({ schema }) => {
    const FeatureDetection = new FeatureDetect(schema);

    const hasSharedWebhooks = FeatureDetection.hasModelDefinition('Webhook');

    return (
        <Switch>
            <Route
                exact
                path={basePath}
                render={() => {
                    return hasSharedWebhooks
                        ? <SharedWebHooks />
                        : <WebHook />;
                }}
            />
            <Route
                exact
                path={`${basePath}/:identifier`}
                render={(props) => {
                    return hasSharedWebhooks
                        ? (
                            <WebHookDetails
                                identifier={props.match.params.identifier}
                            />
                        )
                        : (
                            <WebHookEntity
                                identifier={props.match.params.identifier}
                            />
                        );
                }}
            />
            <Route
                exact
                path={`${basePath}/:identifier/files/:version`}
                render={
                    props => (
                        <WebHookContent
                            identifier={props.match.params.identifier}
                            version={props.match.params.version}
                        />
                    )
                }
            />
        </Switch>
    );
};

export default WebHooks;
