/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import render from '../../core/Ui';
import { arrayRenderer, objectRenderer } from '../../core/Ui/Renderers';
import './Table.css';
import { schemaPropType } from '../../validation';

/**
 * Show a single table cell.
 *
 * @param {*} item
 * @param {object} definition
 * @param {string} name
 * @param {object} context
 * @param {array} renderers
 *
 * @return {object}
 */
const TableCell = ({
    item, definition, name, context, renderers,
}) => {
    const cssClass = arrayRenderer.isCandidate(item, definition) || objectRenderer.isCandidate(item)
        ? 'has-children'
        : null;

    // Show the default value from the schema definition
    // when the item doesn't have a value of its own.
    const subject = item === undefined
        ? definition.default
        : item;

    const value = render(subject, definition, name, context, renderers);

    return (
        <td className={cssClass}>{value}</td>
    );
};

TableCell.propTypes = {
    item: PropTypes.oneOfType([PropTypes.any]),
    definition: schemaPropType.isRequired,
    name: PropTypes.string.isRequired,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    renderers: PropTypes.arrayOf(PropTypes.object),
};

TableCell.defaultProps = {
    renderers: [],
    item: undefined,
};

export default TableCell;
