/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render Ion icon.
 *
 * @param {string} icon
 * @param {boolean} hasText
 * @param {string} margin
 *
 * @return {object}
 */
const IonIcon = ({ icon, hasText, margin }) => {
    const cssClass = hasText ? margin : '';

    return (<i className={`ion ion-md-${icon} ${cssClass}`} />);
};

IonIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    hasText: PropTypes.bool,
    margin: PropTypes.string,
};

IonIcon.defaultProps = {
    hasText: true,
    margin: 'm-r-5',
};

export default IonIcon;
