/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

class AbstractMutator {
    /**
     * Constructor.
     *
     * @param {string} subject
     * @param {string} target
     *
     * @return {void}
     */
    constructor(subject, target) {
        this.subject = subject;
        this.target = target;
    }

    /**
     * Whether the target is a candidate.
     *
     * @param {object} candidates
     * @param {object} original
     *
     * @return {boolean}
     */
    isCandidate(candidates, original) {
        if (typeof candidates === 'undefined') {
            return false;
        }

        const candidate = candidates[this.subject];

        return (
            candidate !== undefined
            && original[this.subject] !== candidate
        );
    }

    /**
     * Get target.
     *
     * @return {string}
     */
    getTarget() {
        return this.target;
    }

    /**
     * Get subject.
     *
     * @return {string}
     */
    getSubject() {
        return this.subject;
    }
}

export default AbstractMutator;
