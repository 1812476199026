/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */
import {
    USER_LOGIN,
    USER_LOGOUT,
    SET_ENVIRONMENT,
    ADD_AVAILABLE_ENVIRONMENT,
} from './actions';

const defaultState = {
    loggedIn: false,
    environment: '',
    username: '',
    availableEnvironments: [],
};

const authenticationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                loggedIn: true,
                username: action.username,
            };
        case USER_LOGOUT:
            return {
                ...state,
                loggedIn: false,
            };
        case SET_ENVIRONMENT:
            return {
                ...state,
                environment: action.environment,
            };
        case ADD_AVAILABLE_ENVIRONMENT:
            return {
                ...state,
                availableEnvironments: state.availableEnvironments.indexOf(action.environment) === -1
                    ? [...state.availableEnvironments, action.environment]
                    : state.availableEnvironments,
            };
        default:
            return state;
    }
};

export default authenticationReducer;
