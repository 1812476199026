/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import GenericTransformer from './GenericTransformer';
import { TYPE_ARRAY } from './index';
import SortableList from '../../../../Ui/SortableList';

class GenericTransformerList extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            transformers: [
                <GenericTransformer
                    key={`0-${this.props.operationId}`}
                    identifier={uuid()}
                    tag={this.props.tag}
                    operationId={this.props.operationId}
                    onChange={data => this.handleChange(data, 0)}
                    name={this.props.name}
                    registry={this.props.registry}
                    type={TYPE_ARRAY}
                    types={this.props.types}
                />,
            ],
            index: 1,
            data: [],
        };

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    }

    /**
     * Handle incoming sorted data.
     *
     * @param {object} data
     */
    handleSort(data) {
        this.setState({
            transformers: data.items,
        });
    }

    /**
     * Handle changes of children and notify parent.
     *
     * @param value
     * @param {int} index
     */
    handleChange(value, index) {
        this.setState((prevState) => {
            const data = [...prevState.data];
            data[index] = value;

            return { data };
        }, () => this.props.onChange(this.state.data));
    }

    /**
     * Add a new transformers.
     */
    handleAddButtonClick() {
        const component = this.createComponent();

        this.setState(prevState => ({
            transformers: [...prevState.transformers, component],
        }));
    }

    /**
     * Create new transformer component.
     *
     * @return {object}
     */
    createComponent() {
        const component = React.createElement(GenericTransformer, {
            identifier: uuid(),
            key: `${this.state.index}-${this.props.operationId}`,
            tag: this.props.tag,
            operationId: this.props.operationId,
            onChange: (data, index) => this.handleChange(data, index),
            name: '',
            registry: this.props.registry,
            type: TYPE_ARRAY,
            types: this.props.types,
        });

        this.setState(prevState => ({
            index: prevState.index + 1,
        }));

        return component;
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        return (
            <div>
                <label htmlFor={this.props.name}>{this.props.name}</label>
                <SortableList
                    onAddItem={() => this.handleAddButtonClick()}
                    onChange={data => this.handleSort(data)}
                >
                    {this.state.transformers}
                </SortableList>
            </div>
        );
    }
}

PropTypes.propTypes = {
    operationId: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    registry: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default GenericTransformerList;
