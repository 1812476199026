/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import JSONPath from 'jsonpath';

const availableOperationIds = {};
const availableFilters = {};

class FeatureDetect {
    constructor(schema) {
        this.schema = schema;
    }

    /**
     * Whether the API schema has the model definition.
     *
     * @param model
     * @param schema
     * @returns {boolean}
     */
    hasModelDefinition(model) {
        return this.schema.components.schemas[model] !== undefined;
    }

    /**
     * Feature detection based on operationId in the API schema
     *
     * @param operationId
     *
     * @return {boolean}
     */
    hasOperationId(operationId) {
        if (!(operationId in availableOperationIds)) {
            // JSONPath query check because of unknown parent keys variation
            availableOperationIds[operationId] = JSONPath.query(
                this.schema,
                `$.paths.*..[?(@.operationId=="${operationId}")]`,
            ).length !== 0;
        }

        return availableOperationIds[operationId];
    }

    /**
     * Whether the operationId has a filter defined in the API schema.
     *
     * @returns {boolean}
     */
    hasFilter(operationId) {
        if (!(operationId in availableFilters)) {
            // Look up the provided operationId in the paths section of an OpenAPI 3.0
            // schema. Then check if the operationId has a parameter called filter.
            // If not, assume this operation has no way to filter data.
            availableFilters[operationId] = JSONPath.query(
                this.schema,
                `$.paths.*[?(@.operationId=="${operationId}")].parameters[?(@.name=="filter")]`,
            ).length > 0;
        }

        return availableFilters[operationId];
    }
}

export default FeatureDetect;
