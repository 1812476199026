/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Table.css';

const mapDispatchToProps = dispatch => ({
    onSort: (id, column) => {
        dispatch({
            type: 'SORT_COLUMN',
            column,
            id,
        });
    },
});

/**
 * Show the header cell of a table.
 *
 * @param {string} tableId
 * @param {object} labels
 * @param {*} value
 * @param {function} onSort
 * @param {boolean} active
 * @param {string} direction
 * @param {boolean} sortable
 *
 * @return {object}
 */
const TableHeaderCell = ({
    tableId, labels, value, onSort, active, direction, sortable,
}) => {
    const cssClass = [];
    if (active) {
        cssClass.push('active');
        cssClass.push(direction);
    }

    if (sortable) {
        cssClass.push('clickable');
        cssClass.push('sortable');
    }

    return (
        <th
            className={cssClass.join(' ')}
            onClick={() => {
                if (sortable) {
                    onSort(tableId, value);
                }
            }}
        >
            <span>{labels[value].replace(/_/g, ' ')}</span>
        </th>
    );
};

TableHeaderCell.propTypes = {
    tableId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onSort: PropTypes.func.isRequired,
    active: PropTypes.bool,
    direction: PropTypes.string,
    sortable: PropTypes.bool,
};

TableHeaderCell.defaultProps = {
    active: false,
    direction: 'ASC',
    sortable: true,
};

export default connect(
    null,
    mapDispatchToProps,
)(TableHeaderCell);
