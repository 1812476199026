/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import PrototypeType from './PrototypeType';
import ParameterField from './ParameterField';

const DiFields = {
    PrototypeType,
    ParameterField,
};

const UISchema = {
    interface: {
        'ui:disabled': true,
    },
    type: {
        'ui:field': 'PrototypeType',
    },
    parameters: {
        'ui:field': 'ParameterField',
    },
};

export {
    DiFields,
    UISchema,
};
