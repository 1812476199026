/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

/**
 * Move array item within array without mutating original array.
 *
 * @param {array} subject
 * @param {number} position
 * @param {number} direction
 *
 * @return {array}
 */
const moveArrayItem = (subject, position, direction) => {
    const item = subject[position];

    const result = subject.filter((value, index) => (index !== position));
    result.splice((position + direction), 0, item);

    return result;
};

/**
 * Move array item up one place without mutating original array.
 *
 * @param {array} subject
 * @param {number} index
 *
 * @return {array}
 */
const moveDown = (subject, index) => {
    return moveArrayItem(subject, index, 1);
};

/**
 * Move array item down one place without mutating original array.
 *
 * @param {array} subject
 * @param {number} index
 *
 * @return {array}
 */
const moveUp = (subject, index) => {
    return moveArrayItem(subject, index, -1);
};

/**
 * Remove item from array without mutating original array.
 *
 * @param {array} data
 * @param {number} index
 * @return {*[]}
 */
const remove = (data, index) => {
    return data.filter((subject, i) => {
        return i !== index;
    });
};


const ArrayOperations = { moveUp, moveDown, remove };

export default ArrayOperations;
export { moveUp, moveDown, remove };
