/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { ViewButton } from '../Ui/Buttons';
import Link from '../Routes/PrefixedLink';

/**
 * Render a View button.
 *
 * @param {string} url
 *
 * @return {object}
 */
const ViewColumn = ({ url }) => (
    <td>
        <Link
            to={url}
        >
            <ViewButton />
        </Link>
    </td>
);

export default ViewColumn;
