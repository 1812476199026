/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import has from 'lodash/has';
import HttpClient from '../../core/HttpClient';
import { options } from '../../components/Ui/DateRangeSelect/CalculateRange';
import ApiFilters from '../../components/Widget/NumberOfTasksWidget/ApiFilters';

const SELECT_ROUTE = 'SELECT_ROUTE';
const SELECT_RANGE = 'SELECT_RANGE';
const RECEIVE_ROUTES = 'RECEIVE_ROUTES';
const RECEIVE_NUMBER_OF_TASKS = 'RECEIVE_NUMBER_OF_TASKS';
const RECEIVE_FAILED_TASKS = 'RECEIVE_FAILED_TASKS';

/**
 * Available task statuses and whether they are usable with date ranges.
 *
 * @type {object}
 */
const TaskReportStatus = {
    new: false,
    processing: false,
    stuck: false,
    finished: true,
    failed: true,
    total: true,
};

/**
 * Receive new routes.
 *
 * @param {object} routes
 *
 * @returns {object}
 */
const receiveRoutes = routes => ({
    type: RECEIVE_ROUTES,
    routes,
});

/**
 * Fetch all available routes.
 *
 * @returns {function}
 */
const fetchRoutes = () => (dispatch) => {
    HttpClient.request('getRouteConfigurations')
        .then((response) => {
            dispatch(receiveRoutes(response.results));
        });
};

/**
 * Receive number of tasks per status.
 *
 * @param {string} identifier
 * @param {number}  numberOfTasks
 *
 * @returns {object}
 */
const receiveNumberOfTasks = (identifier, numberOfTasks) => ({
    type: RECEIVE_NUMBER_OF_TASKS,
    identifier,
    numberOfTasks,
});

/**
 * Fetch number of tasks.
 *
 * @param {string} identifier
 * @param {object=} range
 *
 * @returns {function}
 */
const fetchNumberOfTasks = (identifier, range) => (dispatch, getState) => {
    const filter = {
        limit: 1,
        where: ApiFilters[identifier],
    };

    if (TaskReportStatus[identifier]) {
        const dateRange = !range
            ? getState().reporting.range
            : range;

        if (dateRange) {
            filter.where.created_at = {
                gte: options[dateRange.option].value(dateRange).from,
                lte: options[dateRange.option].value(dateRange).to,
            };
        }
    }

    const { route } = getState().reporting;
    if (route && route.identifier) {
        filter.where.route = {
            inq: [route.identifier],
        };
    } else if (has(filter, 'where.route')) {
        delete filter.where.route;
    }

    HttpClient.request('getTasks', { filter: JSON.stringify(filter) })
        .then((response) => {
            dispatch(receiveNumberOfTasks(identifier, response.metadata.totalCount));
        })
        .catch(() => {
            // The HTTP client dispatches a notification when API calls fail.
        });
};

/**
 * Receive failed tasks.
 *
 * @param {array} tasks
 *
 * @returns {object}
 */
const receiveFailedTasks = tasks => ({
    type: RECEIVE_FAILED_TASKS,
    tasks,
});

/**
 * Fetch failed tasks.
 *
 * @param {object=} range
 *
 * @returns {Function}
 */
const fetchFailedTasks = range => (dispatch, getState) => {
    const dateRange = !range
        ? getState().reporting.range
        : range;

    const filter = {
        where: {
            status: 'failed',
            created_at: {
                gte: options[dateRange.option].value(dateRange).from,
                lte: options[dateRange.option].value(dateRange).to,
            },
        },
    };

    const { route } = getState().reporting;
    if (route && route.identifier) {
        filter.where.route = {
            inq: [route.identifier],
        };
    }

    HttpClient.request('getTasks', { filter: JSON.stringify(filter) })
        .then((response) => {
            dispatch(receiveFailedTasks(response.results));
        })
        .catch(() => {
            // The HTTP client dispatches a notification when API calls fail.
        });
};

/**
 * Select a route.
 *
 * @param {object} route
 *
 * @returns {function}
 */
const selectRoute = route => (dispatch) => {
    dispatch({
        type: SELECT_ROUTE,
        route,
    });

    Object.keys(ApiFilters).forEach(identifier => dispatch(fetchNumberOfTasks(identifier)));

    dispatch(fetchFailedTasks());
};

const selectRange = range => (dispatch) => {
    dispatch({
        type: SELECT_RANGE,
        range,
    });

    Object.keys(ApiFilters).forEach(identifier => dispatch(fetchNumberOfTasks(identifier)));

    dispatch(fetchFailedTasks(range));
};

export {
    selectRoute,
    selectRange,
    fetchRoutes,
    fetchNumberOfTasks,
    fetchFailedTasks,
    SELECT_ROUTE,
    SELECT_RANGE,
    RECEIVE_ROUTES,
    RECEIVE_NUMBER_OF_TASKS,
    RECEIVE_FAILED_TASKS,
};
