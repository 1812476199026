/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const navigationReducer = (state = {
    currentPage: '/',
    menuItems: {},
    enlarged: true,
}, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.page,
            };
        case 'TOGGLE_MENU_ITEM':
            return {
                ...state,
                menuItems: {
                    ...state.menuItems,
                    [action.itemId]: !state.menuItems[action.itemId],
                },
            };
        case 'ENLARGE_CONTENT':
            return {
                ...state,
                enlarged: true,
            };
        case 'EXPAND_MENU':
            return {
                ...state,
                enlarged: false,
            };
        case 'TOGGLE_MENU':
            return {
                ...state,
                enlarged: !state.enlarged,
            };
        default:
            return state;
    }
};

export default navigationReducer;
