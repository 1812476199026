/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

class LogFilters {
    /**
     * Get API filter.
     *
     * @param {object} where
     */
    static getFilter(where) {
        return {
            filter: JSON.stringify({
                where,
            }),
        };
    }

    /**
     * Get import messages filter.
     *
     * @param {number} id
     *
     * @return {object}
     */
    static getImportMessagesFilter(id) {
        return LogFilters.getFilter({
            importLog: {
                inq: [id],
            },
        });
    }

    /**
     * Get export messages filter.
     *
     * @param {array} ids
     *
     * @return {object}
     */
    static getExportMessagesFilter(ids) {
        return LogFilters.getFilter({
            exportTaskLog: {
                inq: ids,
            },
        });
    }

    /**
     * Get export task logs filter.
     *
     * @param {number} taskId
     *
     * @return {object}
     */
    static getExportTaskLogsFilter(taskId) {
        return LogFilters.getFilter({
            taskLog: {
                inq: [taskId],
            },
        });
    }

    /**
     * Get task logs filter.
     *
     * @param {number} taskId
     *
     * @return {object}
     */
    static getTaskLogsFilter(taskId) {
        return LogFilters.getFilter({
            task: {
                inq: [taskId],
            },
        });
    }
}

export default LogFilters;
