/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';

const colors = {
    mapped: '#ffffff',
    matched: '#edc75542',
    pending: '#0088002b',
    unmatched: '#ffffff',
    deleted: '#d03f3f2b',
};

const mapStateToProps = state => ({
    keyMapping: state.mapping.keyMapping,
});

const formattedValue = (options, value) => {
    const result = options.find((subject) => {
        return subject.value === value;
    });

    return result !== undefined ? result : null;
};

/**
 * Select mapping key.
 *
 * @param {number} from
 * @param {array} options
 * @param {integer} currentValue
 * @param {function} onSelect
 * @param {string} status
 * @param {function} onChange
 *
 * @returns {object}
 */
const KeySelect = ({
    from,
    options,
    currentValue,
    status,
    dispatch,
    onChange,
}) => {
    const handleChange = (option) => {
        dispatch(onChange(from, option.value));
    };

    const formattedOptions = options.map(option => ({
        label: `${option.remoteValue} (${option.localValue})`,
        value: option.id,
    }));

    const value = formattedValue(formattedOptions, currentValue);

    const styles = {
        control: base => ({
            ...base,
            background: colors[status],
        }),
    };

    return (
        <Select
            styles={styles}
            value={value}
            options={formattedOptions}
            onChange={handleChange}
        />
    );
};

KeySelect.propTypes = {
    from: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    currentValue: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
};

KeySelect.defaultProps = {
    options: [],
    currentValue: null,
};

export default connect(
    mapStateToProps,
    null,
)(KeySelect);
