/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import EnvironmentStorage from '../../core/Environment/EnvironmentStorage';

const USER_LOGIN = 'USER_LOGIN';
const USER_LOGOUT = 'USER_LOGOUT';
const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
const ADD_AVAILABLE_ENVIRONMENT = 'ADD_AVAILABLE_ENVIRONMENT';

const userLogin = user => (dispatch, getState) => {
    dispatch({
        type: USER_LOGIN,
        username: user.username,
    });

    if (getState().authentication.environment) {
        dispatch({
            type: ADD_AVAILABLE_ENVIRONMENT,
            environment: getState().authentication.environment,
        });
    }
};

const userLogout = () => ({
    type: USER_LOGOUT,
});

const setEnvironment = (environment) => {
    EnvironmentStorage.setEnvironment(environment);

    return {
        type: SET_ENVIRONMENT,
        environment,
    };
};

export {
    USER_LOGIN,
    USER_LOGOUT,
    SET_ENVIRONMENT,
    ADD_AVAILABLE_ENVIRONMENT,
    userLogin,
    userLogout,
    setEnvironment,
};
