/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';
import './Button.css';

/**
 * Show button to refresh data.
 *
 * @param {function} onClick
 *
 * @return {object}
 */
const UndoButton = ({ onClick }) => (
    <Button outline color="secondary" onClick={() => onClick()}>
        <IonIcon icon="undo" hasText={false} />
    </Button>
);

export default UndoButton;
