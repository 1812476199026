/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import Ui from './components/Ui';
import Routes from './components/Routes';

/**
 * Main wrapper component of the application.
 *
 * @returns {object}
 */
const App = () => (
    <Ui>
        <Routes />
    </Ui>
);

export default withRouter(App);
