/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { AlertTriangle } from 'react-feather';
import Config from '../../../core/Config';

/**
 * Error message when data could not be fetched.
 *
 * @return {object}
 */
const Error = () => (
    <tr>
        <td colSpan={100} className="no-results">
            <AlertTriangle
                className="feather text-danger"
                size={Config.ui.iconSize}
            />
            {' '}
            Error while fetching data.
        </td>
    </tr>
);

export default Error;
