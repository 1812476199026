/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from '../../Table/index';
import Entity from './Entity';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

/**
 * Show web hooks.
 *
 * @param {object} props
 *
 * @return {object}
 */
const WebHook = (props) => {
    const table = props.tables.find(t => t.id === 'WebHook');

    return (
        <Table
            title="Web Hooks"
            id="WebHook"
            tag="Hooks"
            operationId="getHooks"
            table={table}
            viewAction={(row) => { return `/webooks/${row.identifier}`; }}
        />
    );
};

export default withRouter(connect(
    mapStateToProps,
    null,
)(WebHook));

export { Entity };
