/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import HttpClient from '../../core/HttpClient';
import {
    getByType,
    getByPage,
    formatter,
} from '../../components/Magement/Mapping/MappingFactory';

const ADD_MAPPING = 'ADD_MAPPING';
const ADD_MAPPING_VALUE = 'ADD_MAPPING_VALUE';
const REMOVE_MAPPING = 'REMOVE_MAPPING';
const REMOVE_MAPPING_VALUE = 'REMOVE_MAPPING_VALUE';
const UNDO_MAPPING = 'UNDO_CHANGE';
const UNDO_MAPPING_VALUE = 'UNDO_MAPPING_VALUE';
const CLEAR_MAPPING = 'CLEAR_MAPPING';
const CLEAR_MAPPING_VALUES = 'CLEAR_MAPPING_VALUES';
const REQUEST_MAPPING = 'REQUEST_MAPPING';
const REQUEST_SUGGESTIONS = 'REQUEST_SUGGESTIONS';
const SELECT_MAPPING_TYPE = 'SELECT_MAPPING_TYPE';
const SELECT_MAPPING_SOURCE = 'SELECT_MAPPING_SOURCE';
const SELECT_MAPPING_DESTINATION = 'SELECT_MAPPING_DESTINATION';
const RECEIVE_MAPPING = 'RECEIVE_MAPPING';
const RECEIVE_MAPPING_VALUES = 'RECEIVE_MAPPING_VALUES';
const RECEIVE_SUGGESTIONS = 'RECEIVE_SUGGESTIONS';
const RECEIVE_MAPPING_VALUE_SUGGESTIONS = 'RECEIVE_MAPPING_VALUE_SUGGESTIONS';
const SAVE_MAPPING = 'SAVE_MAPPING';
const DELETE_MAPPING = 'DELETE_MAPPING';
const APPLY_CHANGES = 'APPLY_CHANGES';
const APPLY_ALL_CHANGES = 'APPLY_ALL_CHANGES';
const APPLY_TYPE_CHANGES = 'APPLY_TYPE_CHANGES';
const APPLY_PAGE_CHANGES = 'APPLY_PAGE_CHANGES';
const SELECT_MAPPING_KEY = 'SELECT_MAPPING_KEY';
const SWAP_KEY_MAPPING = 'SWAP_KEY_MAPPING';

/**
 * Request new mapping values.
 *
 * @returns {object}
 */
const requestMapping = () => ({
    type: REQUEST_MAPPING,
});

/**
 * Request new mapping suggestions.
 *
 * @returns {object}
 */
const requestSuggestions = () => ({
    type: REQUEST_SUGGESTIONS,
});

/**
 * Receive new mapping values.
 *
 * @param {array} mapping
 *
 * @returns {object}
 */
const receiveMapping = mapping => ({
    type: RECEIVE_MAPPING,
    mapping,
});

const receiveMappingValues = mapping => ({
    type: RECEIVE_MAPPING_VALUES,
    mapping,
});

const receiveMappingValueSuggestions = suggestions => ({
    type: RECEIVE_MAPPING_VALUE_SUGGESTIONS,
    suggestions,
});

/**
 * Receive new mapping suggestions.
 *
 * @param {array} suggestions
 *
 * @returns {object}
 */
const receiveSuggestions = suggestions => ({
    type: RECEIVE_SUGGESTIONS,
    suggestions,
});

/**
 * Undo a changed mapping.
 *
 * @param {integer} id
 *
 * @returns {object}
 */
const undoMapping = id => ({
    type: UNDO_MAPPING,
    id,
});

/**
 * Undo a changed mapping value.
 *
 * @param {integer} id
 *
 * @returns {object}
 */
const undoMappingValue = id => ({
    type: UNDO_MAPPING_VALUE,
    id,
});

/**
 * Fetch mappings from the API.
 *
 * @returns {function}
 */
const fetchMapping = () => (dispatch, getState) => {
    dispatch(requestMapping());

    const state = getState();

    const parameters = {
        type: state.mapping.type.value,
        source: state.mapping.source.value,
        destination: state.mapping.destination.value,
    };

    return HttpClient.request('getMappingKeyList', parameters)
        .then(response => dispatch(receiveMapping(response.results)));
};

const fetchMappingValues = () => (dispatch, getState) => {
    const state = getState();

    const parameters = {
        key: state.mapping.currentMappingKey.mappingKey,
        type: state.mapping.currentMappingKey.type.value,
        source: state.mapping.currentMappingKey.source.value,
        destination: state.mapping.currentMappingKey.destination.value,
    };

    return HttpClient.request('getMappingValueList', parameters)
        .then(response => dispatch(receiveMappingValues(response.results)));
};

const fetchMappingValueSuggestions = () => (dispatch, getState) => {
    const state = getState();

    const parameters = {
        key: state.mapping.currentMappingKey.remoteKey,
        type: state.mapping.currentMappingKey.type.value,
        source: state.mapping.currentMappingKey.destination.value,
        destination: state.mapping.currentMappingKey.source.value,
    };

    return HttpClient.request('getMappingValueList', parameters)
        .then(response => dispatch(receiveMappingValueSuggestions(response.results)));
};

/**
 * Fetch suggestions from the API.
 *
 * @returns {function}
 */
const fetchSuggestions = () => (dispatch, getState) => {
    dispatch(requestSuggestions());

    const state = getState();

    const parameters = {
        type: state.mapping.type.value,
        source: state.mapping.destination.value,
        destination: state.mapping.source.value,
    };

    return HttpClient.request('getMappingKeyList', parameters)
        .then(response => dispatch(receiveSuggestions(response.results)));
};

/**
 * Add a key mapping.
 *
 * @param {integer} from
 * @param {integer} to
 *
 * @returns {function}
 */
const addMapping = (from, to) => (dispatch, getState) => {
    const state = getState();
    dispatch({
        type: ADD_MAPPING,
        from,
        to,
        mappingType: state.mapping.type.value,
        source: state.mapping.source.value,
        destination: state.mapping.destination.value,
    });
};

/**
 * Add a value mapping.
 *
 * @param {integer} from
 * @param {integer} to
 *
 * @returns {function}
 */
const addMappingValue = (from, to) => (dispatch, getState) => {
    const state = getState();
    dispatch({
        type: ADD_MAPPING_VALUE,
        from,
        to,
        mappingType: state.mapping.type.value,
        source: state.mapping.source.value,
        destination: state.mapping.destination.value,
    });
};

/**
 * Remove a key mapping.
 *
 * @param {integer} from
 * @param {integer} to
 *
 * @returns {function}
 */
const removeMapping = (from, to) => (dispatch, getState) => {
    const state = getState();
    dispatch({
        type: REMOVE_MAPPING,
        from,
        to,
        mappingType: state.mapping.type.value,
        source: state.mapping.source.value,
        destination: state.mapping.destination.value,
    });
};

/**
 * Remove a value mapping.
 *
 * @param {integer} from
 * @param {integer} to
 *
 * @returns {function}
 */
const removeMappingValue = (from, to) => (dispatch, getState) => {
    const state = getState();

    dispatch({
        type: REMOVE_MAPPING_VALUE,
        from,
        to,
        mappingType: state.mapping.type.value,
        source: state.mapping.source.value,
        destination: state.mapping.destination.value,
    });
};


/**
 * Clear all pending changes.
 *
 * @returns {object}
 */
const clearMapping = () => ({
    type: CLEAR_MAPPING,
});

/**
 * Clear all pending changes.
 *
 * @returns {object}
 */
const clearMappingValues = () => ({
    type: CLEAR_MAPPING_VALUES,
});

/**
 * Whether mapping values should be fetched.
 *
 * @param {object} state
 *
 * @returns {boolean}
 */
const shouldFetchMapping = (state) => {
    return (
        state.mapping.type !== null
        && state.mapping.source !== null
        && state.mapping.destination !== null
    );
};

/**
 * Fetch mapping values if needed.
 *
 * @returns {function}
 */
const fetchMappingIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchMapping(getState())) {
        return dispatch(fetchMapping());
    }

    return null;
};

/**
 * Fetch suggestions if needed.
 *
 * @returns {function}
 */
const fetchSuggestionsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchMapping(getState())) {
        return dispatch(fetchSuggestions());
    }

    return null;
};

/**
 * Get API data when possible.
 *
 * @returns {function}
 */
const getMappingData = () => (dispatch) => {
    dispatch(fetchMappingIfNeeded());
    dispatch(fetchSuggestionsIfNeeded());
};

const getMappingValues = () => (dispatch) => {
    dispatch(fetchMappingValues());
    dispatch(fetchMappingValueSuggestions());
};

/**
 * Save new mapping keys.
 *
 * @param {array} data
 *
 * @returns {function}
 */
const saveMapping = data => (dispatch) => {
    dispatch({
        type: SAVE_MAPPING,
    });

    return HttpClient.request('createKeyMappingAssociation', null, { mappings: data });
};

/**
 * Save new mapping keys.
 *
 * @param {array} data
 *
 * @returns {function}
 */
const saveMappingValues = data => (dispatch) => {
    dispatch({
        type: SAVE_MAPPING,
    });

    return HttpClient.request('createValueMappingAssociation', null, { mappings: data });
};


/**
 * Delete given mappings.
 *
 * @param {array} data
 *
 * @returns {function}
 */
const deleteMapping = data => (dispatch) => {
    dispatch({
        type: DELETE_MAPPING,
    });

    return HttpClient.request('deleteKeyMappingAssociation', null, { mappings: data });
};

/**
 * Delete given mappings.
 *
 * @param {array} data
 *
 * @returns {function}
 */
const deleteMappingValues = data => (dispatch) => {
    dispatch({
        type: DELETE_MAPPING,
    });

    return HttpClient.request('deleteValueMappingAssociation', null, { mappings: data });
};

/**
 * Apply the given changes.
 *
 * @param {array} mapping
 * @param {array} deletions
 *
 * @returns {function}
 */
const applyChanges = (mapping, deletions) => (dispatch) => {
    dispatch(saveMapping(mapping))
        .then(() => dispatch(deleteMapping(deletions)))
        .then(() => dispatch(fetchMappingIfNeeded()))
        .then(() => dispatch(fetchSuggestionsIfNeeded()))
        .then(() => dispatch(clearMapping()));
};

const applyValueChanges = (mapping, deletions) => (dispatch) => {
    dispatch(saveMappingValues(mapping))
        .then(() => dispatch(deleteMappingValues(deletions)))
        .then(() => dispatch(fetchMappingValues()))
        .then(() => dispatch(fetchMappingValueSuggestions()))
        .then(() => dispatch(clearMappingValues()));
};

/**
 * Apply all changes.
 *
 * @returns {function}
 */
const applyAllChanges = () => (dispatch, getState) => {
    dispatch({ type: APPLY_ALL_CHANGES });

    const state = getState();

    dispatch(
        applyChanges(
            formatter(state.mapping.keyMapping),
            formatter(state.mapping.deletions),
        ),
    );
};

/**
 * Apply all changes of the current type.
 *
 * @returns {function}
 */
const applyTypeChanges = () => (dispatch, getState) => {
    dispatch({ type: APPLY_TYPE_CHANGES });

    const state = getState();

    dispatch(
        applyChanges(
            formatter(
                getByType(state.mapping.type.value, state.mapping.keyMapping),
            ),
            formatter(
                getByType(state.mapping.type.value, state.mapping.deletions),
            ),
        ),
    );
};

const applyMappingValueChanges = () => (dispatch, getState) => {
    const state = getState();

    dispatch(
        applyValueChanges(
            formatter(state.mapping.valueMapping),
            formatter(state.mapping.valueDeletions),
        ),
    );
};

/**
 * Apply all changes of the current page.
 *
 * @returns {function}
 */
const applyPageChanges = () => (dispatch, getState) => {
    dispatch({ type: APPLY_PAGE_CHANGES });

    const state = getState();

    dispatch(
        applyChanges(
            formatter(
                getByPage(
                    state.mapping.type.value,
                    state.mapping.source.value,
                    state.mapping.destination.value,
                    state.mapping.keyMapping,
                ),
            ),
            formatter(
                getByPage(
                    state.mapping.type.value,
                    state.mapping.source.value,
                    state.mapping.destination.value,
                    state.mapping.deletions,
                ),
            ),
        ),
    );
};

/**
 * Select the mapping type.
 *
 * @param {string} mappingType
 *
 * @returns {function}
 */
const selectMappingType = mappingType => (dispatch) => {
    dispatch({
        type: SELECT_MAPPING_TYPE,
        mappingType,
    });

    dispatch(getMappingData());
};

/**
 * Select the mapping source.
 *
 * @param {string} mappingSource
 *
 * @returns {object}
 */
const selectMappingSource = mappingSource => (dispatch) => {
    dispatch({
        type: SELECT_MAPPING_SOURCE,
        mappingSource,
    });

    dispatch(getMappingData());
};

/**
 * Select the mapping source.
 *
 * @param {string} mappingDestination
 *
 * @returns {object}
 */
const selectMappingDestination = mappingDestination => (dispatch) => {
    dispatch({
        type: SELECT_MAPPING_DESTINATION,
        mappingDestination,
    });

    dispatch(getMappingData());
};

const selectMappingKey = (mappingKey, remoteKey, mappingType, source, destination) => (dispatch) => {
    dispatch({
        type: SELECT_MAPPING_KEY,
        mappingKey,
        remoteKey,
        mappingType,
        source,
        destination,
    });
};

/**
 * Swap key mapping source and destination.
 *
 * @returns {function}
 */
const swapKeyMapping = () => (dispatch) => {
    dispatch({
        type: SWAP_KEY_MAPPING,
    });

    // Reload data.
    dispatch(fetchMappingIfNeeded());
    dispatch(fetchSuggestionsIfNeeded());
};

export {
    ADD_MAPPING,
    ADD_MAPPING_VALUE,
    REMOVE_MAPPING,
    REMOVE_MAPPING_VALUE,
    UNDO_MAPPING,
    UNDO_MAPPING_VALUE,
    CLEAR_MAPPING,
    CLEAR_MAPPING_VALUES,
    REQUEST_MAPPING,
    SELECT_MAPPING_TYPE,
    SELECT_MAPPING_SOURCE,
    SELECT_MAPPING_DESTINATION,
    RECEIVE_MAPPING,
    RECEIVE_SUGGESTIONS,
    RECEIVE_MAPPING_VALUES,
    RECEIVE_MAPPING_VALUE_SUGGESTIONS,
    SAVE_MAPPING,
    APPLY_CHANGES,
    SELECT_MAPPING_KEY,
    SWAP_KEY_MAPPING,
    selectMappingType,
    selectMappingSource,
    selectMappingDestination,
    requestMapping,
    requestSuggestions,
    receiveMapping,
    receiveSuggestions,
    fetchMapping,
    addMapping,
    addMappingValue,
    removeMapping,
    removeMappingValue,
    undoMapping,
    undoMappingValue,
    clearMapping,
    shouldFetchMapping,
    fetchMappingIfNeeded,
    fetchSuggestionsIfNeeded,
    saveMapping,
    applyChanges,
    applyAllChanges,
    applyPageChanges,
    applyTypeChanges,
    applyMappingValueChanges,
    getMappingData,
    getMappingValues,
    selectMappingKey,
    receiveMappingValues,
    swapKeyMapping,
};
