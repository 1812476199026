/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HttpClient from '../../../core/HttpClient';
import Interval from '../../Ui/Interval';
import HealthStatus from './HealthStatus';
import Placeholder from './Placeholder';
import HealthWidget from './HealthWidget';

const mapDispatchToProps = dispatch => ({
    onError: (message) => {
        dispatch({
            type: 'ADD_NOTIFICATION',
            message,
        });
    },
});

class HealthReportWidget extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.getHealthReport = this.getHealthReport.bind(this);

        this.state = {
            results: [],
            hasError: false,
        };

        this.isActive = false;
    }

    /**
     * Fetch initial report.
     */
    componentDidMount() {
        this.isActive = true;

        this.getHealthReport();
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    setState(state, callback) {
        if (this.isActive) {
            super.setState(state, callback);
        }
    }

    /**
     * Get health report.
     */
    getHealthReport() {
        HttpClient.request('getHealthStatus')
            .then((response) => {
                this.setState({
                    results: response.results,
                });
            })
            .catch(() => {
                // Only dispatch error notification once.
                if (!this.state.hasError) {
                    this.props.onError('Error while loading health status');
                }

                this.setState({
                    hasError: true,
                });
            });
    }

    /**
     * Render widget.
     *
     * @returns {object}
     */
    render() {
        return (
            <Interval time={this.props.interval} callback={this.getHealthReport}>
                <div className="health-widgets">
                    {
                        this.state.results.length === 0
                            ? <Placeholder numberOfItems={5} />
                            : (
                                this.state.results.map((report) => {
                                    return (
                                        <HealthWidget key={`report-${report.identifier}`}>
                                            <HealthStatus
                                                {...report}
                                            />
                                        </HealthWidget>
                                    );
                                })
                            )
                    }
                </div>
            </Interval>
        );
    }
}

HealthReportWidget.propTypes = {
    interval: PropTypes.number,
};

HealthReportWidget.defaultProps = {
    interval: 120,
};

export default connect(
    null,
    mapDispatchToProps,
)(HealthReportWidget);
