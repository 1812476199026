/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import HttpClient from '../../../core/HttpClient';
import IonIcon from '../../Ui/Icons/IonIcon';
import { schemaPropType } from '../../../validation';
import FeatureDetect from '../../../core/FeatureDetect';

class ObjectTester extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isHealthy: false,
            isTested: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Get icon.
     *
     * @returns {string}
     */
    getIcon() {
        if (!this.state.isTested) {
            return 'play';
        }

        return this.state.isHealthy
            ? 'checkmark'
            : 'warning';
    }

    /**
     * Get label
     *
     * @returns {string}
     */
    getLabel() {
        if (!this.state.isTested) {
            return this.props.label;
        }

        return this.state.isHealthy
            ? this.props.labelSuccess
            : this.props.labelError;
    }

    /**
     * Perform test and set result.
     */
    handleClick() {
        HttpClient.request(this.props.operationId, this.props.params)
            .then(() => this.setState({ isTested: true, isHealthy: true }))
            .catch(() => this.setState({ isTested: true, isHealthy: false }));
    }

    /**
     * Render component.
     *
     * @returns {object}
     */
    render() {
        const FeatureDetection = new FeatureDetect(this.props.schema);

        return FeatureDetection.hasOperationId(
            this.props.operationId,
        ) && (
            <Button onClick={this.handleClick}>
                <IonIcon icon={this.getIcon()} />
                { this.getLabel() }
            </Button>
        );
    }
}

ObjectTester.propTypes = {
    label: PropTypes.string.isRequired,
    labelSuccess: PropTypes.string.isRequired,
    labelError: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    params: PropTypes.object,
    schema: schemaPropType.isRequired,
};

ObjectTester.defaultProps = {
    params: {},
};

const mapStateToProps = state => ({
    schema: state.schema.schema,
});

export default connect(mapStateToProps)(ObjectTester);
