/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import LogMessage from './LogMessage';
import LogMessages from './LogMessages';

/**
 * Show import messages.
 *
 * @param {array} messages
 * @param {string} timestamp
 *
 * @return {object}
 */
const ImportMessages = ({ messages, timestamp }) => {
    const components = messages.map((message) => {
        return (
            <LogMessage
                key={`import-message-${message.message_id || message.messageId}`}
                messageId={message.message_id || message.messageId}
                message={message.message}
                timestamp={timestamp}
                level={message.level}
            />
        );
    });


    return <LogMessages messages={components} />;
};

ImportMessages.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    timestamp: PropTypes.string,
};

ImportMessages.defaultProps = {
    timestamp: '',
};

export default ImportMessages;
