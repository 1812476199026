/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import {
    MoveDownButton, MoveUpButton, DeleteRowButton, AddRowButton,
} from '../Buttons';

/**
 * Form template to render array elements.
 *
 * @param {object} props
 *
 * @return {object}
 */
const ArrayTemplate = props => (
    <div className={props.className}>
        {props.items
        && props.items.map(element => (
            <div key={element.index}>
                <div>{element.children}</div>
                {(
                    <MoveDownButton
                        disabled={!element.hasMoveDown}
                        onClick={element.onReorderClick(
                            element.index,
                            element.index + 1,
                        )}
                    />
                )}
                {(
                    <MoveUpButton
                        disabled={!element.hasMoveUp}
                        onClick={element.onReorderClick(
                            element.index,
                            element.index - 1,
                        )}
                    />
                )}
                <DeleteRowButton
                    onClick={element.onDropIndexClick(element.index)}
                />
                <hr />
            </div>
        ))}

        {props.canAdd && (
            <div className="row">
                <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
                    <AddRowButton
                        onClick={event => props.onAddClick(event)}
                    />
                </p>
            </div>
        )}
    </div>
);

export default ArrayTemplate;
