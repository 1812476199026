/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '../../Table';
import Details from './Details';
import ToggleColumn from './ToggleColumn';
import HttpClient from '../../../core/HttpClient/index';
import FeatureDetect from '../../../core/FeatureDetect';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

const mapDispatchToProps = dispatch => ({
    onToggle: (id, row) => {
        dispatch({
            type: 'ON_TOGGLE',
            row,
            id,
        });
    },
});

/**
 * Show Flag overview.
 *
 * @param {string} id
 * @param {string} tag
 * @param {string} operationId
 * @param {function} onToggle
 * @param {string} tables
 * @param {object} schema
 *
 * @return {string}
 */
const Overview = ({
    id,
    tag,
    operationId,
    onToggle,
    tables,
    schema,
}) => {
    const table = tables.find(t => t.id === id);
    const FeatureDetection = new FeatureDetect(schema);
    const handleToggle = (enabled, flag, row) => {
        const operation = (enabled ? 'disableFlag' : 'enableFlag');

        if (FeatureDetection.hasOperationId(operation)) {
            HttpClient
                .request(operation, { flag })
                .then(onToggle('Flags', row));
        }
    };

    return (
        <Table
            title={`${tag}`}
            tag={tag}
            operationId={operationId}
            table={table}
            actionsColumn={row => (
                <ToggleColumn
                    onClick={() => handleToggle(row.enabled, row.flag, row)}
                    enabled={row.enabled}
                />
            )}
        />
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));
export { Details };
