/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { isEmpty } from 'lodash';
import HttpClient from '../../core/HttpClient';

/**
 * Load API data.
 *
 * @param id
 * @param operationId
 * @param filter
 *
 * @returns {function}
 */
const loadApiData = (id, operationId, filter) => (dispatch) => {
    return HttpClient.request(operationId, filter)
        .then((response) => {
            dispatch({
                type: 'LOAD_API_DATA',
                data: response.results,
                records: response.metadata.totalCount,
                id,
            });
        }).catch(() => {
            dispatch({
                type: 'REQUEST_FAILED',
            });
        });
};

/**
 * Build API filter.
 *
 * @param data
 * @param customFilter
 * @param apiParameters
 *
 * @returns {object}
 */
const buildApiFilter = (data, customFilter = null, apiParameters = {}) => {
    const filter = {
        limit: data.limit,
        skip: (data.limit * (data.page - 1)),
    };

    if (!isEmpty(data.where)) {
        filter.where = data.where;
    }

    if (data.sortColumn) {
        filter.order = `${data.sortColumn} ${data.sortDirection}`;
    }

    if (customFilter) {
        filter.where = {
            ...filter.where,
            ...customFilter,
        };
    }

    return {
        ...apiParameters,
        filter: JSON.stringify(filter),
    };
};

const selectRow = (id, row) => {
    return {
        type: 'SELECT_ROW',
        id,
        row,
    };
};

const selectRows = (id, rows) => {
    return {
        type: 'SELECT_ROWS',
        id,
        rows,
    };
};

export {
    loadApiData,
    buildApiFilter,
    selectRow,
    selectRows,
};
