/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from './Loading';
import Error from './Error';
import NoResults from './NoResults';
import './Results.css';

const mapStateToProps = state => ({
    api: state.api,
});

/**
 * Show table rows depending on api status.
 *
 * @param {object} rows
 * @param {string} id
 * @param {object} api
 *
 * @return {object}
 */
const Rows = ({ rows, id, api }) => {
    if (api.isRequesting) {
        return (<Loading />);
    }

    if (api.isError) {
        return (<Error />);
    }

    if (rows.length <= 0) {
        return <NoResults id={id} />;
    }

    return rows;
};

Rows.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    id: PropTypes.string.isRequired,
    api: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
    ])).isRequired,
};

export default connect(
    mapStateToProps,
    null,
)(Rows);
