/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { pickBy } from 'lodash';
import PropTypes from 'prop-types';

// JSON schema defaults
const ENUM_RESOURCE = 'enumResource';
const SCHEMA_RESOURCE = 'schemaResource';

/**
 *
 * @param {string} entity
 * @param {object} uiSchema
 *
 * @return {array}
 */
const getDisabledFilters = (entity, uiSchema) => {
    if (uiSchema[entity] === undefined) {
        return new Array(0);
    }

    const candidates = pickBy(uiSchema[entity], (candidate) => {
        if (candidate.filterable === undefined) {
            return false;
        }

        return !candidate.filterable;
    });

    return Object.keys(candidates);
};

/**
 *
 * @param {string} entity
 * @param {object} uiSchema
 *
 * @return {array}
 */
const getDisabledColumns = (entity, uiSchema) => {
    if (uiSchema[entity] === undefined) {
        return new Array(0);
    }

    const candidates = pickBy(uiSchema[entity], (candidate) => {
        if (candidate.sortable === undefined) {
            return false;
        }

        return !candidate.sortable;
    });

    return Object.keys(candidates);
};

getDisabledFilters.propTypes = {
    entity: PropTypes.string.isRequesting,
    uiSchema: PropTypes.objectOf(PropTypes.object),
};

export {
    getDisabledFilters,
    getDisabledColumns,
    ENUM_RESOURCE,
    SCHEMA_RESOURCE,
};
