/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import Notification from './Notification';
import './Notification.scss';
import InlineTextButton from '../Ui/Buttons/InlineTextButton';

const mapStatetoProps = state => ({
    notifications: state.notification.messages,
});

const mapDispatchToProps = dispatch => ({
    clearAll: () => {
        dispatch({
            type: 'CLEAR_ALL_NOTIFICATIONS',
        });
    },
});

class NotificationsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
    }

    handleToggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    handleClearAll() {
        this.props.clearAll();
    }

    render() {
        const unread = this.props.notifications.filter(
            notification => notification.read === false,
        );

        return (
            <Dropdown
                isOpen={this.state.isOpen}
                toggle={this.handleToggle}
                className="list-inline-item dropdown notification-list"
                nav
            >
                <DropdownToggle>
                    <i className="mdi mdi-bell-outline noti-icon waves-light waves-effect" />
                    {
                        unread.length > 0
                            && <span className="badge badge-pink noti-icon-badge">{unread.length}</span>
                    }
                </DropdownToggle>
                <DropdownMenu className="dropdown-lg">
                    <div className="dropdown-title">
                        <DropdownItem>
                            <h5 className="m-0">
                                <span className="float-right">
                                    <InlineTextButton
                                        label="Clear All"
                                        index={0}
                                        onClick={this.handleClearAll}
                                        className="text-xs text-dark"
                                    />
                                </span>
                            </h5>
                        </DropdownItem>
                    </div>
                    <div className="dropdown-content">
                        {
                            unread.length > 0 && unread.map(item => (
                                <DropdownItem key={item.id} className="notify-item">
                                    <Notification {...item} />
                                </DropdownItem>
                            ))
                        }

                        {
                            unread.length === 0
                            && (
                                <DropdownItem className="notify-item">
                                    <p>No new notifications</p>
                                </DropdownItem>
                            )
                        }
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

NotificationsContainer.propTypes = {
    clearAll: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
    mapStatetoProps,
    mapDispatchToProps,
)(NotificationsContainer);
