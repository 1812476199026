/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import Fields, { uiSchema } from './index';

const Transformer = (props) => {
    return (
        <SchemaField
            uiSchema={uiSchema}
            fields={Fields}
            schema={props.schema}
            registry={props.registry}
            onChange={props.onChange}
            formData={props.formData}
        />
    );
};

export default Transformer;
