/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

/**
 * Show error message.
 *
 * @param {string} message
 * @param {boolean} isOpen
 * @param {function} onClose
 *
 * @return {object}
 */
const ErrorMessage = ({ message, isOpen, onClose }) => (
    <Alert color="danger" isOpen={isOpen} toggle={() => onClose()}>
        {message}
    </Alert>
);

ErrorMessage.propTypes = {
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

ErrorMessage.defaultProps = {
    isOpen: false,
    message: 'Unknown Error',
};

export default ErrorMessage;
