/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../../Table';
import { AddButton } from '../../Ui/Buttons';
import LinkRenderer from './LinkRenderer';

const mapStateToProps = state => ({
    tables: state.table.tables,
});

const buttonParser = {
    create: (table, location, match) => {
        const { url } = match;
        const path = url.replace(`/${match.params.environment}`, '');

        return (
            <AddButton
                key={`${table.id}-add`}
                to={`${path}/create`}
            />
        );
    },
};

/**
 * Show Configuration overview.
 *
 * @param {string} id
 * @param {string} tag
 * @param {string} operationId
 * @param {string} viewAction
 * @param {object} location
 * @param {object} match
 * @param {array} tables
 * @param {boolean} supportsCrud
 *
 * @return {object}
 */
const AbstractOverview = ({
    id, tag, operationId, location, match, tables, actionsColumn, options = [], title, header, viewAction, filter,
}) => {
    const table = tables.find(t => t.id === id);

    const buttons = options
        .filter(option => Object.keys(buttonParser).indexOf(option) !== -1)
        .map(option => buttonParser[option](table, location, match));

    return (
        <Table
            title={`${title || tag}`}
            id={id}
            tag={tag}
            operationId={operationId}
            table={table}
            buttons={buttons}
            actionsColumn={actionsColumn}
            header={header}
            viewAction={viewAction}
            renderers={[LinkRenderer]}
            filter={filter}
        />
    );
};

AbstractOverview.propTypes = {
    id: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

AbstractOverview.defaultProps = {
    filter: false,
};

export default withRouter(connect(
    mapStateToProps,
    null,
)(AbstractOverview));
