/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import MappingContainer from '../Magement/Mapping';
import ValueMapping from '../Magement/Mapping/ValueMapping';
import Route from './PrivateRoute';

const Mapping = () => {
    return (
        <Switch>
            <Route
                exact
                path="/:environment/mapping"
                render={() => (<MappingContainer />)}
            />
            <Route
                exact
                path="/:environment/mapping/values"
                render={() => <ValueMapping />}
            />
        </Switch>
    );
};

export default Mapping;
