/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const Config = {
    name: 'Alumio Dashboard',
    api: {
        numRecords: 20,
        availablePages: [20, 40, 60, 80, 100],
    },
    ui: {
        iconSize: 20,
    },
};

export default Config;
