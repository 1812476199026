/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import Status from './Status';
import CloseButton from '../../Ui/Buttons/CloseButton';

const nameMapping = {
    disk: 'Disk space',
    export_log: 'Export logs',
    import_log: 'Import logs',
    task: 'Tasks',
    task_stuck: 'Stuck tasks',
};

/**
 * Show health status of identifier.
 *
 * @param identifier
 * @param healthy
 * @param messages
 *
 * @returns {object}
 */
const HealthStatus = ({ identifier, healthy, messages }) => {
    const name = nameMapping[identifier] || identifier;

    const statusIcon = (
        <Status isHealthy={healthy} name={name} />
    );

    const [isOpen, setIsOpen] = useState(false);

    if (!healthy) {
        const toggle = () => {
            setIsOpen(!isOpen);
        };

        return (
            <>
                <button type="button" className="p-0 toggle-report-button btn btn-link text-danger" onClick={toggle}>
                    { statusIcon }
                </button>
                <Modal isOpen={isOpen} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{ name }</ModalHeader>
                    <ModalBody>{ map(messages, message => message) }</ModalBody>
                    <ModalFooter>
                        <CloseButton onClose={toggle} />
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    return statusIcon;
};

HealthStatus.propTypes = {
    identifier: PropTypes.string.isRequired,
    healthy: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string),
};

HealthStatus.defaultProps = {
    messages: [],
};

export default HealthStatus;
