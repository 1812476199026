/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Table from '../../Table/index';

/**
 * Show web hook files
 *
 * @param {string} title
 * @param {object} table
 * @param {string} identifier
 *
 * @return {object}
 */
const TableProgress = ({ title, table, identifier }) => (
    <Table
        showHeader={false}
        title={title}
        id="WebhookProgress"
        table={table}
        tag="Hooks"
        operationId="getHookProgress"
        apiParameters={{ hook: identifier }}
    />
);

export default TableProgress;
