/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import moment from 'moment';

/**
 * Filter to fetch new tasks.
 *
 * @type {object}
 */
const newTasksFilter = {
    status: 'new',
};

/**
 * Filter to fetch processing tasks.
 *
 * @type {object}
 */
const processingTasksFilter = {
    status: 'processing',
};

/**
 * Filter to fetch stuck tasks.
 *
 * @type {object}
 */
const stuckTasksFilter = {
    status: 'processing',
    updated_at: {
        lte: moment().utc().subtract(1, 'hour').format(),
    },
};

/**
 * Filter to fetch total/all tasks.
 *
 * @type {object}
 */
const totalTasksFilter = {};

/**
 * Filter to fetch finished tasks.
 *
 * @type {object}
 */
const finishedTasksFilter = {
    status: 'finished',
};

/**
 * Filter to fetch failed tasks.
 *
 * @type {object}
 */
const failedTasksFilter = {
    status: 'failed',
};

export default {
    new: newTasksFilter,
    processing: processingTasksFilter,
    stuck: stuckTasksFilter,
    total: totalTasksFilter,
    finished: finishedTasksFilter,
    failed: failedTasksFilter,
};
