/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import {
    has, isObject, map, isArray,
} from 'lodash';
import ReactJSON from 'react-json-view';
import render from '../Render';
import { PARAMETERS_PROPERTY, PLUGINS_PROPERTY, AUTHENTICATION_PROPERTY } from '../../JsonSchema';
import { EncryptedIcon } from '../../../components/Ui/Icons';
import ClipBoard from '../../../components/Ui/ClipBoard';
import { formatDatetime, formatHumanReadableDate } from '../../Dates';

const TYPE_DATE = 'date';
const TYPE_ARRAY = 'array';

/**
 * Create a wrapper around a given renderer that activates when one of the given
 * fields matches the name of the current cel column.
 *
 * @param {object} renderer
 * @param {array<string>} fields
 * @returns {object}
 */
function fieldRendererFactory(renderer, ...fields) {
    return {
        ...renderer,
        isCandidate: (subject, definition, name, ...args) => (
            fields.reduce(
                (carry, field) => (
                    carry || field === name
                ),
                false,
            ) && renderer.isCandidate(subject, definition, name, ...args)
        ),
    };
}

const rangeFilterRenderer = {
    isCandidate: (subject) => {
        return isObject(subject) && (has(subject, 'gte') || has(subject, 'lte'));
    },
    render: (subject, definition) => {
        let values = [];
        if (subject.gte) {
            values.push(subject.gte);
        }

        if (subject.lte) {
            values.push(subject.lte);
        }

        if (definition.type === 'date') {
            values = values.map((value, index) => {
                const prefix = index < 1 ? 'From' : 'To';

                return `${prefix} ${formatDatetime(value)}`;
            });
        }

        return values.join(' - ');
    },
};

const encryptedRenderer = {
    isCandidate: (subject, definition, name, context = {}) => (
        context.encrypted === true
    ),
    render: () => (
        <div>
            <EncryptedIcon />
            {' '}
            ••••••
        </div>
    ),
};

const variableRenderer = {
    isCandidate: subject => /^[A-Z]+[A-Z0-9_]*$/.test(subject),
    render: subject => (
        <ClipBoard subject={subject} />
    ),
};

/**
 * Render an object.
 *
 * @type {object}
 */
const objectRenderer = {
    isCandidate: subject => (isObject(subject) && !isArray(subject)),
    render: (subject, definition) => (
        <table className="table">
            <tbody>
                {
                    map(definition.properties, (property, key) => {
                        const cssClass = objectRenderer.isCandidate(subject[key])
                            ? 'has-children'
                            : '';

                        return (
                            <tr key={`object-render-${key}`}>
                                <td>{key}</td>
                                <td className={cssClass}>
                                    {render(subject[key], property, key)}
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    ),
};

const parametersRenderer = {
    isCandidate: (subject, definition, name) => {
        return [PARAMETERS_PROPERTY, PLUGINS_PROPERTY, AUTHENTICATION_PROPERTY].includes(name);
    },
    render: (subject) => {
        let parameters = subject || {};

        if (!isObject(parameters)) {
            parameters = JSON.parse(subject);
        }

        return (
            <ReactJSON
                name={false}
                src={parameters}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
            />
        );
    },
};

/**
 * Render a date.
 *
 * @type {object}
 */
const dateRenderer = {
    isCandidate: (subject, definition) => definition.type === TYPE_DATE,
    render: (subject) => {
        return formatHumanReadableDate(subject);
    },
};

/**
 * Render value from enum.
 *
 * @type {{isCandidate: function(*, *=): *, render: function(*, *)}}
 */
const enumRenderer = {
    isCandidate: (subject, definition) => has(definition, 'enum') && has(definition, 'enumNames'),
    render: (subject, definition) => {
        const index = definition.enum.findIndex(element => element === subject);
        return definition.enumNames[index];
    },
};

const booleanRenderer = {
    isCandidate: (subject, definition) => definition.type === 'boolean',
    render: subject => (subject ? 'True' : 'False'),
};

const arrayRenderer = {
    isCandidate: (subject, definition) => (
        definition.type === TYPE_ARRAY
        && Array.isArray(subject)
        && has(definition.items, 'enum')
        && has(definition.items, 'enumNames')
        && subject.length > 0
    ),
    render: (subject, definition) => (
        <ul className="list-group">
            {
                subject.map((item) => {
                    const index = definition.items.enum.findIndex(element => element === item);
                    const value = definition.items.enumNames[index];

                    return (
                        <li
                            key={index}
                            className="list-group-item list-group-item-action"
                        >
                            {value}
                        </li>
                    );
                })
            }
        </ul>
    ),
};

const emptyArrayRenderer = {
    isCandidate: (subject, definition) => (
        definition.type === TYPE_ARRAY
        && Array.isArray(subject)
        && subject.length === 0
    ),
    render: () => 'No items found.',
};

const defaultRenderer = {
    isCandidate: () => true,
    render: subject => subject,
};

const Renderers = [
    rangeFilterRenderer,
    parametersRenderer,
    objectRenderer,
    dateRenderer,
    emptyArrayRenderer,
    enumRenderer,
    booleanRenderer,
    arrayRenderer,
    defaultRenderer,
];

export default Renderers;
export {
    fieldRendererFactory,
    rangeFilterRenderer,
    encryptedRenderer,
    variableRenderer,
    objectRenderer,
    parametersRenderer,
    dateRenderer,
    emptyArrayRenderer,
    enumRenderer,
    booleanRenderer,
    arrayRenderer,
    defaultRenderer,
};
