/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type } from '../Magement';
import FeatureDetect from '../../core/FeatureDetect';
import DiCreate from '../Magement/Di/Create';
import DiUpdate from '../Magement/Di/Update';
import Route from './PrivateRoute';

const configurationsBasePath = '/:environment/filesystems/configurations';
const typesBasePath = '/:environment/filesystems/types';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Filesystems = ({ schema }) => {
    const featureDetect = new FeatureDetect(schema);
    const hasDiObjectConfiguration = featureDetect.hasModelDefinition('DiObjectConfiguration');

    return (
        <Switch>
            <Route
                exact
                path={typesBasePath}
                render={() => (
                    <Type.Overview
                        id="FilesystemTypes"
                        tag="Filesystems"
                        operationId="getFilesystemTypes"
                    />
                )}
            />
            <Route
                exact
                path={`${typesBasePath}/:identifier`}
                render={
                    props => (
                        <Type.Details
                            configuration={{
                                id: 'FilesystemConfigurations',
                                tag: 'Filesystems',
                                operationId: 'getFilesystemConfigurations',
                            }}
                            tag="Filesystems"
                            operationIds={{
                                read: 'getFilesystemType',
                            }}
                            entity="ConfigurationType"
                            params={{ type: props.match.params.identifier }}
                        />
                    )
                }
            />
            <Route
                exact
                path={configurationsBasePath}
                render={() => {
                    return hasDiObjectConfiguration
                        ? (
                            <Configuration.Overview
                                id="DiObjectConfiguration"
                                tag="Di"
                                operationId="getObjectConfigurations"
                                createOperationId="createObjectConfiguration"
                                title="Filesystems"
                                filter={{ interface: 'League\\Flysystem\\FilesystemInterface' }}
                            />
                        )
                        : (
                            <Configuration.Overview
                                id="FilesystemConfigurations"
                                tag="Filesystems"
                                operationId="getFilesystemConfigurations"
                                createOperationId="createFilesystemConfiguration"
                            />
                        );
                }}
            />
            <Route
                exact
                path={`${configurationsBasePath}/create`}
                render={() => {
                    return hasDiObjectConfiguration
                        ? (
                            <DiCreate
                                baseUrl={configurationsBasePath}
                                interface="League\Flysystem\FilesystemInterface"
                                title="Create a filesystem"
                            />
                        )
                        : (
                            <Configuration.Create
                                tag="Filesystems"
                                operationIds={{
                                    read: 'getFilesystemConfiguration',
                                    create: 'createFilesystemConfiguration',
                                }}
                                entity="FilesystemConfiguration"
                                basePath={configurationsBasePath}
                            />
                        );
                }}
            />
            <Route
                exact
                path={`${configurationsBasePath}/:identifier`}
                render={
                    (props) => {
                        return hasDiObjectConfiguration
                            ? (
                                <DiUpdate
                                    interface="League\Flysystem\FilesystemInterface"
                                    baseUrl={configurationsBasePath}
                                    identifier={props.match.params.identifier}
                                />
                            )
                            : (
                                <Configuration.Details
                                    tag="Filesystems"
                                    operationIds={{
                                        read: 'getFilesystemConfiguration',
                                        update: 'updateFilesystemConfiguration',
                                        delete: 'deleteFilesystemConfiguration',
                                    }}
                                    entity="FilesystemConfiguration"
                                    params={{ identifier: props.match.params.identifier }}
                                    basePath={configurationsBasePath}
                                />
                            );
                    }
                }
            />
        </Switch>
    );
};

export default Filesystems;
