/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import AbstractOverview from '../Entity/AbstractOverview';
import Create from './Create';
import Candidates from './Candidates';
import Tabs from '../../Ui/Tabs';
import PageHeader from '../../Ui/PageHeader';
import TableSystemVariables from './TableSystemVariables';
import TableEnvironmentVariables from './TableEnvironmentVariables';
import AddButton from '../../Ui/Buttons/AddButton';
import EnvironmentCandidatesWidget from '../../Widget/EnvironmentCandidatesWidget';
import FeatureDetect from '../../../core/FeatureDetect';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

/**
 * Show Configuration overview.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Overview = (props) => {
    const sysTable = props.tables.find(t => t.id === 'SystemVariables');
    const envTable = props.tables.find(t => t.id === 'EnvironmentVariables');
    const FeatureDetection = new FeatureDetect(props.schema);

    const tabs = [
        {
            id: 'environment-variables',
            name: 'Environment variables',
            content: () => (
                <TableEnvironmentVariables
                    table={envTable}
                    basePath={props.basePath}
                />
            ),
        },
        {
            id: 'system-variables',
            name: 'System variables',
            content: () => (
                <TableSystemVariables table={sysTable} />
            ),
        },

    ];

    const buttons = [];

    if (FeatureDetection.hasOperationId('createEnvironmentVariable')) {
        buttons.push(
            <AddButton to="/environment/variables/create" />,
        );
    }

    return (
        <div>
            <EnvironmentCandidatesWidget
                className="widget-style-2 bg-pink text-white"
                icon="ion-md-alert"
            />

            <PageHeader title={props.title}>
                {buttons}
            </PageHeader>

            <Card>
                <CardBody className="p-0">
                    <Tabs
                        tabs={tabs}
                        default="environment-variables"
                    />
                </CardBody>
            </Card>
        </div>
    );
};

Overview.propTypes = AbstractOverview.propTypes;

export default connect(
    mapStateToProps,
    null,
)(Overview);

export { Create, Candidates };
