/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type, StorageEntity } from '../Magement';
import Details from '../Magement/Storage/Details';
import Route from './PrivateRoute';

const configurationsBasePath = '/:environment/storages/configurations';
const typesBasePath = '/:environment/storages/types';
const entitiesBasePath = '/:environment/storages/entities';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Storages = () => (
    <Switch>
        <Route
            exact
            path={configurationsBasePath}
            render={() => (
                <Configuration.Overview
                    id="StorageConfigurations"
                    tag="Storages"
                    operationId="getStorageConfigurations"
                    createOperationId="createStorageConfiguration"
                />
            )}
        />
        <Route
            exact
            path={`${configurationsBasePath}/create`}
            render={
                () => (
                    <Configuration.Create
                        tag="Storages"
                        operationIds={{
                            create: 'createStorageConfiguration',
                            read: 'getStorageConfiguration',
                            update: 'updateStorageConfiguration',
                            delete: 'deleteStorageConfiguration',
                        }}
                        entity="StorageConfiguration"
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${configurationsBasePath}/:identifier`}
            render={
                props => (
                    <Details
                        tag="Storages"
                        operationIds={{
                            create: 'createStorageConfiguration',
                            read: 'getStorageConfiguration',
                            update: 'updateStorageConfiguration',
                            delete: 'deleteStorageConfiguration',
                        }}
                        entity="StorageConfiguration"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
        <Route
            exact
            path={typesBasePath}
            render={() => (
                <Type.Overview
                    id="StorageTypes"
                    tag="Storages"
                    operationId="getStorageTypes"
                />
            )}
        />
        <Route
            exact
            path={`${entitiesBasePath}/:identifier/:entity`}
            render={props => (
                <StorageEntity.Overview
                    tag="Storages"
                    entity="StorageContent"
                    operationId="getStorageEntity"
                    basePath={`${configurationsBasePath}`}
                    entityIdentifier={props.match.params.entity}
                    identifier={props.match.params.identifier}
                />
            )}
        />
    </Switch>
);

export default Storages;
