/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Render a view button.
 *
 * @param {string} text
 * @param {string} className
 *
 * @return {object}
 */
const ViewButton = ({ text, className }) => {
    const margin = text ? 'm-r-5' : '';

    return (
        <Button color="primary" className={className}>
            <IonIcon icon="search" margin={margin} />
            {text}
        </Button>
    );
};

ViewButton.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    className: PropTypes.string,
};

ViewButton.defaultProps = {
    text: false,
    className: '',
};

export default ViewButton;
