/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';

const ActionColumn = ({ children }) => (
    <td>{children}</td>
);

export default ActionColumn;
