/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import TaskContainer from './TaskContainer';
import '../../Entity/Entity.css';

/**
 * Task details.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Details = (props) => {
    return (
        <TaskContainer {...props} />
    );
};

Details.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
};

Details.defaultProps = {
    params: {},
};

export default Details;
