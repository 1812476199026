/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TabLink from './TabLink';

class Tabs extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            active: this.props.location.hash.substring(1) || props.default,
        };
    }

    /**
     * When a tab link is clicked.
     *
     * @param {string} tab
     *
     * @return {void}
     */
    onClick(tab) {
        this.setState({
            active: tab,
        });
    }

    /**
     * Render tabs.
     *
     * @returns {object}
     */
    render() {
        const tabs = this.props.tabs.map(tab => (
            <TabLink
                key={`tab-${tab.id}`}
                active={tab.id === this.state.active}
                tab={tab.id}
                name={tab.name}
                onClick={current => this.onClick(current)}
            />
        ));

        const content = this.props.tabs.find(item => item.id === this.state.active);

        return (
            <div>
                <ul className="nav nav-tabs profile-tabs">{tabs}</ul>
                <div className="pt-4 p-4">
                    {content.content()}
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        content: PropTypes.func,
    })).isRequired,
    default: PropTypes.string.isRequired,
};

export default withRouter(Tabs);
