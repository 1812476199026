/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Button to cancel an action.
 *
 * @param {function} onCancel
 *
 * @return {object}
 */
const RetryButton = ({
    onRetry,
    hasError,
    isSubmitting,
    numItems,
}) => {
    const icon = isSubmitting
        ? 'hourglass'
        : (hasError
            ? 'alert'
            : 'repeat'
        );

    const color = hasError
        ? 'danger'
        : 'secondary';

    const label = numItems === 1
        ? 'Retry'
        : `Retry ${numItems} tasks`;

    return (
        <Button color={color} onClick={() => onRetry()} disabled={isSubmitting}>
            <IonIcon icon={icon} hasText />
            { label }
        </Button>
    );
};

RetryButton.propTypes = {
    onRetry: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    numItems: PropTypes.number,
};

RetryButton.defaultProps = {
    hasError: false,
    isSubmitting: false,
    numItems: 1,
};

export default RetryButton;
