/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import App from './App';
import Login from './components/Login';
import BaseRedirect from './BaseRedirect';
import PrivateRoute from './components/Routes/PrivateRoute';
import './css/theme.css';
import Wrapper from './Wrapper';

ReactDOM.render(
    (
        <Provider store={store}>
            <Wrapper>
                <Router>
                    <Switch>
                        <Route exact path="/" component={BaseRedirect} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/:environment/login" component={Login} />
                        <PrivateRoute path="/:environment" render={props => <App {...props} />} />
                    </Switch>
                </Router>
            </Wrapper>
        </Provider>
    ),
    document.getElementById('root'),
);

registerServiceWorker();
