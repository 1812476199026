/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Remote } from '../Magement';
import Route from './PrivateRoute';

const basePath = '/:environment/remotes';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Remotes = () => (
    <Switch>
        <Route
            exact
            path={basePath}
            render={() => (
                <Remote.Overview
                    id="Remotes"
                    tag="Remotes"
                    operationId="getRemotes"
                    basePath={basePath}
                />
            )}
        />
        <Route
            exact
            path={`${basePath}/create`}
            render={
                () => (
                    <Remote.Create
                        tag="Remotes"
                        entity="Remote"
                        basePath={basePath}
                        operationIds={{
                            create: 'createRemote',
                            read: 'getRemote',
                            update: 'updateRemote',
                            delete: 'deleteRemote',
                        }}
                    />
                )
            }
        />
        <Route
            exact
            path={`${basePath}/:identifier`}
            render={
                props => (
                    <Remote.Details
                        tag="Remotes"
                        operationIds={{
                            read: 'getRemote',
                            update: 'updateRemote',
                            delete: 'deleteRemote',
                        }}
                        entity="Remote"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={basePath}
                    />
                )
            }
        />
    </Switch>
);

export default Remotes;
