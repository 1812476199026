/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */
import moment from 'moment';

/**
 * Get a date range based on the number of hours.
 *
 * @param hours
 *
 * @returns {object}
 */
const getRange = (hours) => {
    return {
        from: (moment().utc().subtract(hours, 'hours')).format(),
        to: (moment().utc()).format(),
    };
};

/**
 * Get a date range from the end of the day to the end of the day.
 *
 * @returns {object}
 */
const getRangeToday = () => {
    return {
        from: moment().utc().startOf('day').format(),
        to: moment().utc().endOf('day').format(),
    };
};

/**
 * Get a date range from the start of the current hour to the end of the cuurent hour.
 *
 * @returns {object}
 */
const getRangeHour = () => {
    return {
        from: moment().utc().startOf('hour').format(),
        to: moment().utc().endOf('hour').format(),
    };
};

const RANGE_24_HOURS = 'RANGE_24_HOURS';
const RANGE_TODAY = 'RANGE_TODAY';
const RANGE_LAST_HOUR = 'RANGE_LAST_HOUR';
const RANGE_CUSTOM = 'RANGE_CUSTOM';

const options = {
    [RANGE_24_HOURS]: {
        label: '24h',
        value: () => (getRange(24)),
    },
    [RANGE_TODAY]: {
        label: 'Today',
        value: () => (getRangeToday()),
    },
    [RANGE_LAST_HOUR]: {
        label: 'Last hour',
        value: () => (getRangeHour()),
    },
    [RANGE_CUSTOM]: {
        label: 'Custom',
        value: data => data.range,
    },
};

export {
    options,
    getRange,
    getRangeToday,
    getRangeHour,
    RANGE_24_HOURS,
    RANGE_TODAY,
    RANGE_LAST_HOUR,
    RANGE_CUSTOM,
};
