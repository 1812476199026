/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Switch from 'react-switchery-component';
import 'react-switchery-component/react-switchery-component.css';

/**
 * Toggle button.
 *
 * @param {function} onClick
 * @param {bool} status
 *
 * @return {object}
 */
const ToggleButton = ({ onClick, enabled, size }) => (
    <Switch
        onChange={onClick}
        type="checkbox"
        speed=".5s"
        data-plugin="switchery"
        data-color="#039cfd"
        checked={enabled}
        size={size}
    />
);

ToggleButton.defaultProps = {
    size: 'normal',
};

export default ToggleButton;
