import React from 'react';
import ReactJson from 'react-json-view';
import {
    Table, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, Row, Col, ListGroup, ListGroupItem,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IonIcon from '../../Ui/Icons/IonIcon';

class CandidateMatch extends React.Component {
    /**
     * Render the given payload.
     *
     * @param {object} payload
     *
     * @returns {object}
     */
    static renderPayload(payload) {
        return (
            <Table>
                <tbody>
                    {Object.keys(payload).map(property => (
                        <tr>
                            <th scope="row">{property}</th>
                            <td>
                                <ReactJson
                                    name={false}
                                    src={payload[property]}
                                    displayObjectSize={false}
                                    displayDataTypes={false}
                                    enableClipboard={false}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    /**
     * Render the metadata for the given type.
     *
     * @param {string} type
     * @param {object} metadata
     *
     * @returns {object}
     */
    static renderMetadata(type, metadata) {
        switch (type) {
            case 'doctrine-entity':
                return CandidateMatch.renderDoctrineEntity(metadata);

            default:
                return (
                    <pre><code>{JSON.stringify(metadata)}</code></pre>
                );
        }
    }

    /**
     * Render a Doctrine Entity metadata overview.
     *
     * @param {object} metadata
     *
     * @returns {object}
     */
    static renderDoctrineEntity(metadata) {
        return (
            <Table>
                <tbody>
                    <tr>
                        <th scope="row">Entity</th>
                        <td>
                            {metadata.class}
                            <br />
                            <small>{metadata.table}</small>
                            <br />
                            <pre><code>{JSON.stringify(metadata.identifier)}</code></pre>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Fields</th>
                        <td>
                            <ListGroup>
                                {metadata.fields.map(field => (
                                    <ListGroupItem>{field}</ListGroupItem>
                                ))}
                            </ListGroup>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    /**
     * Get the label for the given type.
     *
     * @param {string} type
     *
     * @returns {string}
     */
    static getTypeLabel(type) {
        switch (type) {
            case 'doctrine-entity':
                return (
                    <div>
                        <IonIcon icon="cube" />
                        Doctrine entity
                    </div>
                );

            default:
                return (
                    <div>
                        <IonIcon icon="warning" />
                        Generic match
                    </div>
                );
        }
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'payload',
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        return (
            <Card body>
                <CardTitle>{CandidateMatch.getTypeLabel(this.props.type)}</CardTitle>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 'payload' })}
                            onClick={() => { this.toggle('payload'); }}
                        >
                            Payload
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 'metadata' })}
                            onClick={() => { this.toggle('metadata'); }}
                        >
                            Metadata
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="payload">
                        <Row>
                            <Col sm="12">
                                {CandidateMatch.renderPayload(this.props.payload)}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="metadata">
                        <Row>
                            <Col sm="12">
                                {CandidateMatch.renderMetadata(
                                    this.props.type,
                                    this.props.metadata,
                                )}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </Card>
        );
    }
}

CandidateMatch.propTypes = {
    type: PropTypes.string.isRequired,
    payload: PropTypes.objectOf(PropTypes.object).isRequired,
    metadata: PropTypes.objectOf(PropTypes.any).isRequired,
};

CandidateMatch.defaultProps = {
};

export default CandidateMatch;
