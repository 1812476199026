/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

/**
 * Redirect users to the current environment or login page.
 *
 * @returns {object}
 */
const BaseRedirect = ({ environment }) => {
    const path = environment || 'login';

    return <Redirect to={`/${path}`} />;
};

const mapStateToProps = state => ({
    environment: state.authentication.environment,
});

export default connect(mapStateToProps)(BaseRedirect);
