/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Details from './Details';
import Create from './Create';
import AbstractOverview from '../Entity/AbstractOverview';

/**
 * Show Configuration overview.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Overview = props => (
    <AbstractOverview {...props} />
);

Overview.propTypes = AbstractOverview.propTypes;

export default Overview;
export { Details, Create };
