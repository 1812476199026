/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import ReactJSON from 'react-json-view';


const ParametersWidget = (props) => {
    const data = props.value !== undefined
        ? JSON.parse(props.value)
        : {};

    const changeHandler = (subject, onChange) => {
        onChange(JSON.stringify(subject.updated_src));
    };

    return (
        <ReactJSON
            name={false}
            src={data}
            onAdd={json => changeHandler(json, jsonData => props.onChange(jsonData))}
            onEdit={json => changeHandler(json, jsonData => props.onChange(jsonData))}
            onDelete={json => changeHandler(json, jsonData => props.onChange(jsonData))}
        />
    );
};

export default ParametersWidget;
