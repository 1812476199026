/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { onEnterHandler } from '../../../core/Ui';

/**
 * Inline text button that handles mouse clicks and key presses.
 *
 * @param {string} label
 * @param {number} index
 * @param {function} onClick
 * @param {string }className
 *
 * @return {object}
 */
const InlineTextButton = ({
    label, index, onClick, className,
}) => (
    <span
        className={`clickable ${className}`}
        role="button"
        tabIndex={index}
        onClick={() => onClick()}
        onKeyUp={event => onEnterHandler(event, onClick())}

    >
        {label}
    </span>
);

InlineTextButton.propTypes = {
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

InlineTextButton.defaultProps = {
    className: '',
};

export default InlineTextButton;
