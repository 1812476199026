/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */
import OpenApi3Converter from '../../core/JsonSchema/OpenApi3Converter';

const SCHEMA_LOADED = 'SCHEMA_LOADED';
const UI_SCHEMA_LOADED = 'UI_SCHEMA_LOADED';
const SCHEMAS_LOADED = 'SCHEMAS_LOADED';

/**
 * Receive new schema definition.
 *
 * @param {object} schema
 *
 * @returns {object}
 */
const receiveSchema = schema => ({
    type: SCHEMA_LOADED,
    schema,
});

/**
 * Receive new UI schema definition.
 *
 * @param {object} uiSchema
 *
 * @returns {object}
 */
const receiveUISchema = uiSchema => ({
    type: UI_SCHEMA_LOADED,
    uiSchema,
});

/**
 * Receive the schema and ui schema.
 *
 * @param {object} schema
 * @param {object} uiSchema
 *
 * @returns {object}
 */
const receiveSchemas = (schema, uiSchema) => (dispatch) => {
    const converter = new OpenApi3Converter(schema);

    dispatch({
        type: SCHEMAS_LOADED,
        schema: converter.convert(),
        uiSchema,
    });
};

export {
    SCHEMA_LOADED,
    UI_SCHEMA_LOADED,
    SCHEMAS_LOADED,
    receiveSchema,
    receiveUISchema,
    receiveSchemas,
};
