/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

class Equals {
    /**
     * Get a equals filter schema.
     *
     * @param {string} name
     * @param {object} field
     *
     * @return {object}
     */
    static parse(name, field) {
        // no customizations required.
        return field;
    }
}

export default Equals;
