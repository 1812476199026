/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import Table from '../../Table';
import ActionColumn from '../../Table/ActionColumn';
import Delete from '../Entity/Delete';

const mapDispatchToProps = dispatch => ({
    onDelete: (id, row) => {
        dispatch({
            type: 'ON_DELETE',
            row,
            id,
        });
    },
});

const StorageEntityView = props => (
    <Table
        id="StorageKeys"
        tag="Storages"
        title="Storage Keys"
        sticky={false}
        operationId="getStorageEntities"
        apiParameters={props.params}
        table={props.table}
        viewAction={(row) => { return `/storages/entities/${props.params.identifier}/${row.identifier}`; }}
        actionsColumn={row => (
            <ActionColumn>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <Delete
                            baseUrl={`${props.basePath}/${props.params.identifier}#entities`}
                            inline
                            tag="Storages"
                            operationId="deleteStorageEntity"
                            onSuccess={() => props.onDelete(
                                props.table.id,
                                row,
                            )}
                            params={{ identifier: props.params.identifier, entity: row.identifier }}
                        />
                    </li>
                </ul>
            </ActionColumn>
        )}
    />
);

export default connect(
    null,
    mapDispatchToProps,
)(StorageEntityView);
