/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import AbstractMutator from '../../../../core/Mutators/AbstractMutator';
import store from '../../../../store';
import { CHANGE_CONFIG } from '../../../../reducers/transformer/actions';

class TransformerDataMutator extends AbstractMutator {
    mutate(form) {
        store.dispatch({
            type: CHANGE_CONFIG,
            testData: form,
        });
        return form[this.target];
    }

    isCandidate() {
        return true;
    }
}

export default TransformerDataMutator;
