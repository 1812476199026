/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import HttpClient from '../../../core/HttpClient/index';
import PageHeader from '../../Ui/PageHeader';
import '../../Entity/Entity.css';
import TableFiles from './TableFiles';
import TableProgress from './TableProgress';
import Tabs from '../../Ui/Tabs/index';
import BackButton from '../../Ui/Buttons/BackButton';

const mapStateToProps = state => ({
    schema: state.schema.schema,
    tables: state.table.tables,
});

class WebHookDetails extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            data: { identifier: 'Loading' },
        };

        this.tag = 'Hooks';
        this.operationId = 'getHook';
    }

    /**
     * Load API data.
     *
     * @return {void}
     */
    componentDidMount() {
        HttpClient.request(this.operationId, { hook: this.props.identifier })
            .then((response) => {
                this.setState({
                    data: response,
                });
            })
            .catch(() => {
                // Silently fail.
            });
    }

    /**
     * Render entity.
     *
     * @returns {string}
     */
    render() {
        const tabs = [
            {
                id: 'progress',
                name: 'Progress',
                content: () => (
                    <TableProgress
                        identifier={this.props.identifier}
                        table={this.props.tables.find(t => t.id === 'WebhookProgress')}
                    />
                ),
            },
            {
                id: 'files',
                name: 'Files',
                content: () => (
                    <TableFiles
                        title={`Hook ${this.state.data.name}`}
                        identifier={this.props.identifier}
                        table={this.props.tables.find(t => t.id === 'SharedWebHookFiles')}
                    />
                ),
            },
        ];

        return (
            <div>
                <PageHeader
                    title={`Hook ${this.state.data.name}`}
                >
                    <BackButton to="/webhooks" />
                </PageHeader>
                <Card>
                    <CardBody className="p-0">
                        <Tabs
                            tabs={tabs}
                            default="progress"
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    null,
)(WebHookDetails));
