/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { has, isObject, cloneDeep } from 'lodash';
import HttpClient from '../../core/HttpClient';
import { PARAMETERS_PROPERTY } from '../../core/JsonSchema';

const REQUEST_TYPES = 'REQUEST_TYPES';
const RECEIVE_TYPES = 'RECEIVE_TYPES';
const SELECT_TYPE = 'SELECT_TYPE';
const VIEW_TYPE = 'VIEW_TYPE';
const CHANGE_CONFIG = '_CONFIG';
const ADD_TEST_ENTITY = 'ADD_TEST_ENTITY';
const ADD_ERROR = 'ADD_ERROR';
const CLOSE_ERROR = 'CLOSE_ERROR';

/**
 * Select transformer type.
 *
 * @param {string} identifier
 *
 * @returns {object}
 */
const selectType = identifier => ({
    type: SELECT_TYPE,
    identifier,
});

/**
 * View transformer type.
 *
 * @param {string} identifier
 * @param {string} name
 * @param {object} schema
 *
 * @returns {object}
 */
const viewTransformer = (identifier, name, schema) => ({
    type: VIEW_TYPE,
    identifier,
    name,
    schema,
});

/**
 * Request transformer types.
 *
 * @param {string} identifier
 *
 * @returns {object}
 */
const requestTypes = identifier => ({
    type: REQUEST_TYPES,
    identifier,
});

/**
 * Receive transformer types.
 *
 * @param {string} identifier
 * @param {array} data
 *
 * @returns {object}
 */
const receiveTypes = (identifier, data) => ({
    type: RECEIVE_TYPES,
    identifier,
    data,
});

/**
 * Register new test entities.
 *
 * @param {string} identifier
 * @param {object} entity
 *
 * @returns {object}
 */
const addTestEntity = (identifier, entity) => ({
    type: ADD_TEST_ENTITY,
    identifier,
    entity,
});

/**
 * Add error.
 *
 * @param {string} message
 *
 * @returns {object}
 */
const addError = message => ({
    type: ADD_ERROR,
    message,
});

/**
 * Close error.
 *
 * @returns {object}
 */
const closeError = () => ({
    type: CLOSE_ERROR,
});

/**
 * Change transformer configuration.
 *
 * @param {array} data
 *
 * @returns {object}
 */
const changeConfig = (data) => {
    const testData = cloneDeep(data);
    if (has(testData, PARAMETERS_PROPERTY) && !isObject(testData[PARAMETERS_PROPERTY])) {
        try {
            testData.parameters = JSON.parse(testData.parameters);
        } catch (ex) {
            testData.parameters = {};
        }
    }

    return {
        type: CHANGE_CONFIG,
        testData,
    };
};

/**
 * Fetch transformer types.
 *
 * @param {string} identifier
 * @param {string} tag
 * @param {string} operationId
 *
 * @returns {function}
 */
const fetchTypes = (identifier, tag, operationId) => (dispatch) => {
    dispatch(requestTypes(identifier));

    return HttpClient.request(operationId)
        .then(response => response.results)
        .then(data => dispatch(receiveTypes(identifier, data)));
};

/**
 * Whether transformer types should be fetched.
 *
 * @param {object} state
 * @param {string} identifier
 *
 * @returns {boolean}
 */
const shouldFetchTypes = (state, identifier) => (typeof state.transformer.items[identifier] === 'undefined');

const fetchTypesIfNeeded = (identifier, tag, operationId) => (dispatch, getState) => {
    if (shouldFetchTypes(getState(), identifier)) {
        return dispatch(fetchTypes(identifier, tag, operationId));
    }

    return null;
};

export {
    selectType,
    viewTransformer,
    fetchTypesIfNeeded,
    addTestEntity,
    addError,
    closeError,
    changeConfig,
    REQUEST_TYPES,
    RECEIVE_TYPES,
    SELECT_TYPE,
    VIEW_TYPE,
    CHANGE_CONFIG,
    ADD_TEST_ENTITY,
    ADD_ERROR,
    CLOSE_ERROR,
};
