/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { IonIcon } from '../../Ui/Icons';
import '../../Ui/Buttons/Button.css';

/**
 * Show loading message.
 *
 * @return {object}s
 */
const Loading = () => (
    <tr>
        <td colSpan={100} className="no-results">
            <IonIcon icon="hourglass" hasText />
            Loading
        </td>
    </tr>
);

export default Loading;
