/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { find } from 'lodash';
import Renderers from './Renderers';

/**
 * Render value by type.
 *
 * @param {*} subject
 * @param {object} definition
 * @param {string} name
 * @param {object} context
 * @param {array} renderers
 *
 * @returns {object}
 */
const render = (subject, definition, name, context, renderers = []) => {
    // Push custom renderers, passed by components,
    // to the the beginning of the renderers to
    // make sure they are processed first.
    Renderers.unshift(...renderers);

    const picked = find(Renderers, renderer => renderer.isCandidate(subject, definition, name, context));

    return picked
        ? picked.render(subject, definition, name)
        : subject;
};

export default render;
