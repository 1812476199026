/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { setEnvironment, userLogin, userLogout } from './reducers/authentication/actions';
import { getFreshSchema } from './core/JsonSchema';
import EnvironmentStorage from './core/Environment/EnvironmentStorage';
import { receiveSchemas } from './reducers/schema/actions';
import { resetRules } from './reducers/filter/actions';
import logo from './assets/alumio-horizontal.svg';
import loading from './assets/loading.svg';
import { isLoginEnabled } from './core/Auth';
import EnvironmentDetector from './core/Environment/EnvironmentDetector';

class Wrapper extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            booted: false,
            hasError: false,
        };
    }

    /**
     * Prepare the application.
     */
    componentDidMount() {
        const environment = this.getCurrentEnvironment();

        // Nothing has to be setup when no active environment is selected.
        if (environment === undefined) {
            this.setState({
                booted: true,
            });

            return;
        }

        this.props.dispatch(
            setEnvironment(environment),
        );

        this.props.dispatch(resetRules());

        try {
            this.refreshApiSchema(environment)
                .then((schemas) => {
                    if (schemas) {
                        this.props.dispatch(receiveSchemas(...schemas));
                    }
                })
                .then(() => this.getCurrentUser())
                .then((user) => {
                    this.props.dispatch(userLogin(user));
                })
                .catch(() => {
                    this.props.dispatch(userLogout());
                })
                .finally(() => {
                    this.setState({
                        booted: true,
                    });
                });
        } catch (ex) {
            this.props.dispatch(userLogout());

            this.setState({
                booted: true,
            });
        }
    }

    /**
     * Get current environment.
     *
     * @returns {string|undefined}
     */
    getCurrentEnvironment() {
        const current = EnvironmentDetector.getEnvironmentFromUrl();

        if (!current) {
            return this.props.environment || undefined;
        }

        return current === 'login'
            ? undefined
            : current;
    }

    /**
     * Get the current logged in user from Magement.
     *
     * @returns {Promise}
     */
    getCurrentUser() {
        if (!isLoginEnabled()) {
            return Promise.resolve({ username: 'guest@mediact.nl' });
        }

        return fetch(`${EnvironmentStorage.getCurrentUrl()}/api/v1/users/current`, { credentials: 'include' })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Authentication required');
                }

                return response.json();
            });
    }

    /**
     * Get the current environment from the URL.
     *
     * @returns {string}
     */
    getEnvironmentFromUrl() {
        const pieces = window.location.pathname.split('/');

        return pieces[1];
    }

    /**
     * Whether the environment has changed.
     *
     * @returns {boolean}
     */
    isChangedEnvironment() {
        return this.getEnvironmentFromUrl() !== this.props.environment;
    }

    /**
     * Get fresh copy of schema.
     *
     * @param {string} environment
     *
     * @returns {Promise}
     */
    refreshApiSchema(environment) {
        // If there is no environment available the schemas cannot be fetched.
        if (!this.isValidEnvironment(environment)) {
            return Promise.resolve(false);
        }

        const baseUrl = EnvironmentStorage.getUrl(environment);
        const promises = [
            getFreshSchema(baseUrl, 'schema'),
            getFreshSchema(baseUrl, 'ui-schema'),
        ];

        return Promise.all(promises);
    }

    /**
     * Whether the environment is valid.
     *
     * @param environment
     *
     * @returns {boolean}
     */
    isValidEnvironment(environment) {
        return (environment !== 'login' && environment);
    }

    /**
     * Render component.
     *
     * @returns {any}
     */
    render() {
        if (this.state.hasError) {
            return (
                <div className="wrapper-page">
                    <div className="account-pages">
                        <div className="account-box">
                            <div className="account-logo-box pt-4">
                                <img
                                    src={logo}
                                    width="275"
                                    alt="Alumio logo"
                                />
                            </div>
                            <div className="account-content">
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        An error occurred while loading the application:
                                        <br />
                                        { this.state.errorMessage }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.state.booted
            ? this.props.children
            : (
                <div className="loading">
                    <img src={loading} width="75" height="75" alt="Loading" />
                </div>
            );
    }
}

const mapStateToProps = state => ({
    schema: state.schema.schema,
    environment: state.authentication.environment,
});

export default connect(mapStateToProps)(Wrapper);
