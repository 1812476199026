/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import Swagger from 'swagger-client';
import { isEmpty } from 'lodash';
import { isLoginEnabled } from '../Auth';
import store from '../../store';
import { USER_LOGOUT } from '../../reducers/authentication/actions';
import EnvironmentStorage from '../Environment/EnvironmentStorage';

if (isLoginEnabled()) {
    Swagger.http.withCredentials = true;
}

class HttpClient {
    static get schema() {
        return store.getState().schema.schema;
    }

    /**
     * Perform API request.
     *
     * @param {string} operationId
     * @param {object} parameters
     * @param {object} requestBody
     * @param {boolean} notifyStore
     *
     * @returns {Promise}
     */
    static request(operationId, parameters = {}, requestBody = {}, notifyStore = true) {
        try {
            const config = {
                contextUrl: EnvironmentStorage.getCurrentUrl(),
                spec: HttpClient.schema,
                operationId,
                parameters,
                requestBody: HttpClient.getRequestBody(requestBody),
                responseInterceptor: (response) => {
                    if (!response.ok && response.status === 401) {
                        HttpClient.dispatchEvent({ type: USER_LOGOUT }, notifyStore);
                    }
                },
            };

            HttpClient.dispatchEvent({ type: 'REQUEST_START' }, notifyStore);

            const request = Swagger.execute(config);
            return request
                .then((response) => {
                    HttpClient.dispatchEvent({ type: 'REQUEST_FINISHED' }, notifyStore);

                    // Support legacy and latest version of mediact/openapi-bundle
                    // by converting the metadata from snake case to camel case.
                    const result = JSON.parse(response.data);

                    if (result.metadata && result.metadata.total_count) {
                        result.metadata.totalCount = result.metadata.total_count;
                        delete result.metadata.total_count;
                    }

                    return result;
                })
                .catch((error) => {
                    const message = HttpClient.getErrorMessage(error);

                    HttpClient.dispatchEvent({
                        type: 'REQUEST_FAILED',
                        message,
                    }, notifyStore);

                    HttpClient.dispatchEvent({
                        type: 'ADD_NOTIFICATION',
                        message,
                    }, notifyStore);

                    throw new Error(message);
                });
        } catch (ex) {
            store.dispatch({ type: USER_LOGOUT });

            return Promise.reject(
                new Error('Authentication required'),
            );
        }
    }

    /**
     * Perform API call without notifying the store.
     *
     * @param {string} operationId
     * @param {object} parameters
     * @param {object} requestBody
     *
     * @return Promise
     */
    static backgroundRequest(operationId, parameters = {}, requestBody = {}) {
        return HttpClient.request(operationId, parameters, requestBody, false);
    }

    /**
     * Dispatch events.
     *
     * @param event
     * @param notifyStore
     */
    static dispatchEvent(event, notifyStore) {
        if (notifyStore) {
            store.dispatch(event);
        }
    }

    /**
     * Get enum resource.
     *
     * @param {string} operationId
     * @param {string} subject
     * @param {string} name
     *
     * @returns {Promise}
     */
    static enumResource(operationId, subject, name) {
        return HttpClient.backgroundRequest(operationId)
            .then((response) => {
                return {
                    target: name,
                    label: subject.enumResource.label,
                    value: subject.enumResource.value,
                    data: response.results,
                };
            });
    }

    /**
     * Perform an API request.
     *
     * @param {string} operationId
     * @param {string} subject
     * @param {string} name
     *
     * @returns {Promise}
     */
    static schemaResource(operationId, subject, name) {
        return HttpClient.request(operationId)
            .then((response) => {
                return {
                    target: name,
                    value: subject.schemaResource.value,
                    data: response.results,
                };
            });
    }

    /**
     * Get request data.
     *
     * @param {object} data
     *
     * @return {object|null}
     */
    static getRequestBody(data) {
        return !isEmpty(data)
            ? data
            : null;
    }

    /**
     * Get error message from error response.
     *
     * @param {object} error
     *
     * @return {string}
     */
    static getErrorMessage(error) {
        if (error.response) {
            const data = JSON.parse(error.response.data);

            if (data.context.message) {
                return data.context.message;
            }
        }

        return error.message || 'Unknown API error';
    }
}

export default HttpClient;
