/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ClearFilterInline } from '../../Ui/Buttons';

const mapDispatchToProps = dispatch => ({
    onClearFilter: (id) => {
        dispatch({
            type: 'CLEAR_FILTER',
            id,
        });
    },
});

/**
 * Show no results message.
 *
 * @param {object} props
 *
 * @return {object}
 */
const NoResults = (props) => {
    const { id, onClearFilter } = props;

    return (
        <tr>
            <td colSpan={100} className="no-results">
                We could not find any records.
                <ClearFilterInline onClick={() => onClearFilter(id)} />
            </td>
        </tr>
    );
};

NoResults.propTypes = {
    id: PropTypes.string.isRequired,
};

export default connect(
    null,
    mapDispatchToProps,
)(NoResults);
