/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';
import './Button.css';

/**
 * Save button.
 *
 * @param bool isSubmitting
 *
 * @return {object}
 */
const SaveButton = ({
    message,
    isSubmitting,
    className,
    onClick,
}) => {
    const icon = !isSubmitting
        ? <IonIcon icon="save" />
        : <IonIcon icon="hourglass" />;

    return (
        <Button
            color="primary"
            disabled={isSubmitting}
            onClick={onClick}
            className={className}
        >
            {icon}
            {' '}
            {' '}
            { message }
        </Button>
    );
};

SaveButton.propTypes = {
    message: PropTypes.string,
    isSubmitting: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

SaveButton.defaultProps = {
    message: 'Save',
    isSubmitting: false,
    onClick: undefined,
    className: undefined,
};

export default SaveButton;
