/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import refParser from 'json-schema-ref-parser/lib/index';
import {
    has, pickBy, map, forEach,
} from 'lodash';
import HttpClient from '../HttpClient';
import Utils from '../Utils';
import store from '../../store';
import Normalizers from './Normalizers';
import { isLoginEnabled } from '../Auth';

const ERR_ENTITY_NOT_FOUND = 'entity not found';
const EMPTY_SCHEMA = { type: 'object', properties: {} };
const EMPTY_DEFINITION = { type: 'string' };
const SCHEMA_DEFAULTS = 'defaults';
const SCHEMA_PROPERTIES = 'properties';
const PARAMETERS_PROPERTY = 'parameters';
const PLUGINS_PROPERTY = 'plugins';
const AUTHENTICATION_PROPERTY = 'authentications';

// Default HTTP options to use for getting schemas.
const defaultConfig = { cache: 'no-store' };
if (isLoginEnabled()) {
    defaultConfig.credentials = 'include';
}

/**
 * Get entity schema.
 *
 * @param entity
 * @param schema
 *
 * @returns {{type: string, properties: {}}}
 */
const getEntitySchema = (entity, schema) => {
    if (schema === null) {
        return EMPTY_SCHEMA;
    }

    if (!has(schema.components.schemas, entity)) {
        throw new Error(`${ERR_ENTITY_NOT_FOUND}: ${entity}`);
    }

    return schema.components.schemas[entity];
};

/**
 * Get schema with enum resources.
 *
 * @param {object} uiSchema
 * @param {string} entity
 * @param {entitySchema} entitySchema
 *
 * @returns {Promise}
 */
const getEnumResources = (uiSchema, entity, entitySchema) => {
    const candidates = pickBy(
        uiSchema[entity],
        subject => has(subject, 'enumResource'),
    );

    const actions = map(candidates, (subject, name) => HttpClient.enumResource(
        subject.enumResource.operationId,
        subject,
        name,
    ));

    return Promise.all(actions)
        .then((data) => {
            const target = Utils.clone(entitySchema);
            data.forEach((candidate) => {
                if (!target.properties[candidate.target]) {
                    return;
                }

                const labels = candidate.data.map(row => row[candidate.label]);
                const values = candidate.data.map(row => row[candidate.value]);

                if (target.properties[candidate.target].type !== 'array') {
                    target.properties[candidate.target].enumNames = labels;
                    target.properties[candidate.target].enum = values;
                } else {
                    target.properties[candidate.target].items.enumNames = labels;
                    target.properties[candidate.target].items.enum = values;
                }
            });

            return target;
        })
        .catch(() => entitySchema);
};

/**
 * Dispatch event to update table in store.
 *
 * @param {string} id
 * @param {object} entitySchema
 *
 * @returns {object}
 */
const notifyStore = (id, entitySchema) => {
    /**
     * Dispatch the entity schema early in the process so
     * components can already be rendered. The components
     * will be updated once enum resources are added.
     */
    store.dispatch({
        type: 'EXPANDED_ENTITY_SCHEMA',
        id,
        entitySchema,
    });

    return entitySchema;
};

/**
 * Get entity definition.
 *
 * @param {object} schema
 * @param {string} name
 *
 * @return {object}
 */
const getDefinition = (schema, name) => (
    has(schema.properties, name)
        ? schema.properties[name]
        : EMPTY_DEFINITION
);

/**
 * Get definition by entity.
 *
 * @param {object} schema
 * @param {string} entity
 * @param {string} property
 *
 * @return {object}
 */
const getDefinitionByEntity = (schema, entity, property) => (
    has(schema, entity)
        ? getDefinition(schema[entity], property)
        : EMPTY_SCHEMA
);

/**
 * Normalize a schema.
 *
 * @param {object} schema
 *
 * @return {object}
 */
const normalize = (schema) => {
    const recursiveNormalize = (subject) => {
        forEach(subject, (property, key) => {
            if (has(property, SCHEMA_PROPERTIES)) {
                return recursiveNormalize(property[SCHEMA_PROPERTIES]);
            }

            forEach(Normalizers, (normalizer) => {
                let normalized = property;
                normalized = normalizer(property, key);

                return normalized;
            });

            return subject;
        });

        return schema;
    };

    return recursiveNormalize(schema.properties);
};

/**
 * Get expanded schema with enum resources.
 *
 * @param {string} id
 * @param {string} entity
 * @param {object} schema
 * @param {object} uiSchema
 *
 * @returns {Promise}
 */
const expandSchema = (id, entity, schema, uiSchema) => {
    const entitySchema = getEntitySchema(entity, schema);
    notifyStore(id, entitySchema);

    return getEnumResources(uiSchema, entity, entitySchema);
};

const getFreshSchema = (url, type) => {
    return fetch(`${url}/${type}.json`, defaultConfig)
        .then(response => response.json())
        .then((json) => {
            return refParser.dereference(json);
        });
};

export default expandSchema;
export {
    getEntitySchema,
    getEnumResources,
    getDefinition,
    getDefinitionByEntity,
    getFreshSchema,
    normalize,
    ERR_ENTITY_NOT_FOUND,
    EMPTY_SCHEMA,
    EMPTY_DEFINITION,
    SCHEMA_DEFAULTS,
    SCHEMA_PROPERTIES,
    PARAMETERS_PROPERTY,
    PLUGINS_PROPERTY,
    AUTHENTICATION_PROPERTY,
};
