/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import moment from 'moment/moment';

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY [at] HH:mm';

/**
 * Format a given date.
 *
 * @param {string} date
 *
 * @returns {string}
 */
const formatDate = (date) => {
    return date
        ? moment(date).format(DEFAULT_DATE_FORMAT)
        : '-';
};

/**
 * Format a given date including time.
 *
 * @param {string} date
 *
 * @returns {string}
 */
const formatDatetime = (date) => {
    return date
        ? moment(date).format(DEFAULT_DATETIME_FORMAT)
        : '-';
};

/**
 * Format a given date into a human readable string.
 *
 * @param {string} date
 *
 * @returns {string}
 */
const formatHumanReadableDate = (date) => {
    return date
        ? moment(date).calendar(null, {
            sameDay: '[Today] [at] HH:mm',
            sameElse: DEFAULT_DATE_FORMAT,
            lastDay: '[Yesterday] [at] HH:mm',
            lastWeek: '[Last] dddd [at] HH:mm',
        })
        : '-';
};

export {
    formatDate,
    formatDatetime,
    formatHumanReadableDate,
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATETIME_FORMAT,
};
