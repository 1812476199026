/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import './Pagination.css';

const mapDispatchToProps = dispatch => ({
    onIncrementPage: (id) => {
        dispatch({
            type: 'INCREMENT_PAGE',
            id,
        });
    },
    onDecrementPage: (id) => {
        dispatch({
            type: 'DECREMENT_PAGE',
            id,
        });
    },
    onPageChange: (id, page) => {
        dispatch({
            type: 'CHANGE_PAGE',
            page,
            id,
        });
    },
    onChangeLimit: (id, limit) => {
        dispatch({
            type: 'CHANGE_LIMIT',
            limit,
            id,
        });
    },
});

const isMaxDisabled = (page, numItems, limit) => {
    const max = Math.ceil((numItems / limit));
    return page >= max;
};

/**
 * Show pagination buttons.
 *
 * @param {object} table
 * @param {function} onDecrementPage
 * @param {function} onIncrementPage
 * @param {function} onPageChange
 *
 * @return {object}
 */
const Pagination = ({
    table, onDecrementPage, onIncrementPage, onPageChange,
}) => (
    <div className="pagination">
        <button
            type="button"
            disabled={(table.page <= 1)}
            className="btn btn-sm btn-outline-secondary"
            onClick={() => onDecrementPage(table.id)}
        >
                «
        </button>
        <input
            type="text"
            className="form-control current-page"
            value={table.page}
            onChange={
                event => onPageChange(
                    table.id,
                    event.target.value,
                )
            }
        />
        <button
            type="button"
            disabled={isMaxDisabled(table.page, table.numberOfItems, table.limit)}
            className="btn btn-sm btn-outline-secondary"
            onClick={() => onIncrementPage(table.id)}
        >
                »
        </button>
    </div>
);

export default connect(
    null,
    mapDispatchToProps,
)(Pagination);
