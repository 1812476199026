/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

const AvailableTransformers = [
    {
        name: 'Transformers',
        description: '',
        tag: 'Transformers',
        operationId: 'getTransformerTypes',
    },
    {
        name: 'Data Filters',
        description: '',
        tag: 'Transformers',
        operationId: 'getDataFilterTypes',
    },
    {
        name: 'Data Transformers',
        description: '',
        tag: 'Transformers',
        operationId: 'getDataTransformerTypes',
    },
    {
        name: 'Comparators',
        description: '',
        tag: 'Transformers',
        operationId: 'getComparators',
    },
    {
        name: 'Conditions',
        description: '',
        tag: 'Transformers',
        operationId: 'getConditions',
    },
    {
        name: 'Mappers',
        description: '',
        tag: 'Transformers',
        operationId: 'getMappers',
    },
    {
        name: 'Accessors',
        description: '',
        tag: 'Transformers',
        operationId: 'getAccessors',
    },
    {
        name: 'Operators',
        description: '',
        tag: 'Operators',
        operationId: 'getOperators',
    },
];

export default AvailableTransformers;
