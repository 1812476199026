/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type } from '../Magement';
import ObjectTester from '../Magement/ObjectTester';
import Route from './PrivateRoute';

const typeBasePath = '/:environment/pdo-clients/types';
const configBasePath = '/:environment/pdo-clients/configurations';

/**
 * Route definitions.
 *
 * @return {object}
 */
const PdoClients = () => (
    <Switch>
        <Route
            exact
            path={typeBasePath}
            render={() => (
                <Type.Overview
                    id="PdoClientTypes"
                    tag="PdoClients"
                    operationId="getPdoClientTypes"
                />
            )}
        />
        <Route
            exact
            path={`${typeBasePath}/:identifier`}
            render={
                props => (
                    <Type.Details
                        configuration={{
                            id: 'PdoClientTypes',
                            tag: 'PdoClients',
                            operationId: 'getPdoClientConfigurations',
                        }}
                        tag="PdoClients"
                        operationIds={{
                            read: 'getPdoClientType',
                        }}
                        entity="ConfigurationType"
                        params={{ item: props.match.params.identifier }}
                    />
                )
            }
        />
        <Route
            exact
            path={configBasePath}
            render={() => (
                <Configuration.Overview
                    id="PdoClientConfigurations"
                    tag="PdoClients"
                    operationId="getPdoClientConfigurations"
                />
            )}
        />
        <Route
            exact
            path={`${configBasePath}/create`}
            render={
                () => (
                    <Configuration.Create
                        tag="PdoClients"
                        operationIds={{
                            create: 'createPdoClientConfiguration',
                            read: 'getPdoClientConfiguration',
                            update: 'updatePdoClientConfiguration',
                            delete: 'deletePdoClientConfiguration',
                        }}
                        entity="PdoClientConfiguration"
                        basePath={configBasePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${configBasePath}/:identifier`}
            render={
                props => (
                    <Configuration.Details
                        tag="PdoClients"
                        operationIds={{
                            create: 'createPdoClientConfiguration',
                            read: 'getPdoClientConfiguration',
                            update: 'updatePdoClientConfiguration',
                            delete: 'deletePdoClientConfiguration',
                        }}
                        entity="PdoClientConfiguration"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={configBasePath}
                        actions={[
                            <ObjectTester
                                params={{ connection: props.match.params.identifier }}
                                tag="PdoClients"
                                operationId="getPdoClientTest"
                                label="Test connection"
                                labelError="Connection failed"
                                labelSuccess="Connection OK"
                            />,
                        ]}
                    />
                )
            }
        />
    </Switch>
);

export default PdoClients;
