/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';
import './Button.css';

/**
 * Show button to refresh data.
 *
 * @param {function} onClick
 * @param {string} color
 * @param {boolean} outline
 * @param {boolean} disabled
 *
 * @return {object}
 */
const RefreshButton = ({
    onClick,
    color,
    outline,
    disabled,
}) => (
    <Button outline={outline} color={color} onClick={() => onClick()} disabled={disabled}>
        <IonIcon icon="refresh" hasText={false} />
    </Button>
);

RefreshButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    outline: PropTypes.bool,
    disabled: PropTypes.bool,
};

RefreshButton.defaultProps = {
    color: 'secondary',
    outline: true,
    disabled: false,
};

export default RefreshButton;
