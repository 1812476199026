/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { enumRenderer } from '../../../core/Ui/Renderers';
import MenuItems from '../../Routes/MenuItems';
import Link from '../../Routes/PrefixedLink';

/**
 * Render an url.
 *
 * @param {string} name
 * @param {string} subject
 *
 * @return {string|boolean}
 */
const getUrl = (name, subject) => {
    switch (name) {
        case 'subscriber':
            return `${MenuItems.subscribersConfiguration.url}/${subject}`;
        case 'publisher':
            return `${MenuItems.publishersConfiguration.url}/${subject}`;
        case 'remote':
            return `${MenuItems.remotes.url}/${subject}`;
        default:
            return false;
    }
};


/**
 * Render value wrapped in link for quick access to detail page.
 *
 * @param {string} subject
 * @param {string} definition
 * @param {string} name
 *
 * @return {object}
 */
const LinkRenderer = {
    isCandidate: (subject, definition, name) => {
        const checkList = ['subscriber', 'publisher', 'remote'];

        return checkList.indexOf(name) !== -1;
    },
    render: (subject, definition, name) => {
        const value = enumRenderer.isCandidate(subject, definition)
            ? enumRenderer.render(subject, definition) : subject;

        const url = getUrl(name, subject);

        return <>{url !== false ? (<Link to={url} className="text-success">{value}</Link>) : (value)}</>;
    },
};

export default LinkRenderer;
