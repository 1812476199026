/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import HttpClient from '../../../core/HttpClient';

class SelectContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
        };
    }

    /**
     * Prepare component.
     */
    componentDidMount() {
        this.getApiData();
    }

    /**
     * Get API data based on operation id.
     */
    getApiData() {
        const { operationId } = this.props;

        HttpClient.request(operationId)
            .then((response) => {
                this.setState({
                    options: response.results,
                });
            });
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        const options = this.state.options.map(option => ({
            value: option[this.props.valueProperty],
            label: option[this.props.labelProperty],
        }));

        return (
            <Select
                placeholder={this.props.label}
                value={this.props.selectedOption}
                options={options}
                onChange={this.props.onSelect}
            />
        );
    }
}

SelectContainer.propTypes = {
    operationId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedOption: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
    ]),
    valueProperty: PropTypes.string,
    labelProperty: PropTypes.string,

};

SelectContainer.defaultProps = {
    selectedOption: null,
    valueProperty: 'identifier',
    labelProperty: 'name',
};

export default SelectContainer;
