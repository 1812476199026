/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';

const Encryptable = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    return props.formContext.data.encrypted
        ? (
            <input
                type="password"
                className="form-control"
                onChange={handleChange}
                required={props.required}
            />
        )
        : (
            <input
                type="text"
                className="form-control"
                onChange={handleChange}
                value={props.value}
                required={props.required}
            />
        );
};

export default Encryptable;
