/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import SwapButton from '../../../../Ui/Buttons/SwapButton';
import { swapKeyMapping } from '../../../../../reducers/mapping/actions';

const Swap = ({ isSubmitting, dispatch }) => {
    const onClick = () => dispatch(swapKeyMapping());

    return (
        <SwapButton
            isSubmitting={isSubmitting}
            onClick={onClick}
        />
    );
};

export default connect()(Swap);
