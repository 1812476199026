/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PageHeader from '../../../Ui/PageHeader';
import ValueMappings from './ValueMappings';
import {
    getMappingValues,
} from '../../../../reducers/mapping/actions';

const mapStateToProps = state => ({
    mapping: state.mapping,
    api: state.api,
});

class ValueMapping extends React.Component {
    componentDidMount() {
        this.props.dispatch(
            getMappingValues(),
        );
    }

    /**
     * Render components.
     *
     * @returns {*}
     */
    render() {
        const {
            type, source, destination, mappingValues, mappingValueSuggestions,
        } = this.props.mapping;

        const { mappingKey, remoteKey } = this.props.mapping.currentMappingKey;


        const mapping = mappingValues.length > 0
            ? (
                <ValueMappings
                    type={type}
                    source={source}
                    destination={destination}
                    data={mappingValues}
                    suggestions={mappingValueSuggestions}
                    mappingKey={mappingKey}
                    remoteKey={remoteKey}
                />
            )
            : <p>Select options from the menu above to begin.</p>;

        return (
            <div>
                <PageHeader title="Mapping" />
                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-12">
                                { mapping }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ValueMapping);
