/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { forEach, isObject } from 'lodash';

class ColumnParser {
    /**
     * Format a schema to render a column selector.
     *
     * @param {object} schema
     *
     * @return {object}
     */
    static parse(schema) {
        const columnSchema = {
            type: 'object',
            properties: {},
        };

        forEach(schema.properties, (definition, key) => {
            columnSchema.properties[key] = {
                type: 'boolean',
            };
        });

        return columnSchema;
    }

    /**
     * Clean up object by removing undefined properties.
     *
     * @param {object} columns
     */
    static stripUndefined(columns) {
        const target = columns;
        Object.keys(target).forEach((column) => {
            if (Object.prototype.hasOwnProperty.call(target, column)) {
                if (isObject(target[column])) {
                    ColumnParser.stripUndefined(columns[column]);
                } else if (typeof target[column] === 'undefined') {
                    delete target[column];
                }
            }
        });
    }
}

export default ColumnParser;
