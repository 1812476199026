/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import Table from '../../Table';
import { encryptedRenderer, variableRenderer, fieldRendererFactory } from '../../../core/Ui/Renderers';
import ActionColumn from '../../Table/ActionColumn';
import Delete from '../Entity/Delete';
import FeatureDetect from '../../../core/FeatureDetect';

const mapDispatchToProps = dispatch => ({
    onDelete: (id, row) => {
        dispatch({
            type: 'ON_DELETE',
            row,
            id,
        });
    },
});

const mapStateToProps = state => ({
    schema: state.schema.schema,
});

const renderers = [
    fieldRendererFactory(encryptedRenderer, 'value'),
    fieldRendererFactory(variableRenderer, 'name'),
];

const TableEnvironmentVariables = (props) => {
    const FeatureDetection = new FeatureDetect(props.schema);

    return (
        <Table
            id="EnvironmentVariables"
            tag="Environment"
            title="Environment variables"
            operationId="getEnvironmentVariables"
            table={props.table}
            showHeader={false}
            renderers={renderers}
            viewAction={
                (row) => { return `${props.basePath}/${row.name}`; }
            }
            actionsColumn={FeatureDetection.hasOperationId('deleteEnvironmentVariable')
                ? row => (
                    <ActionColumn>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <Delete
                                    inline
                                    tag="Environment"
                                    operationId="deleteEnvironmentVariable"
                                    onSuccess={() => props.onDelete(
                                        props.table.id,
                                        row,
                                    )}
                                    baseUrl={props.basePath}
                                    params={{ variable: row.name }}
                                />
                            </li>
                        </ul>
                    </ActionColumn>
                )
                : false}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TableEnvironmentVariables);
