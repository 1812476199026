/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import NumberOfTasksWidget from '../NumberOfTasksWidget';
import Widget from '../index';
import './RealtimeStats.scss';

const RealtimeStats = () => {
    return (
        <div className="realtime-stats">
            <Widget className="bg-info text-white" icon="new">
                <NumberOfTasksWidget identifier="new" title="New tasks" />
            </Widget>
            <Widget className="bg-warning text-white" icon="processing">
                <NumberOfTasksWidget identifier="processing" title="Currently processing" />
            </Widget>
            <Widget className="bg-danger text-white" icon="cross">
                <NumberOfTasksWidget identifier="stuck" title="Stuck tasks" />
            </Widget>
        </div>
    );
};

export default RealtimeStats;
