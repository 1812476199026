/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Activity,
    Home,
    UploadCloud,
    Sliders,
    Info,
    Type,
} from 'react-feather';
import Config from '../../core/Config';
import './Sidebar.css';
import MenuItems from '../Routes/MenuItems';
import FeatureDetect from '../../core/FeatureDetect';
import Link from '../Routes/PrefixedLink';

const mapStateToProps = state => ({
    schema: state.schema.schema,
    menuItems: state.navigation.menuItems,
    enlarged: state.navigation.enlarged,
});

const mapDispatchToProps = dispatch => ({
    onToggle: itemId => (
        dispatch({
            type: 'TOGGLE_MENU_ITEM',
            itemId,
        })
    ),
});

const { iconSize } = Config.ui;

/**
 * Get css class of menu item.
 *
 * @param {string} item
 * @param {object} menuItems
 * @param {bool} enlarged
 *
 * @return {string}
 */
const getSubMenuCssClass = (item, menuItems, enlarged) => (
    // When in responsive mode (!enlarged)
    // the sub menu should never show.
    (!enlarged && menuItems[item.id] === true)
        ? 'show'
        : ''
);

class Sidebar extends Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        const FeatureDetection = new FeatureDetect(this.props.schema);

        this.links = {
            main: [
                {
                    id: MenuItems.dashboard.id,
                    label: MenuItems.dashboard.label,
                    icon: <Home size={iconSize} className="feather" />,
                    url: MenuItems.dashboard.url,
                    isVisible: true,
                },
                {
                    id: MenuItems.tasksQueue.id,
                    label: MenuItems.tasksQueue.label,
                    icon: <Activity size={iconSize} className="feather" />,
                    url: MenuItems.tasksQueue.url,
                    isVisible: FeatureDetection.hasOperationId(MenuItems.tasksQueue.operationId),
                },
                {
                    id: MenuItems.webhooks.id,
                    label: MenuItems.webhooks.label,
                    icon: <UploadCloud size={iconSize} className="feather" />,
                    url: MenuItems.webhooks.url,
                    isVisible: FeatureDetection.hasOperationId(MenuItems.webhooks.operationId),
                },
            ],
            secondary: [
                {
                    id: 'configurations',
                    label: 'Configuration',
                    icon: <Sliders size={iconSize} className="feather" />,
                    isVisible: true,
                    children: [
                        {
                            id: MenuItems.routesConfigurations.id,
                            label: MenuItems.routesConfigurations.label,
                            url: MenuItems.routesConfigurations.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.routesConfigurations.operationId),
                        },
                        {
                            id: MenuItems.mapping.id,
                            label: MenuItems.mapping.label,
                            url: MenuItems.mapping.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.mapping.operationId),
                        },
                        {
                            id: MenuItems.remotes.id,
                            label: MenuItems.remotes.label,
                            url: MenuItems.remotes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.remotes.operationId),
                        },
                        {
                            id: MenuItems.subscribersConfiguration.id,
                            label: MenuItems.subscribersConfiguration.label,
                            url: MenuItems.subscribersConfiguration.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.subscribersConfiguration.operationId),
                        },
                        {
                            id: MenuItems.publishersConfiguration.id,
                            label: MenuItems.publishersConfiguration.label,
                            url: MenuItems.publishersConfiguration.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.publishersConfiguration.operationId),
                        },
                        {
                            id: MenuItems.transformersConfigurations.id,
                            label: MenuItems.transformersConfigurations.label,
                            url: MenuItems.transformersConfigurations.url,
                            isVisible: FeatureDetection.hasOperationId(
                                MenuItems.transformersConfigurations.operationId,
                            ),
                        },
                        {
                            id: MenuItems.filesystemsConfigurations.id,
                            label: MenuItems.filesystemsConfigurations.label,
                            url: MenuItems.filesystemsConfigurations.url,
                            isVisible: (
                                FeatureDetection.hasOperationId(
                                    MenuItems.filesystemsConfigurations.operationId,
                                )
                                || FeatureDetection.hasModelDefinition('DiObjectConfiguration')
                            ),
                        },
                        {
                            id: MenuItems.httpClientsConfigurations.id,
                            label: MenuItems.httpClientsConfigurations.label,
                            url: MenuItems.httpClientsConfigurations.url,
                            isVisible: (
                                FeatureDetection.hasOperationId(
                                    MenuItems.httpClientsConfigurations.operationId,
                                )
                                || FeatureDetection.hasModelDefinition('DiObjectConfiguration')
                            ),
                        },
                        {
                            id: MenuItems.pdoClientsConfigurations.id,
                            label: MenuItems.pdoClientsConfigurations.label,
                            url: MenuItems.pdoClientsConfigurations.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.pdoClientsConfigurations.operationId),
                        },
                        {
                            id: MenuItems.storagesConfigurations.id,
                            label: MenuItems.storagesConfigurations.label,
                            url: MenuItems.storagesConfigurations.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.storagesConfigurations.operationId,
                                MenuItems.storagesConfigurations.tags),
                        },
                        {
                            id: MenuItems.environmentVariables.id,
                            label: MenuItems.environmentVariables.label,
                            url: MenuItems.environmentVariables.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.environmentVariables.operationId),
                        },
                        {
                            id: MenuItems.flagList.id,
                            label: MenuItems.flagList.label,
                            url: MenuItems.flagList.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.flagList.operationId),
                        },
                        {
                            id: MenuItems.userConfiguration.id,
                            label: MenuItems.userConfiguration.label,
                            url: MenuItems.userConfiguration.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.userConfiguration.operationId),
                        },
                    ],
                },
                {
                    id: 'types',
                    label: 'Types',
                    icon: <Type size={iconSize} className="feather" />,
                    isVisible: true,
                    children: [
                        {
                            id: MenuItems.entitiesTypes.id,
                            label: MenuItems.entitiesTypes.label,
                            url: MenuItems.entitiesTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.entitiesTypes.operationId),
                        },
                        {
                            id: MenuItems.subscribersTypes.id,
                            label: MenuItems.subscribersTypes.label,
                            url: MenuItems.subscribersTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.subscribersTypes.operationId),
                        },
                        {
                            id: MenuItems.publishersTypes.id,
                            label: MenuItems.publishersTypes.label,
                            url: MenuItems.publishersTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.publishersTypes.operationId),
                        },
                        {
                            id: MenuItems.transformersTypes.id,
                            label: MenuItems.transformersTypes.label,
                            url: MenuItems.transformersTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.transformersTypes.operationId),
                        },
                        {
                            id: MenuItems.filesystemsTypes.id,
                            label: MenuItems.filesystemsTypes.label,
                            url: MenuItems.filesystemsTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.filesystemsTypes.operationId),
                        },
                        {
                            id: MenuItems.httpClientsTypes.id,
                            label: MenuItems.httpClientsTypes.label,
                            url: MenuItems.httpClientsTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.httpClientsTypes.operationId),
                        },
                        {
                            id: MenuItems.pdoClientsTypes.id,
                            label: MenuItems.pdoClientsTypes.label,
                            url: MenuItems.pdoClientsTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.pdoClientsTypes.operationId),
                        },
                        {
                            id: MenuItems.storagesTypes.id,
                            label: MenuItems.storagesTypes.label,
                            url: MenuItems.storagesTypes.url,
                            isVisible: FeatureDetection.hasOperationId(MenuItems.storagesTypes.operationId),
                        },
                    ],
                },
                {
                    id: 'documentation',
                    label: 'Documentation',
                    icon: <Info size={iconSize} className="feather" />,
                    isVisible: true,
                    children: [{
                        id: MenuItems.documentationTransformers.id,
                        label: MenuItems.documentationTransformers.label,
                        url: MenuItems.documentationTransformers.url,
                        isVisible: true,
                    }],
                },
            ],
        };
    }

    /**
     * When a user clicks the collapse button.
     *
     * @param {object} event
     * @param {object} item
     *
     * @return {void}
     */
    onClick(event, item) {
        event.preventDefault();

        this.props.onToggle(item.id);
    }

    /**
     * Render sidebar.
     *
     * @returns {object}
     */
    render() {
        const mainItems = this.links.main.map((item) => {
            const cssClass = this.props.location.pathname === item.url ? 'active' : '';
            return item.isVisible
            && (
                <li key={item.id}>
                    <Link className={`nav-link ${cssClass}`} to={`${item.url}`}>
                        {item.icon}
                        <span>{item.label}</span>
                    </Link>
                </li>
            );
        });

        const secondaryItems = this.links.secondary.map((item, index) => {
            return item.isVisible
            && (
                <li key={`menu-${item.id}`}>
                    <a
                        className="clickable"
                        href={item.url}
                        onClick={event => this.onClick(event, item)}
                        tabIndex={index}
                    >
                        {item.icon}
                        <span>{item.label}</span>
                        <span className="menu-arrow" />
                    </a>
                    <ul
                        className={`nav-second-level collapse ${getSubMenuCssClass(
                            item,
                            this.props.menuItems,
                            this.props.enlarged,
                        )}`}
                    >
                        {
                            item.children.map((child) => {
                                return child.isVisible
                                && (
                                    <li key={`menu-${child.id}`}>
                                        <Link
                                            key={child.id}
                                            to={child.url}
                                        >
                                            {child.label}
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </li>
            );
        });

        return (
            <ul className="metismenu in" id="side-menu">
                <li className="menu-title">Navigation</li>
                {mainItems}
                {secondaryItems}
            </ul>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Sidebar));
