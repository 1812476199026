/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import Source from '../Source';
import Destination from '../Destination';
import PendingChanges from './Actions/PendingChanges';
import Legend from '../Legend';
import MappingStatus, { MappingNames } from '../MappingStatus';
import Help from '../Help';
import MappingFilterType from '../MappingTypeFilter';
import ErrorMessage from '../../../Ui/Messages/ErrorMessage';
import Actions from './Actions';
import {
    getCurrentValue,
    getStatus,
    isChanged,
    isMapped,
    isDeleted,
} from '../Common/Functions';
import { addMappingValue } from '../../../../reducers/mapping/actions';
import '../Mapping.scss';

const mapStateToProps = state => ({
    valueMapping: state.mapping.valueMapping,
    valueDeletions: state.mapping.valueDeletions,
    api: state.api,
});

class ValueMappings extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            filter: [
                MappingStatus.MAPPING_MAPPED,
                MappingStatus.MAPPING_MATCHED,
                MappingStatus.MAPPING_UNMAPPED,
            ],
            hasError: false,
            errorMessage: undefined,
            keyFilter: '',
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleKeyFilterChange = this.handleKeyFilterChange.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    /**
     * Get filtered data.
     *
     * @param {array} data
     * @param {array} searchable
     *
     * @returns {array}
     */
    getFilteredData(data, searchable) {
        const { valueMapping, valueDeletions } = this.props;

        return data.filter((subject) => {
            // Always show changed items.
            if (isChanged(valueMapping, subject.id) || isDeleted(valueDeletions, subject.id)) {
                return true;
            }

            const status = getStatus(valueMapping, valueDeletions, subject);

            return this.state.filter.indexOf(status) !== -1;
        }).filter((subject) => {
            if (!this.state.keyFilter) {
                return true;
            }

            return searchable.reduce((carry, key) => (
                carry
                    ? true
                    : subject[key].toLowerCase().indexOf(this.state.keyFilter.toLowerCase()) !== -1
            ), false);
        });
    }

    /**
     * Set the active filters.
     *
     * @param {array} filter
     */
    handleFilterChange(filter) {
        this.setState({
            filter,
        });
    }

    /**
     * Handle key filter change.
     *
     * @param {string} keyFilter
     */
    handleKeyFilterChange(keyFilter) {
        this.setState({
            keyFilter,
        });
    }

    /**
     * Close the error message.
     */
    handleErrorClose() {
        this.setState({
            hasError: false,
        });
    }

    /**
     * Render component.
     *
     * @returns {object}
     */
    render() {
        const {
            data,
            suggestions,
            source,
            destination,
            valueMapping,
            valueDeletions,
        } = this.props;

        const filteredData = this.getFilteredData(
            data,
            ['localValue', 'remoteValue'],
        );

        return (
            <div>
                <div className="row">
                    <div className="col-md-8 mapping-filter">
                        <MappingFilterType
                            data={this.state.filter}
                            items={MappingNames}
                            onChange={this.handleFilterChange}
                            filter={this.state.keyFilter}
                            onFilter={this.handleKeyFilterChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className="float-right mb-3">
                            <PendingChanges
                                keyMapping={valueMapping}
                                deletions={valueDeletions}
                                isSubmitting={this.props.api.isRequesting}
                            />
                            <Help>
                                <Legend />
                            </Help>
                        </div>
                    </div>
                </div>

                <ErrorMessage
                    isOpen={this.state.hasError}
                    message={this.state.errorMessage}
                    onClose={this.handleErrorClose}
                />

                <Table className="mapping-table" striped>
                    <thead>
                        <tr>
                            <th className="source">{ `${this.props.mappingKey} (${source.label})` }</th>
                            <th className="destination">{ `${this.props.remoteKey} (${destination.label})` }</th>
                            <th className="actions" />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.map((row) => {
                                const currentValue = getCurrentValue(valueMapping, valueDeletions, row);
                                const currentStatus = getStatus(valueMapping, valueDeletions, row);

                                return (
                                    <tr key={`mapping-${row.id}`}>
                                        <td className="source">
                                            <Source
                                                id={row.id}
                                                remoteValue={row.remoteValue}
                                                localValue={row.localValue}
                                                mapped={row.mapped}
                                                matched={row.matched}
                                            />
                                        </td>
                                        <td className="destination">
                                            <Destination
                                                currentValue={currentValue}
                                                from={row.id}
                                                options={suggestions}
                                                status={currentStatus}
                                                onChange={addMappingValue}
                                            />
                                        </td>
                                        <td className="actions">
                                            <Actions
                                                row={row}
                                                currentStatus={currentStatus}
                                                currentValue={currentValue}
                                                isMapped={isMapped(valueMapping, valueDeletions, row)}
                                                isDeleted={isDeleted(valueDeletions, row.id)}
                                                isChanged={isChanged(valueMapping, row.id)}
                                                type={this.props.type}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }

                        {
                            filteredData.length === 0
                                && (
                                    <tr>
                                        <td colSpan="3">
                                            No results found.
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

ValueMappings.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    suggestions: PropTypes.arrayOf(PropTypes.object),
    valueMapping: PropTypes.objectOf(PropTypes.any),
    valueDeletions: PropTypes.objectOf(PropTypes.any),
};

ValueMappings.defaultProps = {
    data: [],
    suggestions: [],
    valueMapping: {},
    valueDeletions: {},
};

export default connect(
    mapStateToProps,
    null,
)(ValueMappings);
