/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Form from 'react-jsonschema-form';
import { isEmpty } from 'lodash';
import {
    ButtonDropdown, DropdownToggle, DropdownMenu, Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import ColumnParser from '../../core/ColumnParser';
import './Column.css';
import TableData from './TableData';

const mapDispatchToProps = dispatch => ({
    onApplyColumns: (id, columns) => {
        dispatch({
            type: 'APPLY_COLUMNS',
            columns,
            id,
        });
        dispatch({
            type: 'TOGGLE_COLUMN',
            id,
        });
    },
});

class Column extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    /**
     * Get active columns. When no columns have been selected
     * the function will return an object with all available
     * columns, based on the schema, enabled.
     *
     * @param {object} columns
     *
     * @return {object}
     */
    getActiveColumns(columns) {
        return !isEmpty(columns)
            ? columns
            : TableData.getDefaultColumns(this.props.table.entitySchema)
                .reduce((prev, current) => {
                    const subject = prev;
                    subject[current] = true;

                    return subject;
                }, {});
    }

    /**
     * Toggle menu state.
     *
     * @return {void}
     */
    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    /**
     * Render menu.
     *
     * @returns {object}
     */
    render() {
        const schema = ColumnParser.parse(this.props.table.entitySchema);
        const values = this.getActiveColumns(this.props.table.columns);

        return (
            <ButtonDropdown
                isOpen={this.state.isOpen}
                toggle={() => this.toggle()}
            >
                <DropdownToggle
                    caret
                    size="sm"
                    color="btn btn-outline-secondary"
                >
                    Columns
                </DropdownToggle>
                <DropdownMenu right>
                    <Form
                        className="columns p-3"
                        id={`columns-${this.props.table.entity}`}
                        schema={schema}
                        formData={values}
                        onSubmit={(form) => {
                            this.toggle();
                            this.props.onApplyColumns(
                                this.props.table.id,
                                form.formData,
                            );
                        }}
                    >
                        <Button color="primary" size="sm">Apply</Button>
                    </Form>
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(Column);
