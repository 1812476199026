/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchRoutes, selectRoute } from '../../reducers/reporting/actions';

class SelectRoute extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
    }

    /**
     * Fetch all routes.
     */
    componentDidMount() {
        this.props.dispatch(
            fetchRoutes(),
        );
    }

    /**
     * Get option to show all routes.
     *
     * @returns {object}
     */
    getAllRoutesOption() {
        return {
            label: 'All routes',
            value: null,
        };
    }

    /**
     * Get route option.
     * @param {object} route
     * @returns {object}
     */
    getRouteOption(route) {
        return {
            label: route.name,
            value: route.identifier,
        };
    }

    /**
     * Select a value.
     *
     * @param {object} option
     */
    handleSelect(option) {
        const route = this.props.routes.find((subject) => {
            return option.value === subject.identifier;
        });

        this.props.dispatch(
            selectRoute(route),
        );
    }

    /**
     * Render the component.
     *
     * @returns {object}
     */
    render() {
        const data = this.props.routes.map(route => this.getRouteOption(route));
        data.unshift(this.getAllRoutesOption());

        const value = (this.props.selectedRoute && this.props.selectedRoute.identifier)
            ? this.getRouteOption(this.props.selectedRoute)
            : this.getAllRoutesOption();

        return (
            <Select
                value={value}
                options={data}
                onChange={this.handleSelect}
            />
        );
    }
}

const mapStateToProps = state => ({
    routes: state.reporting.routes,
    selectedRoute: state.reporting.route,
});

export default connect(mapStateToProps)(SelectRoute);
