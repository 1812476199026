/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Subscribers from './Subscribers';
import Publishers from './Publishers';
import Entities from './Entities';
import Remotes from './Remotes';
import MagementRoutes from './Routes';
import Tasks from './Tasks';
import Transformers from './Transformers';
import WebHooks from './WebHooks';
import HttpClients from './HttpClients';
import PdoClients from './PdoClients';
import Storages from './Storages';
import Filesystems from './Filesystems';
import Documentation from './Documentation';
import Environment from './Environment';
import Flags from './Flags';
import Mapping from './Mapping';
import Di from './Di';
import Users from './Users';
import Route from './PrivateRoute';

const mapStateToProps = state => ({
    schema: state.schema.schema,
});

/**
 * Route definitions.
 *
 * @return {object}
 */
const Routes = ({ schema }) => (
    <>
        <Subscribers />
        <Publishers />
        <Entities />
        <Remotes />
        <MagementRoutes />
        <Tasks />
        <Transformers />
        <WebHooks schema={schema} />
        <HttpClients schema={schema} />
        <PdoClients />
        <Storages />
        <Filesystems schema={schema} />
        <Environment />
        <Flags />
        <Documentation />
        <Mapping />
        <Di />
        <Users />
        <Switch>
            <Route
                exact
                path="/:environment"
                render={() => <Dashboard />}
            />
            {/* @TODO: Create 404 page. */}
            <Route render={() => null} />
        </Switch>
    </>
);

export default withRouter(connect(mapStateToProps, null)(Routes));
