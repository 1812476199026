/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    map,
    has,
    pickBy,
    isObject,
} from 'lodash';
import render from '../../core/Ui';
import Chips, { Chip } from '../Ui/Chips';

const mapDispatchToProps = dispatch => ({
    onRemoveFilter: (id, field) => {
        dispatch({
            type: 'REMOVE_FILTER',
            id,
            field,
        });
    },
});

const extractors = [
    filter => (filter.inq ? filter.inq.identifier : false),
    filter => (filter.like ? filter.like : false),
    filter => filter,
];

const valueExtractor = (filter) => {
    return extractors.reduce((carry, extractor) => {
        if (carry !== false) {
            return carry;
        }

        return extractor(filter);
    }, false);
};

/**
 * Show applied filters.
 *
 * @param {object } props
 *
 * @return {object}
 */
const AppliedFilters = (props) => {
    const { table, onRemoveFilter, chips } = props;

    const filters = pickBy(chips, (subject) => {
        if (!isObject(subject)) {
            return true;
        }

        return (
            subject.gte !== undefined
            || subject.lte !== undefined
            || subject.inq !== undefined
            || subject.like !== undefined
        );
    });

    return (
        <Chips>
            {
                map(filters, (filter, column) => {
                    const definition = has(table, `entitySchema.properties.${column}`)
                        ? table.entitySchema.properties[column]
                        : { type: 'string' };

                    return (
                        <Chip
                            key={`${column}-chip`}
                            field={column}
                            onDelete={() => onRemoveFilter(table.id, column)}
                            label={render(valueExtractor(filter), definition)}
                        />
                    );
                })
            }
        </Chips>
    );
};

export default connect(
    null,
    mapDispatchToProps,
)(AppliedFilters);
