/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

class Utils {
    /**
     * Create a deep copy (without references) of an object.
     *
     * @param {object} object
     *
     * @returns {object}
     */
    static clone(object) {
        return JSON.parse(JSON.stringify(object));
    }
}

export default Utils;
