/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';

/**
 * Show the header of a tab.
 *
 * @param {boolean} active
 * @param {object} tab
 * @param {string} name
 * @param {function} onClick
 *
 * @return {object}
 */
const TabLink = ({
    active, tab, name, onClick,
}) => {
    const activeCss = active ? 'active' : '';

    return (
        <li className="nav-item">
            <a
                className={`nav-link ${activeCss}`}
                href={`#${tab}`}
                onClick={() => onClick(tab)}
            >
                {name}
            </a>
        </li>
    );
};

export default TabLink;
