/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import HealthReportWidget from '../Widget/HealthReport';
import PageHeader from '../Ui/PageHeader';
import FeatureDetect from '../../core/FeatureDetect';
import RealtimeStats from '../Widget/RealtimeStats';
import SelectRoute from '../Widget/SelectRoute';
import TimeFrame from '../Widget/TimeFrame';
import DateRangeSelect from '../Ui/DateRangeSelect';
import ErrorRateGraph from '../Widget/ErrorRateGraph';
import { selectRange } from '../../reducers/reporting/actions';
import Title from '../Ui/Title';
import './Dashboard.scss';

const TASK_HOUR_RANGE = 24;

const Dashboard = ({ reporting, schema, dispatch }) => {
    const FeatureDetection = new FeatureDetect(schema);

    return (
        <>
            {
                FeatureDetection.hasOperationId('getHealthStatus') && (
                    <>
                        <PageHeader title="Health monitoring" sticky={false} />
                        <HealthReportWidget />
                    </>
                )
            }

            <Title title="Realtime stats" sticky={false}>
                <div className="row realtime">
                    <div className="col-md-6">
                        <p className="mr-4 page-title text-secondary">Filter by route</p>
                    </div>
                    <div className="col-md-6">
                        <SelectRoute
                            status={{
                                new: false,
                                processing: false,
                                failed: TASK_HOUR_RANGE,
                                finished: TASK_HOUR_RANGE,
                            }}
                        />
                    </div>
                </div>
            </Title>
            <RealtimeStats />

            <div className="time-frame mt-4 header">
                <h4 className="page-title">
                    Timeframe
                </h4>
                <DateRangeSelect
                    className="date-range-select ml-4"
                    onClick={range => dispatch(selectRange(range))}
                    currentRange={reporting.range}
                />
            </div>
            <TimeFrame />

            <PageHeader title="Task flow" sticky={false} />
            <ErrorRateGraph label="Error rate per hour" />
        </>
    );
};

const mapStateToProps = state => ({
    schema: state.schema.schema,
    reporting: state.reporting,
});

export default connect(mapStateToProps)(Dashboard);
