/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import Accessor from './Accessor';
import Filter from './Filter';
import DataTransformer from './DataTransformer';
import Comparator from './Comparator';
import Condition from './Condition';
import Mapper from './Mapper';
import Operator from './Operator';
import Transformer from './Transformer';

const Fields = {
    Accessor,
    Filter,
    DataTransformer,
    Comparator,
    Condition,
    Mapper,
    Operator,
    Transformer,
};

const TYPE_ARRAY = 'array';

const TYPE_OBJECT = 'object';

// TODO: Refactor the UI schema once the API supports resolving field types.
// This uiSchema maps fields to UI widgets based on the name
// of the field. In future releases of Magement it will be
// possible to determine the field type from the API.
const uiSchema = {
    // Accessors
    accessor: {
        'ui:field': 'Accessor',
    },
    accessors: {
        'ui:field': 'Accessor',
    },
    source: {
        'ui:field': 'Accessor',
    },
    destination: {
        'ui:field': 'Accessor',
    },
    // Filters
    filter: {
        'ui:field': 'Filter',
    },
    filters: {
        'ui:field': 'Filter',
    },
    'node-filters': {
        'ui:field': 'Filter',
    },
    // DataTransformers
    transformer: {
        'ui:field': 'DataTransformer',
    },
    transformers: {
        'ui:field': 'DataTransformer',
    },
    'node-transformers': {
        'ui:field': 'DataTransformer',
    },
    // Comparators
    comparator: {
        'ui:field': 'Comparator',
    },
    comparators: {
        'ui:field': 'Comparator',
    },
    // Conditions
    condition: {
        'ui:field': 'Condition',
    },
    conditions: {
        'ui:field': 'Condition',
    },
    // Mappers
    mapper: {
        'ui:field': 'Mapper',
    },
    mappers: {
        'ui:field': 'Mapper',
    },
    // Operators
    operator: {
        'ui:field': 'Operator',
    },
    operators: {
        'ui:field': 'Operator',
    },
    parameters: {
        'ui:field': 'Transformer',
    },
};

export default Fields;
export {
    Accessor,
    DataTransformer,
    uiSchema,
    TYPE_ARRAY,
    TYPE_OBJECT,
};
