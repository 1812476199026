/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

/**
 * Route only accessible for logged in users.
 *
 * @param {function} render
 * @param {object} props
 * @param {boolean} loggedIn
 * @param {string} environment
 * @param {object} rest
 *
 * @returns {object}
 */
const PrivateRoute = ({
    render,
    loggedIn,
    environment,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={
                (loggedIn && !!environment)
                    ? render
                    : () => <Redirect to="/login" />
            }
        />
    );
};

const mapStateToProps = state => ({
    loggedIn: state.authentication.loggedIn,
    environment: state.authentication.environment,
});

export default connect(mapStateToProps)(PrivateRoute);
