/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactJSON from 'react-json-view';
import HttpClient from '../../../core/HttpClient/index';

/**
 * Show an entity's contents.
 *
 * @param {string} value
 *
 * @return {object}
 */
class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entity: {},
        };
    }

    componentDidMount() {
        HttpClient.request(
            this.props.operationId,
            {
                entity: this.props.entityIdentifier,
                identifier: this.props.identifier,
            },
        ).then((response) => {
            this.setState({ entity: response.entity });
        }).catch(() => {
            // fail silently
        });
    }

    render() {
        return (
            <div className="col-md-12">
                <h4>Content</h4>
                <ReactJSON
                    name={false}
                    src={this.state.entity}
                    enableClipboard={false}
                    displayObjectSize={false}
                    displayDataTypes={false}
                />
            </div>
        );
    }
}

Details.propTypes = {
    operationId: PropTypes.string.isRequired,
    entityIdentifier: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
};

export default Details;
