/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import EntityContainer from '../Entity/EntityContainer';
import EntityView from '../Entity/EntityView';
import '../../Entity/Entity.css';

/**
 * Remote details.
 *
 * @param {string} tag
 * @param {object} operationIds
 * @param {string} entity
 * @param {object} params
 * @param {string} basePath
 * @param {array} renderers
 * @param {array} actions
 * @param {function} onChange
 * @param {array} tabs
 *
 * @return {object}
 */
const Details = ({
    tag,
    operationIds,
    entity,
    params,
    basePath,
    renderers,
    actions,
    onChange,
    tabs,
}) => (
    <EntityContainer
        tag={tag}
        operationIds={operationIds}
        entity={entity}
        params={params}
        basePath={basePath}
        onChange={onChange}
    >
        <EntityView
            tag={tag}
            operationIds={operationIds}
            entity={entity}
            params={params}
            basePath={basePath}
            renderers={renderers}
            actions={actions}
            tabs={tabs}

        />
    </EntityContainer>
);

Details.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
    renderers: PropTypes.arrayOf(PropTypes.func),
    actions: PropTypes.array,
    onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    tabs: PropTypes.array,
};

Details.defaultProps = {
    params: {},
    renderers: [],
    actions: [],
    onChange: false,
    tabs: [],
};

export default Details;
