/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import UndoChanges from '../../Actions/UndoChanges';
import AcceptSuggestion from '../../Actions/AcceptSuggestion';
import RemoveMapping from '../../Actions/RemoveMapping';
import MappingStatus from '../../MappingStatus';
import {
    undoMappingValue,
    addMappingValue,
    removeMappingValue,
} from '../../../../../reducers/mapping/actions';

const Actions = ({
    row,
    currentStatus,
    currentValue,
    isMapped,
    isDeleted,
    isChanged,
}) => {
    return (
        <>
            {
                (isDeleted || isChanged)
                    && (
                        <UndoChanges
                            id={row.id}
                            onClick={undoMappingValue}
                        />
                    )
            }

            {
                currentStatus === MappingStatus.MAPPING_MATCHED
                    && (
                        <AcceptSuggestion
                            source={row.id}
                            destination={currentValue}
                            onClick={addMappingValue}
                        />
                    )
            }

            {
                (isMapped && !isDeleted)
                    && (
                        <RemoveMapping
                            source={row.id}
                            destination={currentValue}
                            onClick={removeMappingValue}
                        />
                    )
            }
        </>
    );
};

export default Actions;
