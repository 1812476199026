/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * Prefix link with environment.
 *
 * @param {string} environment
 * @param {dispatch}
 * @param {string} to
 * @param {object} rest
 *
 * @returns {object}
 */
const PrefixedLink = ({
    environment,
    dispatch,
    to,
    ...rest
}) => {
    return (
        <Link
            to={`/${environment}${to}`}
            {...rest}
        />
    );
};

const mapStateToProps = state => ({
    environment: state.authentication.environment,
});

export default connect(mapStateToProps)(PrefixedLink);
