/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHeaderCell from './TableHeaderCell';
import Validation from '../../validation';
import { selectRows } from '../../reducers/table/action';

/**
 * Render the cell of a table header.
 *
 * @param {object} table
 * @param {array} columns
 * @param {object} disabledColumns
 * @param {boolean} withActionsColumn
 * @param {boolean} withMassActionColumn
 * @param {function} dispatch
 * @param {boolean} hasFilter
 *
 * @return {object}
 */
const TableHeaderColumns = ({
    table,
    columns,
    disabledColumns,
    withActionsColumn,
    withMassActionColumn,
    dispatch,
    hasFilter,
}) => {
    const labels = columns.reduce((prev, current) => {
        const subject = prev;
        subject[current] = table.entitySchema.properties[current].title
            ? table.entitySchema.properties[current].title
            : current;
        return subject;
    }, {});

    return (
        <thead>
            <tr>
                {withMassActionColumn
                    ? (
                        <th>
                            <input
                                type="checkbox"
                                checked={table.massChecked}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    dispatch(selectRows(
                                        table.id,
                                        table.data.map((row) => {
                                            return row.identifier;
                                        }),
                                    ));
                                }}
                            />
                        </th>
                    )
                    : null
                }
                {
                    columns.map(column => (
                        <TableHeaderCell
                            key={`th ${column}`}
                            tableId={table.id}
                            value={column}
                            labels={labels}
                            active={(table.sortColumn === column)}
                            direction={table.sortDirection}
                            sortable={!disabledColumns.includes(column) && hasFilter}
                        />
                    ))
                }
                {withActionsColumn ? <th>Actions</th> : null}
            </tr>
        </thead>
    );
};

TableHeaderColumns.propTypes = {
    table: PropTypes.shape(Validation.table).isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
    withActionsColumn: PropTypes.bool,
    hasFilter: PropTypes.bool,
};

TableHeaderColumns.defaultProps = {
    columns: [],
    disabledColumns: [],
    withActionsColumn: false,
    hasFilter: true,
};

export default connect()(TableHeaderColumns);
