/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Config from '../../core/Config';
import logo from '../../assets/alumio-horizontal-white.svg';
import smallLogo from '../../assets/alumio-symbol.svg';
import NotificationsContainer from './NotificationsContainer';
import CurrentUser from './CurrentUser';
import Link from '../Routes/PrefixedLink';
import './Logo.css';

const mapDispatchToDashboardProps = dispatch => ({
    handleSetCurrentPage: page => (
        dispatch({
            type: 'SET_CURRENT_PAGE',
            page,
        })
    ),
    toggleMenu: () => (
        dispatch({
            type: 'TOGGLE_MENU',
        })
    ),
});

/**
 * Show header of page.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Header = ({ handleSetCurrentPage, toggleMenu }) => (
    <div className="topbar">
        <div className="topbar-left">
            <Link
                className="logo"
                href="/"
                to="/"
                onClick={() => handleSetCurrentPage('/')}
            >
                <span>
                    <img src={logo} alt={Config.name} title={Config.name} id="main-logo" />
                </span>
                <i>
                    <img src={smallLogo} alt={Config.name} title={Config.name} id="small-logo" />
                </i>
            </Link>
        </div>
        <nav className="navbar-custom">
            <ul className="list-inline float-right mb-0">
                <NotificationsContainer />
                <CurrentUser />
            </ul>
            <ul className="list-inline menu-left mb-0">
                <li className="float-left">
                    <button
                        type="button"
                        className="button-menu-mobile open-left waves-light waves-effect"
                        onClick={() => toggleMenu()}
                    >
                        <i className="mdi mdi-menu" />
                    </button>
                </li>
            </ul>
        </nav>
    </div>
);

Header.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    handleSetCurrentPage: PropTypes.func.isRequired,
};

export default connect(
    null,
    mapDispatchToDashboardProps,
)(Header);
