/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import EmptyLogMessage from './EmptyLogMessage';

/**
 * Show log messages.
 *
 * @param {array} messages
 *
 * @return {object}
 */
const LogMessages = ({ messages }) => {
    const children = messages.length > 0
        ? messages
        : <EmptyLogMessage />;

    return (
        <div>
            <div className="timeline timeline-left">
                { children }
            </div>
        </div>
    );
};

LogMessages.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LogMessages;
