/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import beautify from 'xml-beautifier';
import { UncontrolledCollapse, Button } from 'reactstrap';
import IonIcon from '../../Ui/Icons/IonIcon';
import { formatDatetime, formatHumanReadableDate } from '../../../core/Dates';
import ToggleButton from '../../Ui/Buttons/ToggleButton';

/**
 * Show single log message.
 *
 * @param {string} timestamp
 * @param {string} message
 * @param {string} level
 *
 * @return {object}
 */
const LogMessage = ({
    messageId, timestamp, message, level,
}) => {
    const [formatMessages, setFormatMessages] = useState(true);

    const colorClass = {
        emergency: {
            bg: 'bg-danger',
            cl: 'text-danger',
        },
        alert: {
            bg: 'bg-danger',
            cl: 'text-danger',
        },
        critical: {
            bg: 'bg-danger',
            cl: 'text-danger',
        },
        error: {
            bg: 'bg-danger',
            cl: 'text-danger',
        },
        warning: {
            bg: 'bg-warning',
            cl: 'text-warning',
        },
        notice: {
            bg: 'bg-warning',
            cl: 'text-warning',
        },
        info: {
            bg: 'bg-info',
            cl: 'text-info',
        },
        debug: {
            bg: 'bg-info',
            cl: 'text-info',
        },
    };

    const { bg } = colorClass[level] || 'bg-info';
    const { cl } = colorClass[level] || 'text-info';

    /**
     * Test string if it's JSON.
     *
     * @param {string} str
     *
     * @return {boolean}
     */
    const isJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    };

    /**
     * Improve readability of JSON in given string.
     *
     * @param {string} msg
     *
     * @return {string} optimized content
     */
    const optimizeJSONContent = (msg) => {
        const pices = msg.split('\n');
        let optimizedContent = '';

        pices.forEach((pice) => {
            optimizedContent += `${(isJSON(pice)) ? JSON.stringify(JSON.parse(pice), undefined, 4) : pice} \n`;
        });

        return optimizedContent;
    };

    /**
     * Improve readability of XML.
     *
     * @param {string} msg
     *
     * @return {string} optimized content
     */
    const optimizeXMLContent = (msg) => {
        return beautify(msg);
    };

    /**
     * Determine content type
     * Returns array of matches or undefined. matches[1] should contain 'text/xml' or 'application/json'.
     *
     * @param {string} msg
     *
     * @return {array|boolean} matches | false
     */
    const contentType = (msg) => {
        const matches = msg.match(/Content-Type:\s*([^ ;]+)/i);

        return matches ? matches[1] : false;
    };

    /**
     * Improve readability of message.
     *
     * @param {string} msg
     *
     * @return {string} formatted
     */
    const optimizeMessage = (msg) => {
        if (!formatMessages) {
            return msg;
        }

        const sizeBefore = msg.length;

        const processors = {
            'text/xml': optimizeXMLContent(msg),
            'application/json': optimizeJSONContent(msg),
        };

        let formatted = processors[contentType(msg)] ? processors[contentType(msg)] : msg;

        // prevent message data getting lost if optimize goes wrong or content is still not set
        if (formatted === '' || formatted.length < sizeBefore) {
            formatted = msg;
        }

        return formatted;
    };

    return (
        <article className="timeline-item">
            <div className="timeline-desk">
                <div className="panel">
                    <div className="timeline-box">
                        <div className="arrow" />
                        <div className="timeline-head">
                            <span className={`timeline-icon ${bg}`}>
                                <i className="mdi mdi-checkbox-blank-circle-outline" />
                            </span>
                            <h4>{ formatHumanReadableDate(timestamp) }</h4>
                            <p className="timeline-date text-muted">
                                <small>
                                    { formatDatetime(timestamp) }
                                </small>
                            </p>
                        </div>
                        { message && (
                            <>
                                <Button id={`toggler-${messageId}`}>
                                    <IonIcon icon="information-circle-outline" />
                                    {' Details'}
                                </Button>
                                <UncontrolledCollapse toggler={`#toggler-${messageId}`}>
                                    <pre className={cl}>
                                        <div className="float-right">
                                            <ToggleButton
                                                onClick={() => setFormatMessages(!formatMessages)}
                                                enabled={formatMessages}
                                                size="small"
                                            />
                                        </div>
                                        { optimizeMessage(message) }
                                    </pre>
                                </UncontrolledCollapse>
                            </>
                        )
                        }
                    </div>
                </div>
            </div>
        </article>
    );
};

LogMessage.propTypes = {
    timestamp: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
};

export default LogMessage;
