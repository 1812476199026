/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {
    applyAllChanges,
    applyTypeChanges,
    applyPageChanges,
} from '../../../../../reducers/mapping/actions';
import {
    getByType,
    getByPage,
} from '../../MappingFactory';
import IonIcon from '../../../../Ui/Icons/IonIcon';

const mapStateToProps = state => ({
    mapping: state.mapping,
});

/**
 * Show the amount of pending changes.
 *
 * @param {object} keyMapping
 * @param {array} deletions
 * @param {object} mapping
 * @param {function} dispatch
 * @param {bool} isSubmitting
 * @returns {object}
 */
const PendingChanges = ({
    keyMapping,
    deletions,
    mapping,
    dispatch,
    isSubmitting,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const amount = (Object.keys(keyMapping).length + Object.keys(deletions).length);

    const changedPerType = getByType(
        mapping.type.value,
        mapping.keyMapping,
    );

    const changedPerPage = getByPage(
        mapping.type.value,
        mapping.source.value,
        mapping.destination.value, mapping.keyMapping,
    );

    const deletedPerType = getByType(
        mapping.type.value,
        mapping.deletions,
    );

    const deletedPerPage = getByPage(
        mapping.type.value,
        mapping.source.value,
        mapping.destination.value,
        mapping.deletions,
    );

    const SaveIcon = isSubmitting
        ? <IonIcon icon="hourglass" />
        : <IonIcon icon="save" />;

    return amount > 0 && (
        <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="mr-2">
            <DropdownToggle caret color="primary" disabled={isSubmitting}>
                { SaveIcon }
                Save changes
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => dispatch(applyAllChanges())}>
                    { `All changes (${amount})` }
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(applyTypeChanges())}>
                    { `In ${mapping.type.label} (${(changedPerType.length + deletedPerType.length)})` }
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(applyPageChanges())}>
                    { `On this page (${changedPerPage.length + deletedPerPage.length})` }
                </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );
};

PendingChanges.propTypes = {
    keyMapping: PropTypes.objectOf(PropTypes.any),
    deletions: PropTypes.objectOf(PropTypes.any),
    isSubmitting: PropTypes.bool,
};

PendingChanges.defaultProps = {
    keyMapping: {},
    deletions: {},
    isSubmitting: false,
};

export default connect(mapStateToProps)(PendingChanges);
