/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Chip from './Chip';
import './Chips.css';

/**
 * Render a list of chips.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Chips = ({ children }) => (
    <div className="border-bottom pb-3 mb-3 clearfix">
        <ul
            className="chips"
        >
            {children}
        </ul>
    </div>
);

export default Chips;
export { Chip };
