/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import FieldFactory from './FieldFactory';

const Operator = (props) => {
    const additionalProps = {
        tag: 'Operators',
        operationId: 'getOperators',
        types: { object: 'operator', array: 'operators' },
        ...props,
    };

    return FieldFactory.create(props.schema, additionalProps);
};

export default Operator;
