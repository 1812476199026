/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import PropTypes from 'prop-types';
import Redirect from '../../../core/Redirect';
import { fetchFailedTasks } from '../../../reducers/reporting/actions';
import {
    options,
    RANGE_24_HOURS,
    RANGE_CUSTOM,
    RANGE_LAST_HOUR,
    RANGE_TODAY,
} from '../../Ui/DateRangeSelect/CalculateRange';

class ErrorRateGraph extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Load task data.
     */
    componentDidMount() {
        this.props.dispatch(fetchFailedTasks());
    }

    /**
     * Get data by 24 hours.
     *
     * @param {array} tasks
     * @param {object=} start
     *
     * @returns {array}
     */
    getDataBy24H(tasks, start) {
        const axis = [];
        for (let i = 1; i <= 24; i++) {
            axis.push({
                x: moment(start).utc().subtract((24 - i), 'hours').format('DD-MM [/] H[:00]'),
                y: 0,
            });
        }

        tasks.map(item => moment(item).utc().format('DD-MM [/] H[:00]'))
            .forEach((task) => {
                const index = axis.findIndex((subject) => {
                    return subject.x === task;
                });

                if (index !== -1) {
                    axis[index].y += 1;
                }
            });

        return axis;
    }

    /**
     * Get graph data for a whole day.
     *
     * @param {array} tasks
     * @param {object=} start
     *
     * @returns {array}
     */
    getDataByDay(tasks, start) {
        const axis = [];
        const dayStart = moment(start).utc().startOf('day');

        for (let i = 0; i < 24; i++) {
            axis.push({
                x: moment(dayStart).utc().add(i, 'hours').format('DD-MM / H[:00]'),
                y: 0,
            });
        }

        tasks.map(item => moment(item).utc().format('DD-MM / H[:00]'))
            .forEach((task) => {
                const index = axis.findIndex((subject) => {
                    return subject.x === task;
                });

                if (index !== -1) {
                    axis[index].y += 1;
                }
            });

        return axis;
    }

    /**
     * Get graph data for one hour.
     *
     * @param {array} tasks
     * @param {object=} start
     *
     * @returns {array}
     */
    getDataByHour(tasks, start) {
        const axis = [];
        const hourStart = moment(start).utc().startOf('hour');

        for (let i = 0; i < 60; i += 5) {
            axis.push({
                x: moment(hourStart).utc().add(i, 'minutes').format('H:mm'),
                y: 0,
            });
        }

        tasks
            .map((item) => {
                return this.getRoundedDate(item, moment.duration(5, 'minutes'), 'ceil').utc().format('H:mm');
            })
            .forEach((task) => {
                const index = axis.findIndex((subject) => {
                    return subject.x === task;
                });

                if (index !== -1) {
                    axis[index].y += 1;
                }
            });

        return axis;
    }

    /**
     * Get data by custom date range.
     *
     * @param {array} tasks
     * @param {object} start
     * @param {object} end
     *
     * @returns {array}
     */
    getDataByDayRange(tasks, start, end) {
        const axis = [];

        const endDay = moment(end).utc();
        let current = moment(start).utc();

        while (endDay.isAfter(current)) {
            axis.push({
                x: moment(current).utc().format('DD-MM'),
                y: 0,
            });

            current = moment(current).utc().add(1, 'day');
        }

        tasks.map(item => moment(item).utc().format('DD-MM'))
            .forEach((task) => {
                const index = axis.findIndex((subject) => {
                    return subject.x === task;
                });

                if (index !== -1) {
                    axis[index].y += 1;
                }
            });

        return axis;
    }

    /**
     * Get data by custom range.
     *
     * @param {array} tasks
     *
     * @returns {array}
     */
    getDataByCustomRange(tasks) {
        const custom = this.props.range;

        const start = moment(custom.range.from).utc();
        const end = moment(custom.range.to).utc();

        // Difference in hours.
        const diff = (end - start) / 3600000;

        // More then a day.
        if (diff > 24) {
            return this.getDataByDayRange(tasks, start, end);
        }

        // More then a hour
        if (diff > 1) {
            return this.getDataByDay(tasks, start);
        }

        // One hour or less.
        return this.getDataByHour(tasks, start);
    }

    /**
     * Round date.
     *
     * @param {string} date
     * @param {object} duration
     * @param {string} method
     *
     * @returns {object}
     */
    getRoundedDate(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    /**
     * Get graph date based on the current selected range.
     *
     * @returns {array}
     */
    getAggregatedTasks() {
        const tasks = this.props.tasks
            .map(task => new Date(task.created_at))
            .sort((a, b) => {
                return a - b;
            });

        switch (this.props.range.option) {
            case RANGE_LAST_HOUR:
                return this.getDataByHour(tasks);
            case RANGE_TODAY:
                return this.getDataByDay(tasks);
            case RANGE_24_HOURS:
                return this.getDataBy24H(tasks);
            case RANGE_CUSTOM:
                return this.getDataByCustomRange(tasks);
            default:
                return [];
        }
    }

    /**
     * Get graph options.
     *
     * @returns {object}
     */
    getOptions() {
        return {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        min: 0,
                    },
                }],
            },
            legend: {
                display: false,
            },
            scaleShowGridLines: true,
            scaleGridLineColor: 'rgba(102,111,123, 1)',
            scaleGridLineWidth: 1,
            scaleShowHorizontalLines: true,
            scaleShowVerticalLines: true,
            bezierCurve: true,
            bezierCurveTension: 0.4,
            pointDot: true,
            pointDotRadius: 4,
            pointDotStrokeWidth: 1,
            pointHitDetectionRadius: 20,
            datasetStroke: true,
            datasetStrokeWidth: 2,
            datasetFill: true,
        };
    }

    /**
     * Get form data.
     *
     * @returns {object}
     */
    getGraphData() {
        const data = this.getAggregatedTasks();

        return {
            labels: data.map(row => row.x),
            datasets: [
                {
                    label: this.props.label,
                    pointBackgroundColor: 'rgba(242,79,124, 1)',
                    pointBorderColor: '#d03f3f',
                    fillColor: '#d03f3f',
                    strokeColor: '#d03f3f',
                    pointColor: '#d03f3f',
                    borderColor: '#d03f3f',
                    backgroundColor: '#d03f3f',
                    pointStrokeColor: '#000',
                    pointHighlightFill: '#000',
                    pointHighlightStroke: '#d03f3f',
                    data,
                },
            ],
        };
    }

    /**
     * Redirect users to task queue.
     *
     * @param {array} event
     */
    handleClick(event) {
        if (event[0] !== undefined) {
            const { range } = this.props;

            this.props.dispatch({
                type: 'APPLY_FILTER',
                id: 'TaskQueue',
                formData: {
                    status: 'failed',
                    created_at: {
                        gte: options[range.option].value(range).from,
                        lte: options[range.option].value(range).to,
                    },
                },
            });

            Redirect(this.props.history, '/tasks/queue');
        }
    }

    /**
     * Render component
     *
     * @returns {object}
     */
    render() {
        return (
            <div className="portlet">
                <div className="portlet-body">
                    <Bar
                        height={50}
                        data={this.getGraphData()}
                        options={this.getOptions()}
                        getElementsAtEvent={this.handleClick}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tasks: state.reporting.tasks.failed,
    currentRoute: state.reporting.route,
    range: state.reporting.range,
});

ErrorRateGraph.propTypes = {
    label: PropTypes.string.isRequired,
    range: PropTypes.object,
};

ErrorRateGraph.defaultProps = {
    range: { option: RANGE_24_HOURS },
};

export default withRouter(connect(mapStateToProps)(ErrorRateGraph));
