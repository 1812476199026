/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EntityContainer from '../Entity/EntityContainer';
import EntityView from '../Entity/EntityView';
import EntityState from './EntityState';
import '../../Entity/Entity.css';
import HttpClient from '../../../core/HttpClient';
import ExportMessages from './ExportMessages';
import ImportMessages from './ImportMessages';
import LogFilters from './LogFilters';
import { IonIcon } from '../../Ui/Icons';

class TaskContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            exportMessages: [],
            importMessages: [],
            entityData: {},
            importFinishedAt: null,
        };
    }

    componentDidMount() {
        const { identifier } = this.props.params;

        HttpClient.request(
            'getTaskLogs',
            LogFilters.getTaskLogsFilter(identifier),
        )
            .then((response) => {
                if (response.metadata.totalCount < 1) {
                    throw new Error('No task logs available.');
                }
                const hit = response.results[0];
                const taskLogId = hit.task_log_id || hit.taskLogId;

                const entityState = hit.entity_state || hit.entityState;
                const entityData = entityState.data;

                const importLog = hit.import_log || hit.importLog;
                this.importLogId = importLog.log_id || importLog.logId;

                this.setState({
                    importFinishedAt: importLog.finished_at || importLog.finishedAt,
                });

                this.setState({
                    entityData,
                });

                return HttpClient.request(
                    'getExportTaskLogs',
                    LogFilters.getExportTaskLogsFilter(taskLogId),
                );
            })
            .then((response) => {
                const ids = response.results.reduce((carry, row) => {
                    carry.push(row.log_id || row.logId);

                    return carry;
                }, []);

                return HttpClient.request(
                    'getExportMessages',
                    LogFilters.getExportMessagesFilter(ids),
                );
            })
            .then((response) => {
                this.setState({
                    exportMessages: response.results,
                });
            })
            .then(() => {
                return HttpClient.request(
                    'getImportMessages',
                    LogFilters.getImportMessagesFilter(this.importLogId),
                );
            })
            .then((response) => {
                this.setState({
                    importMessages: response.results,
                });
            })
            .catch(() => {
                // No messages available. No action required.
            });
    }

    showLoadingStatus() {
        return (
            <div className="text-center">
                <IonIcon icon="hourglass" />
                {' '}
                Loading
            </div>
        );
    }

    render() {
        const {
            tag,
            operationIds,
            entity,
            params,
            basePath,
            api,
        } = this.props;

        const {
            entityData, importFinishedAt, importMessages, exportMessages,
        } = this.state;

        const tabs = [
            {
                id: 'entity-data',
                name: 'Entity Data',
                content: () => (
                    (api.isRequesting)
                        ? this.showLoadingStatus()
                        : (
                            <EntityState
                                entity={entityData}
                            />
                        )
                ),
            },
            {
                id: 'import-messages',
                name: 'Import Messages',
                content: () => (
                    (api.isRequesting)
                        ? this.showLoadingStatus()
                        : (
                            <ImportMessages
                                timestamp={importFinishedAt}
                                messages={importMessages}
                            />
                        )
                ),
            },
            {
                id: 'export-messages',
                name: 'Export Messages',
                content: () => (
                    (api.isRequesting)
                        ? this.showLoadingStatus()
                        : (
                            <ExportMessages
                                messages={exportMessages}
                            />
                        )
                ),
            },
        ];

        return (
            <EntityContainer
                tag={tag}
                operationIds={operationIds}
                entity={entity}
                params={params}
                basePath={basePath}
            >
                <EntityView
                    tag={tag}
                    operationIds={operationIds}
                    entity={entity}
                    params={params}
                    basePath={basePath}
                    tabs={tabs}
                />
            </EntityContainer>
        );
    }
}

TaskContainer.propTypes = {
    tag: PropTypes.string.isRequired,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    entity: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
};

TaskContainer.defaultProps = {
    params: {},
};

const mapStateToProps = state => ({
    api: state.api,
});

export default connect(mapStateToProps)(TaskContainer);
