/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { onEnterHandler } from '../../core/Ui';
import { formatHumanReadableDate } from '../../core/Dates';

const mapDispatchToProps = dispatch => ({
    onDelete: (id) => {
        dispatch({
            type: 'DELETE_NOTIFICATION',
            id,
        });
    },
});

/**
 * Show single notification.
 *
 * @param {string} id
 * @param {string} createdAt
 * @param {string} message
 * @param {function} onDelete
 *
 * @return {string}
 */
const Notification = ({
    id, createdAt, message, onDelete,
}) => {
    const handleDelete = (event) => {
        event.stopPropagation();
        onDelete(id);
    };

    return (
        <div
            tabIndex={0}
            role="button"
            onKeyUp={event => onEnterHandler(event, handleDelete)}
            onClick={event => handleDelete(event)}
        >
            <div className="notify-icon text-success">
                <i className="mdi mdi-comment-account-outline" />
            </div>
            <p className="notify-details">
                { message }
                <small className="text-muted">
                    { formatHumanReadableDate(createdAt) }
                </small>
            </p>
        </div>
    );
};

Notification.propTypes = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    message: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Notification);
