/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Redirect from '../../core/Redirect';

class ActionRow extends React.Component {
    /**
     * Constructor.
     *
     * @param {props} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.detailInfo = this.detailInfo.bind(this);
    }

    /**
     * Check if viewAction is true and if target is not already bound by a click
     *
     * @param {object} event
     * @param {string} url
     *
     * @return {void}
     */
    detailInfo(event, url) {
        let element = event.target;

        // Bubble up until it's sure that parents do not have an bound click event.
        while (!element.classList.contains('tr-to-detail')) {
            if (!!element.onclick !== false) {
                return;
            }

            element = element.parentElement;
        }

        Redirect(this.props.history, url);
    }

    /**
     * Render the tr section of a table.
     *
     */
    render() {
        const { children, viewAction } = this.props;
        let tr;

        if (viewAction !== null) {
            tr = (
                <tr onClick={event => this.detailInfo(event, viewAction)} className="clickable tr-to-detail">
                    {children}
                </tr>
            );
        } else {
            tr = (
                <tr>
                    {children}
                </tr>
            );
        }

        return (
            <>{tr}</>
        );
    }
}

ActionRow.propTypes = {
    viewAction: PropTypes.string,
    children: PropTypes.node,
};

ActionRow.defaultProps = {
    viewAction: null,
    children: undefined,
};

export default withRouter(ActionRow);
