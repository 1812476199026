/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Task } from '../Magement';
import Route from './PrivateRoute';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Tasks = () => (
    <Switch>
        <Route
            exact
            path="/:environment/tasks/queue"
            render={() => <Task.Overview />}
        />
        <Route
            exact
            path="/:environment/tasks/queue/:identifier"
            render={
                props => (
                    <Task.Details
                        tag="Tasks"
                        operationIds={{
                            read: 'getTask',
                        }}
                        entity="Task"
                        params={{ identifier: props.match.params.identifier }}
                        basePath="/tasks/queue"
                    />
                )
            }
        />
    </Switch>
);

export default Tasks;
