import React from 'react';
import HttpClient from '../../../core/HttpClient';
import PageHeader from '../../Ui/PageHeader';
import Create from './Create';
import CandidateMatch from './CandidateMatch';

const basePath = '/environment/variables/candidates';

class Candidates extends React.Component {
    /**
     * Render the given matches.
     *
     * @param {array} matches
     */
    static renderMatches(matches) {
        const matchList = matches.map(match => (
            <CandidateMatch
                type={match.type}
                payload={match.payload}
                metadata={match.metadata}
            />
        ));

        return (
            <div>{matchList}</div>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            candidates: null,
        };
    }

    /**
     * Get all routes.
     *
     * @return {void}
     */
    componentDidMount() {
        this.getCandidates();
    }

    /**
     * Get routes.
     *
     * @return {Promise}
     */
    getCandidates() {
        this.setState({ candidates: null });
        return HttpClient
            .request('getCandidateEnvironmentVariables')
            .then((response) => {
                this.setState({
                    candidates: response.results,
                });
            }).catch(() => {
                this.setState({
                    candidates: [],
                });
            });
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        if (this.state.candidates === null) {
            return (
                <div>
                    <PageHeader title="Loading candidates. . ." />
                </div>
            );
        }

        const candidates = this.state.candidates.map(candidate => (
            <div className="row">
                <div className="col-md-6">
                    <Create
                        entity="EnvironmentVariable"
                        tag="Environment"
                        title={candidate.name}
                        basePath={basePath}
                        backButton={false}
                        onSuccess={() => this.getCandidates()}
                        uiSchema={{
                            name: {
                                'ui:disabled': true,
                            },
                        }}
                        data={candidate}
                        operationIds={{
                            create: 'createEnvironmentVariable',
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <PageHeader title="Matches" />
                    { Candidates.renderMatches(candidate.matches) }
                </div>
                <div className="clearfix" />
            </div>
        ));

        if (candidates.length === 0) {
            return (
                <div>
                    <PageHeader title="No candidates found" />
                    <p>
                        All configurations have existing environment variables.
                    </p>
                </div>
            );
        }

        return (
            <div>
                {candidates}
            </div>
        );
    }
}

export default Candidates;
