/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Move down button.
 *
 * @param {function} onClick
 *
 * @return {object}
 */
const AddRowButton = ({ onClick }) => (
    <Button
        color="success"
        onClick={onClick}
    >
        <IonIcon icon="add-circle" hasText={false} />
    </Button>
);

AddRowButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default AddRowButton;
