/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

const mapStateToProps = state => ({
    enlarged: state.navigation.enlarged,
});

class Ui extends React.Component {
    /**
     * Constructor
     *
     */
    constructor(props) {
        super(props);

        this.bodyClass = document.body.classList;
    }

    /**
     * When component is mounted.
     *
     * @return {void}
     */
    componentDidMount() {
        // The CSS framework requires a class to be added to the
        // <body /> tag. Since that is outside of our React
        // app vanilla JS is used to add or remove it.
        this.bodyClass.toggle('enlarged', this.props.enlarged);

        this.scrollingDown();
    }

    /**
     * When props are updated.
     *
     * @param {object} nextProps
     *
     * @return {void}
     */
    componentWillReceiveProps(nextProps) {
        this.bodyClass.toggle('enlarged', nextProps.enlarged);
    }

    /**
     * When scrolling toggle classname on body
     *
     * @return {void}
     */
    scrollingDown() {
        window.onscroll = () => {
            this.bodyClass.toggle('scroll-down', (window.pageYOffset || document.body.scrollTop));
        };
    }

    /**
     * Render the main application.
     *
     * @return {object}
     */
    render() {
        return (
            <div id="wrapper">
                <Header />
                <div className="left side-menu">
                    <div id="sidebar-menu">
                        <Sidebar />
                    </div>
                </div>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            {this.props.children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

Ui.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withRouter(connect(
    mapStateToProps,
    null,
)(Ui));
