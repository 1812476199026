/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import KeySelect from '../KeySelect';

/**
 * Show mapping destination form element.
 *
 * @param {integer} from
 * @param {integer} currentValue
 * @param {array} options
 * @param {string} status
 * @param {function} onChange
 *
 * @returns {object}
 */
const Destination = ({
    from,
    currentValue,
    options,
    status,
    onChange,
}) => {
    return (
        <KeySelect
            from={from}
            options={options}
            currentValue={currentValue}
            status={status}
            onChange={onChange}
        />
    );
};

Destination.propTypes = {
    from: PropTypes.number.isRequired,
    currentValue: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string.isRequired,
};

Destination.defaultProps = {
    options: [],
    currentValue: null,
};

export default Destination;
