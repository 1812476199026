/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Form.css';

/**
 * Bootstrap4 layout for react-jsonschema-form.
 *
 * @param {string} id
 * @param {string} classNames
 * @param {string} label
 * @param {object} help
 * @param {bool} required
 * @param {object} errors
 * @param {object} children
 *
 * @return {object}
 */
const Bootstrap4Template = ({
    id, classNames, label, help, errors, children,
}) => {
    // Root of schema, containing all children fields is treated
    // as field as well. It should not have any template added.
    // Solution proposed by package maintainer (Issue 468).
    if (id === 'root') {
        return children;
    }

    return (
        <div className={`form-group row ${classNames}`}>
            <label
                className="col-sm-2 col-form-label"
                htmlFor={id}
            >
                {label !== null && label.replace(/_/g, ' ')}
            </label>
            <div className="col-sm-10">
                {children}
                {errors}
                {help}
            </div>
        </div>
    );
};

Bootstrap4Template.propTypes = {
    id: PropTypes.string.isRequired,
    classNames: PropTypes.string.isRequired,
    label: PropTypes.string,
    help: PropTypes.node.isRequired,
    errors: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

Bootstrap4Template.defaultProps = {
    label: '',
};

export default Bootstrap4Template;
