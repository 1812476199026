/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import HttpClient from '../../core/HttpClient';
import { KEY_MAPPING } from '../../core/Ui';
import Redirect from '../../core/Redirect';
import FeatureDetect from '../../core/FeatureDetect';

const mapStateToProps = state => ({
    definitions: state.schema.schema.components.schemas,
    schema: state.schema.schema,
});

class EnvironmentCandidatesWidget extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            candidates: [],
        };

        this.isActive = false;
    }

    /**
     * Load initial data.
     *
     * @return {void}
     */
    componentDidMount() {
        this.isActive = true;

        this.getCandidates();
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    /**
     * When a user clicks the icon.
     * Set filter and redirect.
     *
     * @return {void}
     */
    onClick() {
        const FeatureDetection = new FeatureDetect(this.props.schema);

        if (FeatureDetection.hasOperationId('createEnvironmentVariable')) {
            Redirect(this.props.history, '/environment/variables/candidates');
        }
    }

    /**
     * When a user presses a key while focused on component.
     *
     * @param {object} event
     */
    onKeyUp(event) {
        if (event.keyCode === KEY_MAPPING.ENTER) {
            this.onClick();
        }
    }

    setState(state, callback) {
        if (this.isActive) {
            super.setState(state, callback);
        }
    }

    /**
     * Get candidates for environment variables.
     *
     * @return {Promise}
     */
    getCandidates() {
        return HttpClient
            .request('getCandidateEnvironmentVariables')
            .then((response) => {
                this.setState({
                    candidates: response.results,
                });
            }).catch(() => {
                this.setState({
                    candidates: [],
                });
            });
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        const candidates = this.state.candidates.reduce((carry, candidate) => {
            carry.push(candidate.name);
            return carry;
        }, []);

        return candidates.length > 0 ? (
            <div
                className={`clickable ${this.props.className}`}
                icon={this.props.icon}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onKeyUp={event => this.onKeyUp(event)}
                    onClick={() => this.onClick()}
                >
                    <h2 className="m-0 text-white">Missing environment variables</h2>
                    <div>{candidates.join(', ')}</div>
                </div>
            </div>
        ) : (null);
    }
}

EnvironmentCandidatesWidget.propTypes = {
    icon: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    className: PropTypes.string,
};

EnvironmentCandidatesWidget.defaultProps = {
    icon: false,
    className: '',
};

export default withRouter(connect(
    mapStateToProps,
    null,
)(EnvironmentCandidatesWidget));
