/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Button to close a modal.
 *
 * @param {function} onClose
 *
 * @return {object}
 */
const CloseButton = ({ onClose }) => (
    <Button color="secondary" onClick={onClose}>
        <IonIcon icon="close" hasText />
        Close
    </Button>
);

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default CloseButton;
