/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import Item from './Item';

/**
 * Show legend explaining color codes.
 *
 * @returns {object}
 */
const Legend = () => {
    return (
        <div>
            <p>
                A mapping is the translation of a key and it values. A key can for example be a product attribute
                called color. In one system it may be called color while in another system it may be
                called colour.
            </p>
            <p>
                To create a mapping first select the type and both systems to connect. Then map the keys from the
                first system to the second.
            </p>
            <p>
                <div className="card-title">Suggestions</div>
                Alumio will offer mapping suggestions based on the values of the keys. The suggestions can be
                accepted by clicking on the checkmark on the right of the table. Suggestions are color coded.
                See the legend below.
            </p>

            <p><span className="card-title">Legend</span></p>
            <Item name="Pending change" type="mapped" />
            <Item name="Pending deletion" type="deleted" />
            <Item name="Suggestion" type="matched" />
        </div>
    );
};

export default Legend;
