/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import {
    REQUEST_TYPES,
    RECEIVE_TYPES,
    SELECT_TYPE,
    VIEW_TYPE,
    CHANGE_CONFIG,
    ADD_TEST_ENTITY, ADD_ERROR, CLOSE_ERROR,
} from './actions';

const transformerReducer = (state = {
    identifier: '',
    isFetching: false,
    items: {},
    current: {
        identifier: '',
        name: 'Transformer Documentation',
        schema: {},
    },
    testData: {},
    entities: {},
    error: {
        hasError: false,
        errorMessage: '',
    },
}, action) => {
    switch (action.type) {
        case REQUEST_TYPES:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_TYPES:
            return {
                ...state,
                isFetching: false,
                items: {
                    ...state.items,
                    [action.identifier]: action.data,
                },
            };
        case SELECT_TYPE:
            return {
                ...state,
                identifier: action.identifier === state.identifier
                    ? ''
                    : action.identifier,
            };
        case VIEW_TYPE:
            return {
                ...state,
                current: {
                    ...state.current,
                    name: action.name,
                    identifier: action.identifier,
                    schema: action.schema,
                },
            };
        case CHANGE_CONFIG:
            return {
                ...state,
                testData: action.testData,

            };
        case ADD_TEST_ENTITY:
            return {
                ...state,
                entities: {
                    [action.identifier]: action.entity,
                },
                error: {
                    hasError: false,
                },
            };
        case ADD_ERROR:
            return {
                ...state,
                error: {
                    hasError: true,
                    errorMessage: action.message,
                },
            };
        case CLOSE_ERROR:
            return {
                ...state,
                error: {
                    hasError: false,
                },
            };
        default: {
            return state;
        }
    }
};

export default transformerReducer;
