/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { createStore, applyMiddleware } from 'redux';
import { forEach } from 'lodash';
import thunk from 'redux-thunk';
import JSONPath from 'jsonpath';
import throttle from 'lodash/throttle';
import { initialize, addTranslation } from 'react-localize-redux';
import getApplicationState from './core/LocalStorage';
import reducer from './reducers';
import phrases from './locale/phrases.json';
import EnvironmentStorage from './core/Environment/EnvironmentStorage';

function loadState() {
    return getApplicationState();
}

/**
 * Save state in local storage.
 *
 * @param {object} state
 */
const saveState = (state) => {
    try {
        // Only save the application state to local storage
        // when an environment has been selected. Do not
        // store the state when a user is not logged in.
        if (EnvironmentStorage.hasEnvironment()) {
            const serializedState = JSON.stringify(state);
            EnvironmentStorage.setItem('MagementState', serializedState);
        }
    } catch (e) {
        // silently fail
    }
};

// Create Redux store.
const store = createStore(reducer, loadState(), applyMiddleware(thunk));

store.subscribe(throttle(() => {
    let copy = JSON.stringify(store.getState());
    copy = JSON.parse(copy);

    // The Magement API currently doesn't support storing preferences.
    // All preferences and API results are stored in local storage.
    // Sensitive information is removed before saving.
    forEach(copy.filter.rules, (value, rule) => {
        JSONPath.apply(copy, rule, () => value);
    });

    saveState(copy);
}, 250));

const languages = ['en'];
store.dispatch(initialize(languages));
store.dispatch(addTranslation(phrases));

export default store;
