/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import JSONInput from 'react-json-editor-ajrm/index';
import locale from 'react-json-editor-ajrm/locale/en';
import FileDropReadOut from './FileDropReadOut';

/**
 * JSON edit field.
 *
 * @param {string} value
 * @param {function} onChange
 *
 * @returns {object}
 */
class JsonEditField extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        };

        this.dropWrapRef = React.createRef();
        this.busy = React.createRef();
        this.onDrop = this.onDrop.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onChange = this.onChange.bind(this);

        // default hint
        this.defHint = "Type for auto validation or drag and drop a valid JSON file. Click on test when you're done";
    }

    /**
     * Check if the user is changing the content of the editor
     *
     */
    onChange(val) {
        this.setState({
            value: val.jsObject,
        });

        const busy = this.busy.current;
        busy.classList.add('hide');

        setTimeout(() => {
            busy.classList.remove('hide');
            busy.innerHTML = this.defHint;
        }, 3000);

        this.setState({
            value: val.jsObject,
        }, () => this.props.onChange(JSON.stringify(this.state.value)));
    }

    /**
     * Add wrapping style class when drag starts
     *
     * @param {object} event
     *
     */
    onDragEnter(e) {
        e.preventDefault();
        this.dropWrapRef.current.classList.add('show-json-dropzone');
    }

    /**
     * Remove wrapping style class when drag stops
     *
     * @param {boolean}
     *
     */
    onDragLeave(bool) {
        if (bool === false) {
            this.dropWrapRef.current.classList.remove('show-json-dropzone');
        }
    }

    /**
     * File drop value parsed to state
     *
     * @param {val} data
     *
     * @return {void}
     */
    onDrop(val) {
        let value = {};
        const busy = this.busy.current;
        // Clear value so new value will always be inserted
        this.setState({
            value,
        });

        // check if the file contains valid JSON
        try {
            value = JSON.parse(val);
            busy.innerHTML = this.defHint;
        } catch (e) {
            busy.innerHTML = 'Invalid JSON file. Please add its content manually';
        }

        value = JSON.parse(val);

        this.setState({
            value,
        });
    }

    /**
     * Render component and its children.
     *
     * @return {object}
     */
    render() {
        const { value } = this.state;


        return (
            <>
                <div
                    className="border position-relative"
                    onDragEnter={e => this.onDragEnter(e)}
                    ref={this.dropWrapRef}
                >
                    <FileDropReadOut
                        fileReadOut={val => this.onDrop(val)}
                        highLight={bool => this.onDragLeave(bool)}
                        fileType="json"
                        hideUploadButton
                    />
                    <JSONInput
                        locale={locale}
                        theme="light_mitsuketa_tribute"
                        height="450px"
                        width="100%"
                        placeholder={value}
                        waitAfterKeyPress={2000}
                        onChange={val => this.onChange(val)}
                    />
                </div>
                <div className="json-input-hint" ref={this.busy}>
                    {this.defHint}
                </div>
            </>
        );
    }
}

export default JsonEditField;
