/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import '../Table/Pagination.css';

/**
 * Show a title with optional buttons.
 *
 * @param {string} title
 * @param {array} children
 * @param {boolean} sticky
 *
 * @return {object}
 */
const Title = ({
    title, children, sticky, subTitle,
}) => {
    const isSticky = (sticky) ? 'sticky-header' : 'header';

    if (!title && !children) {
        return (
            <div className="pb-4" />
        );
    }

    return (
        <div className={`row ${isSticky} mt-2`}>
            <div className="col-md-6 p-0">
                <h4 className="page-title mt-2">
                    { title }
                    { subTitle }
                </h4>
            </div>
            <div className="col-md-6 p-0">
                { children }
            </div>
        </div>
    );
};

Title.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    sticky: PropTypes.bool,
};

Title.defaultProps = {
    title: '',
    children: undefined,
    sticky: true,
};

export default Title;
