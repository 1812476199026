/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';
import './Button.css';

/**
 * Save button.
 *
 * @param bool isSubmitting
 *
 * @return {object}
 */
const SwapButton = ({
    isSubmitting,
    onClick,
    className,
}) => {
    const icon = !isSubmitting
        ? <IonIcon icon="swap" hasText={false} />
        : <IonIcon icon="hourglass" hasText={false} />;

    return (
        <Button
            color="primary"
            disabled={isSubmitting}
            onClick={onClick}
            className={className}
        >
            { icon }
        </Button>
    );
};

SwapButton.propTypes = {
    isSubmitting: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

SwapButton.defaultProps = {
    isSubmitting: false,
    className: '',
};

export default SwapButton;
