/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

// Register available UI widgets.
import ParametersWidget from './ParametersWidget';
import Encryptable from './Encryptable';

const Widgets = {
    parameters: ParametersWidget,
    encryptable: Encryptable,
};

export default Widgets;
