/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import IonIcon from './IonIcon';

const EncryptedIcon = () => (
    <IonIcon icon="lock" />
);

export default EncryptedIcon;
