/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Show mapping source label.
 *
 * @param {string} remoteValue
 * @param {string} localValue
 *
 * @returns {object}
 */
const Source = ({ remoteValue, localValue }) => {
    return (
        <div>
            { `${remoteValue}  (${localValue})`}
        </div>
    );
};

Source.propTypes = {
    remoteValue: PropTypes.string.isRequired,
    localValue: PropTypes.string.isRequired,
};

export default Source;
