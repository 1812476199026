/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import GenericTransformer from './GenericTransformer';
import GenericTransformerList from './GenericTransformerList';
import { TYPE_ARRAY, TYPE_OBJECT } from './index';

class FieldFactory {
    static create(data, props) {
        switch (data.type) {
            case TYPE_OBJECT:
                return (<GenericTransformer {...props} />);
            case TYPE_ARRAY:
                return (<GenericTransformerList {...props} />);
            default:
                // TODO: Replace with exception when type can be resolved from API.
                // Field types are resolved by their name in `magement.json`.
                // As a result some false positives can occur. When this is
                // the case a regular form element should be used.
                return (
                    <>
                        <label htmlFor={props.name}>{props.name}</label>
                        <SchemaField
                            schema={data}
                            registry={props.registry}
                            onChange={props.onChange}
                            formData={props.formData}
                        />
                    </>
                );
        }
    }
}

export default FieldFactory;
