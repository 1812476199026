/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { DropdownItem } from 'reactstrap';
import SelectEnvironment from '../Ui/Buttons/SelectEnvironment';

class EnvironmentList extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Reload the application when a user switches environments.
     *
     * @param {string} environment
     */
    handleClick(environment) {
        window.location.href = `/${environment}`;
    }

    /**
     * Render the component.
     *
     * @returns {object}
     */
    render() {
        return (
            <>
                <DropdownItem header>Environments</DropdownItem>
                {
                    this.props.environments.map((environment) => {
                        const className = classNames({
                            'notify-item': true,
                            'pt-1': true,
                            'pb-1': true,
                            clickable: this.props.environment !== environment,
                        });

                        return (
                            <DropdownItem
                                className={className}
                                key={`environment-${environment}`}
                                onClick={() => this.handleClick(environment)}
                            >
                                <SelectEnvironment
                                    environment={environment}
                                    current={this.props.environment}
                                    onClick={this.handleClick}
                                />
                            </DropdownItem>
                        );
                    })
                }
            </>
        );
    }
}

EnvironmentList.propTypes = {
    environment: PropTypes.string.isRequired,
    environments: PropTypes.array,
};

EnvironmentList.defaultProps = {
    environments: [],
};

export default withRouter(EnvironmentList);
