/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Configuration, Type } from '../Magement';
import Route from './PrivateRoute';

const configurationsBasePath = '/:environment/publishers/configurations';
const typesBasePath = '/:environment/publishers/types';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Publishers = () => (
    <Switch>
        <Route
            exact
            path={`${configurationsBasePath}/create`}
            render={
                () => (
                    <Configuration.Create
                        tag="Publishers"
                        operationIds={{
                            create: 'createPublisherConfiguration',
                            read: 'getPublisherConfiguration',
                            update: 'updatePublisherConfiguration',
                            delete: 'deletePublisherConfiguration',
                        }}
                        entity="PublisherConfiguration"
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${configurationsBasePath}/:identifier`}
            render={
                props => (
                    <Configuration.Details
                        tag="Publishers"
                        operationId="getPublisherConfiguration"
                        operationIds={{
                            read: 'getPublisherConfiguration',
                            update: 'updatePublisherConfiguration',
                            delete: 'deletePublisherConfiguration',
                        }}
                        entity="PublisherConfiguration"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={configurationsBasePath}
                    />
                )
            }
        />
        <Route
            exact
            path={typesBasePath}
            render={() => (
                <Type.Overview
                    id="PublishersTypes"
                    tag="Publishers"
                    operationId="getPublisherTypes"
                />
            )}
        />
        <Route
            exact
            path={`${configurationsBasePath}`}
            render={() => (
                <Configuration.Overview
                    id="PublishersConfigurations"
                    tag="Publishers"
                    operationId="getPublisherConfigurations"
                    createOperationId="createPublisherConfiguration"
                />
            )}
        />
        <Route
            exact
            path={`${typesBasePath}/:identifier`}
            render={
                props => (
                    <Type.Details
                        configuration={{
                            id: 'PublishersTypes',
                            tag: 'Publishers',
                            operationId: 'getPublisherTypes',
                        }}
                        tag="Publishers"
                        operationIds={{
                            read: 'getPublisherType',
                        }}
                        entity="PublisherType"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={typesBasePath}
                    />
                )
            }
        />
    </Switch>
);

export default Publishers;
