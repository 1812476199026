/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import TransformerDocumentation from '../Magement/Transformer';
import Route from './PrivateRoute';

const basePath = '/:environment/documentation';

const Documentation = () => (
    <Switch>
        <Route
            extact
            path={`${basePath}/transformers`}
            render={() => (<TransformerDocumentation />)}
        />
    </Switch>
);

export default Documentation;
