/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import Config from '../../core/Config';

const mapDispatchToProps = dispatch => ({
    onChangeLimit: (id, limit) => {
        dispatch({
            type: 'CHANGE_LIMIT',
            limit,
            id,
        });
    },
});

class Limit extends React.Component {
    /**
     * Constructor.
     *
     * @param {props} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    /**
     * Toggle menu state.
     *
     * @return {void}
     */
    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    /**
     * Render limits.
     *
     * @returns {object}
     */
    render() {
        return (
            <ButtonDropdown
                isOpen={this.state.isOpen}
                toggle={() => this.toggle()}
            >
                <DropdownToggle
                    caret
                    size="sm"
                    color="btn btn-outline-secondary"
                >
                    {this.props.table.limit}
                </DropdownToggle>
                <DropdownMenu>
                    {Config.api.availablePages.map(page => (
                        <DropdownItem
                            key={`page-${page}`}
                            onClick={() => {
                                this.props.onChangeLimit(
                                    this.props.table.id,
                                    page,
                                );
                            }}
                        >
                            {page}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(Limit);
