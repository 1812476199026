/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import { version as nextVersion } from '../../../package.json';
import EnvironmentStorage from '../Environment/EnvironmentStorage';

const MAGEMENT_STATE = 'MagementState';
const MAGEMENT_VERSION = 'MagementVersion';

/**
 * Remove Magement Dashboard local storage.
 */
const clearMagementState = () => {
    EnvironmentStorage.clearAllEnvironments();
};

/**
 * Get current Magement Dashboard version.
 *
 * @return {string}
 */
const getMagementVersion = () => {
    return EnvironmentStorage.getItem(MAGEMENT_VERSION, false);
};

/**
 * Set the version of Magement Dashboard.
 *
 * @param version
 */
const setMagementVersion = (version) => {
    EnvironmentStorage.setItem(MAGEMENT_VERSION, version, false);
};

/**
 * Get state from local storage.
 *
 * @return {object}
 */
const getStateFromLocalStorage = () => {
    try {
        const serializedState = EnvironmentStorage.getItem(MAGEMENT_STATE);

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState || '{}');
    } catch (e) {
        return {};
    }
};

/**
 * Get initial state.
 *
 * @return {object}
 */
const getApplicationState = () => {
    if (getMagementVersion() === nextVersion) {
        return getStateFromLocalStorage();
    }

    clearMagementState();
    setMagementVersion(nextVersion);

    return {};
};

export default getApplicationState;
export {
    getMagementVersion,
    MAGEMENT_STATE,
    MAGEMENT_VERSION,
};
