/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import FieldFactory from './FieldFactory';

const Mapper = (props) => {
    const additionalProps = {
        tag: 'Transformers',
        operationId: 'getMappers',
        types: { object: 'mapper', array: 'mappers' },
        ...props,
    };

    return FieldFactory.create(props.schema, additionalProps);
};

export default Mapper;
