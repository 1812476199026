/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Show count down.
 *
 * @param {int} initial
 * @param {int} time
 * @param {string} sentence
 *
 * @return {object}
 */
const CountDown = ({ initial, time, sentence }) => {
    const left = initial - time;
    return (
        <span className="last-update">
            {sentence.replace('%s', Math.max(0, left))}
        </span>
    );
};

CountDown.propTypes = {
    initial: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
    sentence: PropTypes.string.isRequired,
};

export default CountDown;
