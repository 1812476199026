/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import {
    SCHEMA_LOADED,
    SCHEMAS_LOADED,
    UI_SCHEMA_LOADED,
} from './actions';

const schemaReducer = (
    state = {
        schema: null,
        uiSchema: null,
    },
    action,
) => {
    switch (action.type) {
        case SCHEMA_LOADED:
            return {
                ...state,
                schema: action.schema,
            };
        case UI_SCHEMA_LOADED:
            return {
                ...state,
                uiSchema: action.uiSchema,
            };
        case SCHEMAS_LOADED:
            return {
                ...state,
                schema: action.schema,
                uiSchema: action.uiSchema,
            };
        default:
            return state;
    }
};

export default schemaReducer;
