/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';

class ParameterField extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle form changes.
     *
     * @param {object} data
     */
    handleChange(data) {
        this.props.onChange(data);
    }

    /**
     * Render form.
     *
     * @returns {object}
     */
    render() {
        return (
            <>
                <SchemaField
                    schema={this.props.formContext.prototype.schema}
                    onChange={this.handleChange}
                    registry={this.props.registry}
                    formData={this.props.formData}
                />
            </>
        );
    }
}

export default ParameterField;
