/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import { apiResultType } from '../../../validation';

/**
 * Show list based on provided filter.
 *
 * @param {array} items
 * @param {string} query
 * @param {array} searchable
 * @param {func} render
 *
 * @return {object}
 */
const FilteredList = ({
    items,
    query,
    searchable,
    render,
}) => {
    const filtered = [];
    items.forEach((item) => {
        const match = searchable.reduce((carry, key) => (
            carry
                ? true
                : item[key].toLowerCase().indexOf(query.toLowerCase()) !== -1
        ), false);

        if (match) {
            filtered.push(item);
        }
    });

    return (
        <List items={filtered} render={render} />
    );
};

FilteredList.propTypes = {
    items: apiResultType.isRequired,
    query: PropTypes.string.isRequired,
    searchable: PropTypes.arrayOf(PropTypes.string).isRequired,
    render: PropTypes.func.isRequired,
};

export default FilteredList;
