/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';

const EmptyLogMessage = () => {
    return (
        <article className="timeline-item">
            <div className="timeline-desk">
                <div className="panel">
                    <div className="timeline-box">
                        <span className="arrow" />
                        <span className="timeline-icon bg-primary">
                            <i className="mdi mdi-checkbox-blank-circle-outline" />
                        </span>
                        <h4>No messages</h4>
                        <p>There are no messages available for this task.</p>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default EmptyLogMessage;
