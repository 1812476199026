/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';
import Link from '../../Routes/PrefixedLink';

/**
 * Button to add new entity.
 *
 * @param {string} to
 * @param {bool} inline
 *
 * @return {object}
 */
const AddButton = ({ to, inline }) => (
    <Link to={to}>
        <Button color="success">
            <IonIcon icon="add-circle" hasText={!inline} />
            { !inline && 'Add' }
        </Button>
    </Link>
);

AddButton.propTypes = {
    to: PropTypes.string.isRequired,
    inline: PropTypes.bool,
};

AddButton.defaultProps = {
    inline: true,
};

export default AddButton;
