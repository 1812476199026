/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import './TextLoadingIndicator.scss';

/**
 * Show indication that a component is loading text.
 *
 * @param {number} width
 * @param {string} mode
 *
 * @returns {object}
 */
const TextLoadingIndicator = ({ width, mode }) => {
    const style = {
        width: `${width}${mode}`,
    };

    return (
        <span style={style} className="text-loading-indicator">loading</span>
    );
};

TextLoadingIndicator.propTypes = {
    width: PropTypes.number.isRequired,
    mode: PropTypes.oneOf(['px', '%']).isRequired,
};

export default TextLoadingIndicator;
