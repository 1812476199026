/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';
import './Button.css';

const AcceptButton = ({ onAccept }) => {
    return (
        <Button outline color="success" onClick={onAccept}>
            <IonIcon icon="checkmark" hasText={false} />
        </Button>
    );
};

AcceptButton.propTypes = {
    onAccept: PropTypes.func.isRequired,
};

export default AcceptButton;
