/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const DateWidget = ({ onChange, value }) => {
    const formValue = value
        ? new Date(value)
        : null;

    return (
        <DatePicker
            selected={formValue}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd-MM-yyyy, HH:mm"
            timeCaption="time"
            onChange={formData => (onChange(formData !== null ? formData.toISOString() : null))}
        />
    );
};

export default DateWidget;
