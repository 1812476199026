/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import RefreshButton from '../../../Ui/Buttons/RefreshButton';
import { getMappingData } from '../../../../reducers/mapping/actions';

const Reload = ({ dispatch, disabled }) => {
    return (
        <RefreshButton
            disabled={disabled}
            onClick={() => dispatch(getMappingData())}
            color="primary"
            outline={false}
        />
    );
};

export default connect()(Reload);
