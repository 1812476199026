/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import omit from 'lodash/omit';
import {
    ADD_MAPPING,
    ADD_MAPPING_VALUE,
    UNDO_MAPPING,
    UNDO_MAPPING_VALUE,
    CLEAR_MAPPING,
    CLEAR_MAPPING_VALUES,
    REMOVE_MAPPING,
    REMOVE_MAPPING_VALUE,
    SELECT_MAPPING_TYPE,
    SELECT_MAPPING_SOURCE,
    SELECT_MAPPING_DESTINATION,
    RECEIVE_MAPPING,
    RECEIVE_MAPPING_VALUES,
    RECEIVE_MAPPING_VALUE_SUGGESTIONS,
    RECEIVE_SUGGESTIONS,
    SELECT_MAPPING_KEY, SWAP_KEY_MAPPING,
} from './actions';

const mappingReducer = (
    state = {
        data: [],
        suggestions: [],
        keyMapping: {},
        valueMapping: {},
        deletions: {},
        type: null,
        source: null,
        destination: null,
        currentMappingKey: {},
        remoteMappingKey: {},
        mappingValues: [],
        mappingValueSuggestions: [],
    },
    action,
) => {
    switch (action.type) {
        case ADD_MAPPING:
            return {
                ...state,
                keyMapping: {
                    ...state.keyMapping,
                    [action.from]: {
                        from: action.from,
                        to: action.to,
                        type: action.mappingType,
                        source: action.source,
                        destination: action.destination,
                    },
                },
                deletions: omit(state.deletions, action.from),
            };
        case ADD_MAPPING_VALUE:
            return {
                ...state,
                valueMapping: {
                    ...state.valueMapping,
                    [action.from]: {
                        from: action.from,
                        to: action.to,
                        type: action.mappingType,
                        source: action.source,
                        destination: action.destination,
                    },
                },
                valueDeletions: omit(state.valueDeletions, action.from),
            };
        case REMOVE_MAPPING:
            return {
                ...state,
                deletions: {
                    ...state.deletions,
                    [action.from]: {
                        from: action.from,
                        to: action.to,
                        type: action.mappingType,
                        source: action.source,
                        destination: action.destination,
                    },
                },
            };
        case REMOVE_MAPPING_VALUE:
            return {
                ...state,
                valueDeletions: {
                    ...state.valueDeletions,
                    [action.from]: {
                        from: action.from,
                        to: action.to,
                        type: action.mappingType,
                        source: action.source,
                        destination: action.destination,
                    },
                },
            };
        case RECEIVE_MAPPING:
            return {
                ...state,
                data: action.mapping,
            };
        case RECEIVE_MAPPING_VALUES:
            return {
                ...state,
                mappingValues: action.mapping,
            };
        case SELECT_MAPPING_TYPE:
            return {
                ...state,
                type: action.mappingType,
            };
        case SELECT_MAPPING_SOURCE:
            return {
                ...state,
                source: action.mappingSource,
            };
        case SELECT_MAPPING_DESTINATION:
            return {
                ...state,
                destination: action.mappingDestination,
            };
        case RECEIVE_SUGGESTIONS:
            return {
                ...state,
                suggestions: action.suggestions,
            };
        case RECEIVE_MAPPING_VALUE_SUGGESTIONS:
            return {
                ...state,
                mappingValueSuggestions: action.suggestions,
            };
        case UNDO_MAPPING:
            return {
                ...state,
                keyMapping: omit(state.keyMapping, action.id),
                deletions: omit(state.keyMapping, action.id),
            };
        case UNDO_MAPPING_VALUE:
            return {
                ...state,
                valueMapping: omit(state.valueMapping, action.id),
                valueDeletions: omit(state.valueMapping, action.id),
            };
        case CLEAR_MAPPING:
            return {
                ...state,
                keyMapping: {},
                deletions: {},
            };
        case CLEAR_MAPPING_VALUES:
            return {
                ...state,
                valueMapping: {},
                valueDeletions: {},
            };
        case SELECT_MAPPING_KEY:
            return {
                ...state,
                currentMappingKey: {
                    mappingKey: action.mappingKey,
                    remoteKey: action.remoteKey,
                    type: action.mappingType,
                    source: action.source,
                    destination: action.destination,
                },
            };
        case SWAP_KEY_MAPPING:
            return {
                ...state,
                source: state.destination,
                destination: state.source,
            };
        default:
            return state;
    }
};

export default mappingReducer;
