/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import FieldFactory from './FieldFactory';

const Filter = (props) => {
    const additionalProps = {
        tag: 'Transformers',
        operationId: 'getDataFilterTypes',
        ...props,
    };

    return FieldFactory.create(props.schema, additionalProps);
};

export default Filter;
