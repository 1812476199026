/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import alert from './assets/alert.svg';
import healthy from './assets/healthy.svg';

/**
 * Show status of health report.
 *
 * @param {bool} isHealthy
 * @param {string} name
 *
 * @returns {object}
 */
const Status = ({
    isHealthy,
    name,
}) => {
    return (
        <div className={`health-report ${isHealthy ? 'healthy' : 'color-danger'}`}>
            <img alt="Health indicator" src={isHealthy ? healthy : alert} height="66" />
            <p className="p-0 mt-3 mb-0">{ name }</p>
        </div>
    );
};

Status.propTypes = {
    isHealthy: PropTypes.bool.isRequired,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

export default Status;
