/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Environment, Configuration } from '../Magement';
import {
    encryptedRenderer,
    fieldRendererFactory,
    variableRenderer,
} from '../../core/Ui/Renderers';
import Route from './PrivateRoute';

const basePath = '/:environment/environment/variables';

const renderers = [
    fieldRendererFactory(encryptedRenderer, 'value'),
    fieldRendererFactory(variableRenderer, 'name'),
];

/**
 * Route definitions.
 *
 * @return {object}
 */
const Routes = () => (
    <Switch>
        <Route
            exact
            path={basePath}
            render={() => (
                <Environment.Overview
                    title="Environment variables"
                    basePath={basePath}
                />
            )}
        />
        <Route
            exact
            path={`${basePath}/create`}
            render={
                () => (
                    <Environment.Create
                        tag="Environment"
                        operationIds={{
                            create: 'createEnvironmentVariable',
                        }}
                        entity="EnvironmentVariable"
                        basePath={basePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${basePath}/candidates`}
            render={
                () => (
                    <Environment.Candidates />
                )
            }
        />
        <Route
            exact
            path={`${basePath}/:identifier`}
            render={
                props => (
                    <Configuration.Details
                        tag="Environment"
                        operationIds={{
                            read: 'getEnvironmentVariable',
                            update: 'updateEnvironmentVariable',
                            delete: 'deleteEnvironmentVariable',
                        }}
                        entity="EnvironmentVariable"
                        params={{ variable: props.match.params.identifier }}
                        basePath={basePath}
                        renderers={renderers}
                    />
                )
            }
        />
    </Switch>
);

export default Routes;
