/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Button } from 'reactstrap';
import { IonIcon } from '../Icons';

/**
 * Button to cancel an action.
 *
 * @param {function} onCancel
 *
 * @return {object}
 */
const CancelButton = ({ onCancel }) => (
    <Button color="secondary" onClick={() => onCancel()}>
        <IonIcon icon="undo" hasText />
        Cancel
    </Button>
);

export default CancelButton;
