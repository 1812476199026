/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import HttpClient from '../../../../core/HttpClient';
import Select from './Magement/Select';

class MagementField extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            prototype: undefined,
            prototypes: [],
            configurations: [],
        };

        // Promises can resolve after the component is unmounted.
        // Keep track of the mount status of the component and
        // only update state when the component is mounted.
        this.componentIsMounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handlePrototypeSelect = this.handlePrototypeSelect.bind(this);
        this.handleConfigurationSelect = this.handleConfigurationSelect.bind(this);
    }

    /**
     * Get available prototypes.
     */
    componentDidMount() {
        this.componentIsMounted = true;

        HttpClient.request('getInterfaceList', { interface: this.props.schema.magement.interface })
            .then((response) => {
                this.setState({
                    prototypes: response.prototypes,
                    prototype: undefined,
                    configurations: response.configurations,
                });
            })
            .then(() => {
                if (this.props.formData && this.props.formData.type) {
                    this.handlePrototypeSelect(this.props.formData.type);
                }
            });
    }

    /**
     * Mark the component as unmounted.
     */
    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    /**
     * Only set state when the component is mounted.
     *
     * @param state
     * @param callback
     */
    setState(state, callback) {
        if (this.componentIsMounted) {
            super.setState(state, callback);
        }
    }

    /**
     * Get formatted form data.
     *
     * @param {formData} formData
     *
     * @returns {object}
     */
    getFormData(formData) {
        return {
            ...formData,
            type: this.state.prototype.identifier,
        };
    }

    /**
     * Get formatted schema.
     *
     * @param original
     *
     * @returns {object}
     */
    getSchema(original) {
        return {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                parameters: {
                    ...original,
                },
            },
        };
    }

    /**
     * Get current value.
     *
     * @returns {object|string|undefined}
     */
    getCurrentValue() {
        if (this.state.prototype) {
            return this.state.prototype;
        }

        return this.state.configurations.find((subject) => {
            return subject.identifier === this.props.formData;
        });
    }


    /**
     * Select a prototype.
     *
     * @param {string} prototype
     */
    handlePrototypeSelect(prototype) {
        this.setState((prevState) => {
            const proto = prevState.prototypes.find((subject) => {
                return subject.identifier === prototype;
            });

            return { prototype: proto || undefined };
        });

        if (this.props.formData) {
            this.props.onChange({
                type: prototype,
                parameters: this.props.formData.parameters,
            });
        }
    }

    /**
     * Select a configuration.
     *
     * @param {string} configuration
     */
    handleConfigurationSelect(configuration) {
        this.setState({
            prototype: undefined,
        }, this.props.onChange(configuration));
    }

    /**
     * Notify parent of form changes.
     *
     * @param {object} data
     */
    handleChange(data) {
        this.props.onChange(data);
    }

    /**
     * Render the component.
     *
     * @returns {object}
     */
    render() {
        const { prototypes, configurations } = this.state;
        const value = this.getCurrentValue();

        return (
            <>
                <Select
                    value={value}
                    prototypes={prototypes}
                    configurations={configurations}
                    onSelectPrototype={this.handlePrototypeSelect}
                    onSelectConfiguration={this.handleConfigurationSelect}
                />
                {
                    this.state.prototype && (
                        <SchemaField
                            uiSchema={{ type: { 'ui:disabled': true } }}
                            schema={this.getSchema(this.state.prototype.schema)}
                            onChange={this.handleChange}
                            registry={this.props.registry}
                            formData={this.getFormData(this.props.formData)}
                        />
                    )
                }
            </>
        );
    }
}

export default MagementField;
