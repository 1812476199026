/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import ViewButton from '../../../../Ui/Buttons/ViewButton';
import Link from '../../../../Routes/PrefixedLink';

const ViewValueMapping = ({
    mappingKey,
    remoteKey,
    type,
    source,
    destination,
    dispatch,
    onClick,
}) => {
    return (
        <Link
            onClick={() => dispatch(onClick(mappingKey, remoteKey, type, source, destination))}
            to="/mapping/values"
        >
            <ViewButton className="ml-2" />
        </Link>
    );
};

export default connect()(ViewValueMapping);
