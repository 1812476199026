/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import Form from 'react-jsonschema-form';
import Bootstrap4Template from '../../Ui/Form/Bootstrap4Template';
import ArrayTemplate from '../../Ui/Form/ArrayTemplate';
import Widgets from '../../Ui/Form/Widgets';
import { SaveButton } from '../../Ui/Buttons';
import { apiDataPropType, schemaPropType } from '../../../validation';
import { PARAMETERS_PROPERTY } from '../../../core/JsonSchema';
import Fields from '../Transformer/Form/Fields';

/**
 * Entity create form.
 *
 * @param {object} data
 * @param {object} schema
 * @param {function} onChange
 * @param {function} onSubmit
 * @param {boolean} isSubmitting
 * @param {object} uiSchema
 *
 * @return {object}
 */
const EntityForm = ({
    data, schema, onChange, onSubmit, isSubmitting, uiSchema,
}) => {
    const userInterfaceSchema = {
        parameters: {
            'ui:widget': 'parameters',
        },
        email: {
            'ui:options': {
                inputType: 'email',
            },
        },
        ...uiSchema,
    };

    // Always show parameter at the bottom of the form.
    if (has(schema.properties, PARAMETERS_PROPERTY)) {
        userInterfaceSchema['ui:order'] = ['*', PARAMETERS_PROPERTY];
    }

    return (
        <Form
            fields={Fields}
            FieldTemplate={Bootstrap4Template}
            ArrayFieldTemplate={ArrayTemplate}
            onChange={form => onChange(form)}
            onSubmit={form => onSubmit(form)}
            schema={schema}
            formData={data}
            uiSchema={userInterfaceSchema}
            widgets={Widgets}
            formContext={{ schema, data }}
        >
            <SaveButton isSubmitting={isSubmitting} />
        </Form>
    );
};

EntityForm.propTypes = {
    data: apiDataPropType.isRequired,
    schema: schemaPropType.isRequired,
    uiSchema: schemaPropType,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
};

EntityForm.defaultProps = {
    isSubmitting: false,
    uiSchema: {},
};

export default EntityForm;
