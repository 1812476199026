/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import PageHeader from '../../Ui/PageHeader';
import { BackButton } from '../../Ui/Buttons';
import EntityForm from './EntityForm';
import { apiDataPropType, schemaPropType } from '../../../validation';
import { EMPTY_SCHEMA } from '../../../core/JsonSchema';
import store from '../../../store';
import { ErrorMessage } from '../../Ui/Messages';
import Tabs from '../../Ui/Tabs';

/**
 *
 * @param {string} basePath
 * @param {object} data
 * @param {object} schema
 * @param {function} onChange
 * @param {function} onSubmit
 * @param {string} tag
 * @param {object} operationIds
 * @param {object} api
 * @param {string} title
 * @param {boolean} backButton
 * @param {object} uiSchema
 * @param {actions} actions
 * @param {array} tabs
 *
 * @return {object}
 */
const EntityCreate = ({
    basePath,
    data,
    schema,
    onChange,
    onSubmit,
    tag,
    operationIds,
    api,
    title,
    backButton,
    uiSchema,
    actions,
    tabs,
}) => {
    let contentTabs = [
        {
            id: 'create',
            name: 'Create',
            content: () => (
                <EntityForm
                    isSubmitting={api.isRequesting}
                    data={data}
                    schema={schema}
                    uiSchema={uiSchema}
                    onChange={form => onChange(form.formData)}
                    onSubmit={(form) => {
                        onSubmit(form.formData, tag, operationIds.create);
                    }}
                />
            ),
        },
    ];

    contentTabs = contentTabs.concat(tabs);

    return (
        <div>
            <PageHeader title={`Create ${title || tag}`}>
                {actions}
                {backButton && <BackButton to={basePath} />}
            </PageHeader>
            <ErrorMessage
                message={api.message}
                isOpen={api.isError}
                onClose={() => store.dispatch({ type: 'CLOSE_ERROR_MESSAGE' })}
            />
            <Card>
                <CardBody className="p-0">
                    <Tabs
                        tabs={contentTabs}
                        default="create"
                    />
                </CardBody>
            </Card>
        </div>
    );
};

EntityCreate.propTypes = {
    basePath: PropTypes.string.isRequired,
    data: apiDataPropType,
    schema: schemaPropType,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    tag: PropTypes.string.isRequired,
    title: PropTypes.string,
    backButton: PropTypes.bool,
    operationIds: PropTypes.objectOf(PropTypes.string).isRequired,
    api: PropTypes.oneOfType([
        PropTypes.object,
    ]),
    uiSchema: schemaPropType,
    actions: PropTypes.arrayOf(PropTypes.object),
    tabs: PropTypes.array,
};

EntityCreate.defaultProps = {
    onChange: () => {},
    onSubmit: () => {},
    backButton: true,
    data: {},
    schema: EMPTY_SCHEMA,
    uiSchema: {},
    api: {},
    title: null,
    actions: undefined,
    tabs: [],
};

export default EntityCreate;
