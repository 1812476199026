/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import ToggleButton from '../../Ui/Buttons/ToggleButton';

/**
 * Render a Toggle column.
 *
 * @param {function} onClick
 * @param {bool} status
 *
 * @return {object}
 */
const ToggleColumn = ({ onClick, enabled }) => (
    <td>
        <ToggleButton
            onClick={onClick}
            enabled={enabled}
        />
    </td>
);

export default ToggleColumn;
