/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Clear a filter.
 *
 * @param {object} props
 *
 * @return {object}
 */
const ClearFilterInline = (props) => {
    const { onClick } = props;

    return (
        <button
            type="button"
            className="badge badge-primary ml-2 clickable"
            onClick={() => onClick()}
        >
            Clear Filter
        </button>
    );
};

ClearFilterInline.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ClearFilterInline;
