/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AcceptButton from '../../../Ui/Buttons/AcceptButton';

/**
 * Accept suggested value.
 *
 * @param {integer} source
 * @param {integer} destination
 * @param {function} onAccept
 *
 * @returns {object}
 */
const AcceptSuggestion = ({
    source,
    destination,
    dispatch,
    onClick,
}) => {
    return (
        <AcceptButton
            onAccept={() => dispatch(onClick(source, destination))}
        />
    );
};

AcceptSuggestion.propTypes = {
    source: PropTypes.number.isRequired,
    destination: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(AcceptSuggestion);
