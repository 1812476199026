/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { connect } from 'react-redux';
import confirm from 'reactstrap-confirm';
import { RetryButton } from '../../Ui/Buttons';
import HttpClient from '../../../core/HttpClient';
import FeatureDetection from '../../../core/FeatureDetect';

const mapStateToProps = state => ({
    schema: state.schema.schema,
});

class Retry extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isRequesting: false,
            hasError: false,
        };

        this.featureDetection = new FeatureDetection(props.schema);
    }

    /**
     * Retry a task
     *
     * @return {Promise}
     */
    onRetry() {
        this.setState({ isRequesting: true, hasError: false });
        return HttpClient.backgroundRequest(
            this.props.operationId,
            null,
            this.props.formData,
        ).then((response) => {
            this.setState({ isRequesting: false });
            if (typeof this.props.onSuccess === 'function') {
                this.props.onSuccess(response);
            }
        }).catch((error) => {
            this.setState({ isRequesting: false, hasError: true });
            if (typeof this.props.onError === 'function') {
                const msg = error.message
                    ? error.message
                    : 'Unexpected error while retrying.';
                this.props.onError(msg);
            }
        });
    }

    /**
     * Confirm retrying a task
     */
    async confirmRetry() {
        const numTasks = this.props.formData.taskIds.length;
        const confirmed = await confirm({
            title: 'Retry',
            message: numTasks === 1
                ? 'Are you sure you want to retry this task?'
                : `Are you sure you want to retry ${numTasks} tasks?`,
        });

        if (confirmed) {
            this.onRetry();
        }
    }

    /**
     * Render delete button.
     *
     * @return {string}
     */
    render() {
        return this.featureDetection.hasOperationId('retryTasks') && (
            <RetryButton
                isSubmitting={this.state.isRequesting}
                hasError={this.state.hasError}
                onRetry={() => this.confirmRetry()}
                inline={this.props.inline}
                numItems={this.props.formData.taskIds.length}
            />
        );
    }
}

export default connect(mapStateToProps)(Retry);
