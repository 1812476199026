/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardHeader, Collapse } from 'reactstrap';
import FilteredList from '../FilteredList';
import SearchBar from '../SearchBar';
import { InlineTextButton } from '../../../Ui/Buttons';
import { selectType, fetchTypesIfNeeded } from '../../../../reducers/transformer/actions';
import TransformerType from '../TransformerType';

const mapStateToProps = state => ({
    items: state.transformer.items[state.transformer.identifier] || [],
    active: state.transformer.identifier,
});

class TransformerTypeContainer extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            query: '',
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleSearchQuery = this.handleSearchQuery.bind(this);
    }

    /**
     * Handle click event.
     *
     * @return {void}
     */
    handleClick() {
        this.props.dispatch(selectType(this.props.identifier));
        this.props.dispatch(fetchTypesIfNeeded(
            this.props.identifier,
            this.props.tag,
            this.props.operationId,
        ));
    }

    /**
     * Handle search query.
     *
     * @param {string} query
     *
     * @return {void}
     */
    handleSearchQuery(query) {
        this.setState({
            query,
        });
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        return (
            <Card>
                <CardHeader>
                    <InlineTextButton
                        index={0}
                        label={this.props.name}
                        onClick={this.handleClick}
                    />
                </CardHeader>
                <Collapse isOpen={this.props.identifier === this.props.active}>
                    <SearchBar filter={this.state.query} onChange={this.handleSearchQuery} />
                    <FilteredList
                        items={this.props.items}
                        query={this.state.query}
                        searchable={['identifier', 'name']}
                        render={(item, index) => (
                            <TransformerType
                                identifier={item.identifier}
                                name={item.name}
                                index={index}
                                schema={item.schema}
                            />
                        )}
                    />
                </Collapse>
            </Card>
        );
    }
}

TransformerTypeContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    identifier: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
};

TransformerTypeContainer.defaultProps = {
    items: [],
};

export default connect(mapStateToProps)(TransformerTypeContainer);
