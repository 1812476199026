/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Type } from '../Magement';
import Route from './PrivateRoute';

const basePath = '/:environment/entities/types';

/**
 * Route definitions.
 *
 * @return {object}
 */
const Entities = () => (
    <Switch>
        <Route
            exact
            path={`${basePath}/create`}
            render={
                () => (
                    <Type.Create
                        tag="Entities"
                        operationId="createEntityType"
                        entity="EntityType"
                        basePath={basePath}
                        operationIds={{
                            create: 'createEntityType',
                            read: 'getEntityType',
                            update: 'updateEntityType',
                            delete: 'deleteEntityType',
                        }}
                    />
                )
            }
        />
        <Route
            exact
            path={`${basePath}/:identifier`}
            render={
                props => (
                    <Type.Details
                        configuration={{
                            id: 'EntitiesTypes',
                            tag: 'Entities',
                            operationId: 'getEntityTypes',
                        }}
                        tag="Entities"
                        operationIds={{
                            read: 'getEntityType',
                        }}
                        entity="EntityType"
                        params={{ identifier: props.match.params.identifier }}
                        basePath={basePath}
                    />
                )
            }
        />
        <Route
            exact
            path={`${basePath}`}
            render={() => (
                <Type.Overview
                    id="EntitiesTypes"
                    tag="Entities"
                    operationId="getEntityTypes"
                />
            )}
        />
    </Switch>
);

export default Entities;
