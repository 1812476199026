/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CountUp from 'react-countup';
import uuid from 'uuid/v4';
import { getDefinitionByEntity } from '../../../core/JsonSchema';
import render, { KEY_MAPPING } from '../../../core/Ui';
import Interval from '../../Ui/Interval';
import CountDown from '../../Ui/CountDown';
import Redirect from '../../../core/Redirect';
import { fetchNumberOfTasks } from '../../../reducers/reporting/actions';
import ApiFilters from './ApiFilters';

const mapStateToProps = state => ({
    definitions: state.schema.schema.components.schemas,
    tasks: state.reporting.tasks,
});

class NumberOfTasksWidget extends React.Component {
    /**
     * Constructor.
     *
     * @param {object} props
     *
     * @return {void}
     */
    constructor(props) {
        super(props);

        this.state = {
            lastUpdate: 0,
            interval: 60,
        };

        this.isActive = false;
        this.identifier = uuid();
    }

    /**
     * Load initial data.
     *
     * @return {void}
     */
    componentDidMount() {
        this.isActive = true;

        this.getNumberOfTasks();
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    /**
     * When a user clicks the icon.
     * Set filter and redirect.
     *
     * @return {void}
     */
    onClick() {
        this.props.dispatch({
            type: 'APPLY_FILTER',
            id: 'TaskQueue',
            formData: ApiFilters[this.props.identifier],
        });

        Redirect(this.props.history, '/tasks/queue');
    }

    /**
     * When a user presses a key while focused on component.
     *
     * @param {object} event
     */
    onKeyUp(event) {
        if (event.keyCode === KEY_MAPPING.ENTER) {
            this.onClick();
        }
    }

    /**
     * Get the number of tasks.
     */
    getNumberOfTasks() {
        this.props.dispatch(
            fetchNumberOfTasks(this.props.identifier),
        );

        this.setState({
            lastUpdate: 0,
        });
    }

    /**
     * Set state only when component is mounted.
     *
     * @param {object|function} state
     * @param {function} callback
     */
    setState(state, callback) {
        if (this.isActive) {
            super.setState(state, callback);
        }
    }

    /**
     * Get title.
     *
     * @return {string}
     */
    getTitle() {
        return this.props.title
            ? this.props.title
            : `${render(
                this.props.status,
                getDefinitionByEntity(
                    this.props.definitions,
                    'Task',
                    'status',
                ),
                this.props.status,
                this.props.status,
            )} tasks`;
    }

    /**
     * Count the number of seconds since last update.
     *
     * @return {void}
     */
    increaseLastUpdate() {
        this.setState(prevState => ({
            lastUpdate: prevState.lastUpdate + 1,
        }));
    }

    /**
     * Render component.
     *
     * @return {object}
     */
    render() {
        return (
            <div
                className={`clickable p-4 ${this.props.className}`}
                role="button"
                tabIndex={0}
                onKeyUp={event => this.onKeyUp(event)}
                onClick={() => this.onClick()}
            >
                <h2 className="m-0">
                    <CountUp
                        className="m-0 text-white"
                        start={0}
                        end={this.props.tasks.statistics[this.props.identifier] || 0}
                        duration={1}
                    />
                </h2>
                <h4 className="text-white mt-0 mb-0">{this.getTitle()}</h4>
                {
                    this.props.autoRefresh
                    && (
                        <div>
                            <CountDown
                                initial={this.state.interval}
                                time={this.state.lastUpdate}
                                sentence="Refreshes in %s seconds."
                            />
                            <Interval
                                callback={() => this.getNumberOfTasks()}
                                time={this.state.interval}
                            />
                            <Interval callback={() => this.increaseLastUpdate()} time={1} />
                        </div>
                    )
                }
            </div>
        );
    }
}

NumberOfTasksWidget.propTypes = {
    autoRefresh: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
};

NumberOfTasksWidget.defaultProps = {
    title: false,
    autoRefresh: true,
};

export default withRouter(connect(
    mapStateToProps,
)(NumberOfTasksWidget));
