/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import Details from './Details';
import PageHeader from '../../Ui/PageHeader';
import BackButton from '../../Ui/Buttons/BackButton';

const mapStateToProps = state => ({
    tables: state.table.tables,
    schema: state.schema.schema,
});

/**
 * Show storage content.
 *
 * @param {object} props
 *
 * @return {object}
 */
const Overview = (props) => {
    const pageTitle = `${props.entityIdentifier} from storage ${props.identifier}`;
    return (
        <div>
            <PageHeader title={pageTitle}>
                <BackButton to={`${props.basePath}/${props.identifier}#entities`} />
            </PageHeader>
            <Card>
                <CardBody>
                    <Details {...props} />
                </CardBody>
            </Card>
        </div>
    );
};

Overview.propTypes = {
    tag: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    entityIdentifier: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
};

export default connect(
    mapStateToProps,
    null,
)(Overview);

export { Details };
