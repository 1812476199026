/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';

const OPENAPI_2 = 'OPENAPI_2';
const OPENAPI_3 = 'OPENAPI_3';

class OpenApi3Converter {
    /**
     * Constructor.
     *
     * @param {object} schema
     */
    constructor(schema) {
        this.schema = schema;
    }

    /**
     * Detect the OpenAPI version of a schema.
     *
     * @return {string}
     */
    getVersion() {
        if (has(this.schema, 'swagger')) {
            return OPENAPI_2;
        }

        return OPENAPI_3;
    }

    /**
     * Convert a schema from OpenAPI 2 to OpenAPI 3.
     *
     * @returns {object}
     */
    convert() {
        if (this.getVersion() === OPENAPI_3) {
            return this.schema;
        }

        const schema = cloneDeep(this.schema);

        // The component definitions have been moved to `components.schema` in OpenAPI 3.
        schema.components = {
            schemas: this.schema.definitions,
        };

        // The `api/v1` prefix is still registered for each route in OpenAPI 2.
        // Therefore it is not added to the servers definitions.
        schema.servers = [{ url: '/' }];

        // Discard the items not needed anymore in OpenAPI 3.
        delete schema.swagger;
        delete schema.definitions;
        delete schema.schemes;
        delete schema.produces;
        delete schema.host;

        return schema;
    }
}

export default OpenApi3Converter;
