/**
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

import PropTypes from 'prop-types';
import { getDefaultFormState } from 'react-jsonschema-form/lib/utils';
import {
    has, map, pickBy, isObject,
} from 'lodash';
import HttpClient from '../HttpClient';
import { SCHEMA_RESOURCE } from '../JsonSchema/UiSchema';
import Utils from '../Utils';

/**
 * Get schema definition of property and
 * enrich parent schema with new data.
 *
 * @param {object} resource
 * @param {object} parameters
 * @param {object} container
 * @param {string} target
 * @param {function} notifier
 *
 * @return {void}
 */
const schemaResourceWatcher = (resource, parameters, container, target, notifier) => {
    HttpClient.request(
        resource.operationId,
        parameters,
    ).then((data) => {
        // Extract default values from the schema. Merge values
        // with current state of form to generate new state.
        let defaults = {};
        try {
            defaults = getDefaultFormState(
                data[resource.value],
                container.data,
            );
        } catch (ex) {
            defaults = {};
        }

        // When the data is a string (the default) it can be converted
        // to an object because the schema is available.
        const containerData = Utils.clone(container.data);
        if (!isObject(containerData[target]) && containerData[target] !== undefined) {
            containerData[target] = JSON.parse(containerData[target]);
        }

        notifier({
            ...container,
            schema: {
                ...container.schema,
                properties: {
                    ...container.schema.properties,
                    [target]: { type: 'object', ...data[resource.value] },
                },
            },
            data: {
                [target]: {
                    ...defaults,
                },
                ...containerData,
            },
        });
    });
};

schemaResourceWatcher.propTypes = {
    resource: PropTypes.shape({
        tag: PropTypes.string.isRequired,
        operationId: PropTypes.string.isRequired,
    }).isRequired,
    parameters: PropTypes.objectOf(PropTypes.string).isRequired,
    container: PropTypes.shape({
        entity: PropTypes.string,
        schema: PropTypes.object,
        data: PropTypes.object,
    }).isRequired,
    target: PropTypes.string.isRequired,
    notifier: PropTypes.func.isRequired,
};


/**
 * Register watchers per field.
 *
 * @param {string} entity
 * @param {object} uiSchema
 * @param {function} registry
 */
const RegisterWatchers = (entity, uiSchema, registry) => {
    const watchers = {};

    // The ui-schema.json contains fields that have separate JSON schemas
    // defined depending on the values of other fields. Create a watcher
    // for all fields in order to get the related schema from the API.
    const candidates = pickBy(
        uiSchema[entity],
        subject => has(subject, SCHEMA_RESOURCE),
    );

    // Bind the watchers.
    map(candidates, (subject, name) => {
        const resource = uiSchema[entity][name][SCHEMA_RESOURCE];

        watchers[resource.from] = {
            isCandidate: (value, current) => (value !== undefined && value !== current[resource.from]),
            handle: (value, container, action) => schemaResourceWatcher(
                resource,
                { identifier: value },
                container,
                name,
                action,
            ),
        };
    });

    registry(watchers);
};

RegisterWatchers.propTypes = {
    entity: PropTypes.string.isRequired,
    registry: PropTypes.func.isRequired,
};

export default RegisterWatchers;
export { schemaResourceWatcher };
